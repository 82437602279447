import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  COMMUNITY_PIN_TYPE,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  getUrlWithSpecificRendition,
  isToday,
  showToast,
} from '../../utils/common';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, COMMUNITIES_BY_ID, COMMUNITIES_POST } from '../../services/ApiUrls';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { saveCommunityData, selectCommunityData } from '../../state/CommunityData';
import { useParams } from 'react-router';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import Divider from '../../common/ui/divider/Divider';
import { OverlayTrigger, Popover, Modal, Carousel } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import LikeCommentDialog from './components/LikeCommentDialog';
import { selectEmployeeData } from '../../state/EmployeeData';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { selectAccessData } from '../../state/UserAccessData';

const PostDetailScreen = ({ history }) => {
  const { postId } = useParams();
  const dispatch = useDispatch();
  const [post, setPost] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const communityData = useSelector(selectCommunityData);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showCreatePostButton, setShowCreatePostButton] = useState(true);
  const communityAccessData = accessData?.data?.find((item) => item.feature_key === '_communities');

  useEffect(() => {
    const actions = communityAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowCreatePostButton(actions?.includes('_post'));
  }, [communityAccessData?.actions]);
  const [products] = useState([
    {
      id: 1,
      name: 'Inappropriate content',
    },
    {
      id: 2,
      name: 'Individual being targetted',
    },
    {
      id: 3,
      name: 'Posting id fake/unreliable',
    },
    {
      id: 4,
      name: 'Spam',
    },
  ]);
  const [radioSelected, setRadioSelected] = useState(products[0]);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const employeeData = useSelector(selectEmployeeData);

  const callPostDetailApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        COMMUNITIES_BY_ID + 'post/' + postId,
        REQUEST_TYPE.GET,
        {}
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data.post) {
              setPost(data.post);
            }
            if (data.community) {
              dispatch(saveCommunityData(data.community));
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, COMMUNITIES_BY_ID + 'post/' + postId, NAVIGATION_ROUTES.POST_DETAIL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, postId]);

  const pinUnpinPostApi = async (pinType) => {
    const params = new URLSearchParams();
    params.append('post_id', postId);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        COMMUNITIES_BY_ID +
          post.community.community_id +
          (pinType === COMMUNITY_PIN_TYPE.PIN ? '/pinpost' : '/unpinpost'),
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            history.goBack();
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        COMMUNITIES_BY_ID +
          post.community.community_id +
          (pinType === COMMUNITY_PIN_TYPE.PIN ? '/pinpost' : '/unpinpost'),
        NAVIGATION_ROUTES.POST_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POST_DETAIL, NAVIGATION_ROUTES.POST_DETAIL);
  }, []);

  useEffect(() => {
    if (postId) {
      callPostDetailApi();
    }
  }, [callPostDetailApi, postId]);

  const reportPostApi = async (val) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.REPORTING_POST,
      })
    );
    const formData = new URLSearchParams();
    formData.append('comment', val);
    try {
      const apiResponse = await apiRequest(
        COMMUNITIES_POST + postId + '/report',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setShowReportDialog(false);
          showToast(apiResponse.response.message);
          setRadioSelected(products[0]);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        formData,
        COMMUNITIES_POST + postId + '/report',
        NAVIGATION_ROUTES.POST_DETAIL
      );
      //
    }
  };

  const onPressLikeButton = () => {
    if (communityData.is_member && communityData.approved) {
      logEvent(
        ANALYTICS_EVENT_TYPES.COMMUNITIES_POST_LIKE,
        post.post_id,
        ANALYTICS_ITEM_NAMES.LIKE
      );
      callLikeApi(post.post_id);
    } else {
      setShowDialog(true);
    }
  };

  const callLikeApi = async (postId) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.COMMUNITY);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + postId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          callPostDetailApi();
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + postId + '/like', NAVIGATION_ROUTES.POST_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        e.preventDefault();
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e.target.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e.target.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          history.push(`${target}`, { community: communityData });
        }
      }
    }
  };

  const postActionsPopover = () => (
    <Popover>
      {communityData.is_champion && !post.is_pinned && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                pinUnpinPostApi(COMMUNITY_PIN_TYPE.PIN);
              }}>
              Pin this post
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {communityData.is_champion && post.is_pinned && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                pinUnpinPostApi(COMMUNITY_PIN_TYPE.UNPIN);
              }}>
              Unpin this post
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {post.employee.employee_id !== employeeData.employee_id && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                setShowReportDialog(true);
              }}>
              Report
            </div>
          </Popover.Content>
        </>
      )}
      {post.employee.employee_id === employeeData.employee_id && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.EDIT_POST, {
                  postId: post.post_id,
                  post: post,
                });
              }}>
              Edit
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {post.employee.employee_id === employeeData.employee_id && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                setDeleteDialog(true);
              }}>
              Delete
            </div>
          </Popover.Content>
        </>
      )}
    </Popover>
  );

  const reportDialog = () => {
    return (
      <div>
        {products.map((item, index) => {
          return (
            <div key={`${index}`}>
              <div key={item.id} className="form-check ml-1 mt-1">
                <input
                  type="radio"
                  className="form-check-input common-cursor-pointer"
                  checked={radioSelected && item.id === radioSelected.id ? true : false}
                  onChange={() => {
                    setRadioSelected(item);
                  }}
                />
                <label className="form-check-label communities-report">{item.name}</label>
              </div>
            </div>
          );
        })}
        <CustomButton
          buttonStyle="communities-button-style mt-3 mr-0"
          onClick={() => reportPostApi(radioSelected.name)}
          showLoader={progressLoadingState.isProgressLoading}>
          SUBMIT
        </CustomButton>
      </div>
    );
  };

  const callDeleteApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        COMMUNITIES_BY_ID + post.post_id + '/deletepost',
        REQUEST_TYPE.POST,
        {}
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setDeleteDialog(false);
          if (apiResponse.response) {
            const data = apiResponse.response.data;
            showToast(data.message);
            history.goBack();
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        COMMUNITIES_BY_ID + post.post_id + '/deletepost',
        NAVIGATION_ROUTES.POST_DETAIL
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, history, post.post_id]);

  const renderDeletePost = () => {
    return (
      <div>
        <div className="text-center communities-dialog-title">OOPS!</div>
        <div className="text-center communities-dialog-subtitle mt-3">{MESSAGES.DELETE_POST}</div>
        <div className="d-flex justify-content-center mt-3">
          <div
            className="d-flex justify-content-center align-items-center mr-4"
            onClick={() => setDeleteDialog(false)}>
            <div className="common-cursor-pointer communities-cancel-community-button">CANCEL</div>
          </div>
          <CustomButton
            buttonStyle="common-custom-button-quaternary ml-4"
            showLoader={progressLoadingState}
            onClick={() => callDeleteApi()}>
            YES, DELETE
          </CustomButton>
        </div>
      </div>
    );
  };

  const renderImages = () => {
    return (
      <Carousel
        className="communities-post-carousel-container mt-2"
        activeIndex={currentActiveSlideIndex}
        nextIcon={
          post.images.length > 1 && (
            <span aria-hidden="true" className="carousel-control-next-icon" />
          )
        }
        prevIcon={
          post.images.length > 1 && (
            <span aria-hidden="true" className="carousel-control-prev-icon" />
          )
        }
        onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
        {post.images.map((imageItem, index) => {
          return (
            <Carousel.Item key={`${index}`}>
              <div className="d-flex justify-content-center">
                <img
                  className="communities-post-carousel-image"
                  src={getUrlWithSpecificRendition(
                    imageItem.base_url,
                    SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                    imageItem.filename
                  )}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  const renderVideoLinks = () => {
    return (
      <div>
        {post.video_links && post.video_links.length > 0 && (
          <div className="communities-url mt-2">Video URL:</div>
        )}
        {post.video_links &&
          post.video_links.length > 0 &&
          post.video_links.map((item, index) => {
            return (
              <div key={`${index}`}>
                <a
                  className="community-link-text"
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {item.url}
                </a>
              </div>
            );
          })}
      </div>
    );
  };

  const renderLinks = () => {
    return (
      <div>
        {post.links && post.links.length > 0 && (
          <div className="communities-url mt-2">Website URL:</div>
        )}
        {post.links &&
          post.links.length > 0 &&
          post.links.map((item, index) => {
            return (
              <div className="text-truncate" key={`${index}`}>
                <a
                  className="community-link-text"
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {item.url}
                </a>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      <BackButton />
      <Modal
        show={showReportDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowReportDialog(false)}>
        <Modal.Body>{reportDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={showDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDialog(false)}>
        <Modal.Body>
          <LikeCommentDialog onChangeDialogVisible={(value) => setShowDialog(value)} flag={'2'} />
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setDeleteDialog(false)}>
        <Modal.Body>{renderDeletePost()}</Modal.Body>
      </Modal>
      <div className="main-content-container">
        <div className="d-flex justify-content-center">
          <div className="common-container-md">
            {post && Object.keys(post).length > 0 && (
              <div className="border p-3">
                <div className="communities-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex align-items-center">
                    <UserProfilePhoto
                      imageBaseUrl={post.employee.profile_picture.base_url}
                      imagePath={post.employee.profile_picture.image_path}
                      imageClass="common-user-profile-photo-xs"
                      employeeId={post.employee.employee_id}
                    />
                    <div className="communities-post-employee-name ml-3 common-cursor-pointer">
                      {post.employee.first_name + ' ' + post.employee.last_name}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="communities-date-text">
                      {isToday(post.created_on)
                        ? formatTimeAMPM(post.created_on)
                        : formatDateDDMonthYYYY(post.created_on)}
                    </div>
                    <div className="d-flex ml-3">
                      {communityData.is_member && communityData.approved && (
                        <OverlayTrigger
                          rootClose
                          trigger="click"
                          placement="bottom"
                          overlay={postActionsPopover()}>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_MORE_OPTIONS,
                              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                            )}
                            className="header-icon mr-0"
                          />
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  {post.is_pinned && (
                    <div className="d-flex align-items-center mr-2">
                      <img
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_PIN,
                          ACCESSIBILITY_IDENTIFIERS.COMMON
                        )}
                        className="communities-post-pin-icon mr-1"
                      />
                      <div className="communities-post-marker-text">Pinned</div>
                    </div>
                  )}
                  {post.is_edited && (
                    <div className="communities-grey-box px-2 py-1">
                      <div className="communities-post-marker-text">Edited</div>
                    </div>
                  )}
                </div>
                {post.title && <div className="mt-2 communities-title">{post.title}</div>}
                <HtmlView
                  htmlStyle="mt-2 communities-description text-break"
                  html={post.description}
                  onClick={handleHtmlClicks}
                />
                {renderImages()}
                {renderVideoLinks()}
                {renderLinks()}
                <Divider style="mt-2" />
                <div className="mt-2 communities-flex justify-content-between">
                  <div className="d-flex align-items-center mt-1">
                    {showLikeButton && (
                      <>
                        {post.is_liked ? (
                          <div onClick={() => onPressLikeButton()}>
                            <img
                              src={getHeaderComponentUrl(
                                accessibilityData,
                                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ACTIVE_LIKE,
                                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                              )}
                              className="communities-normal-icon"
                            />
                          </div>
                        ) : (
                          <div onClick={() => onPressLikeButton()}>
                            <img
                              src={getHeaderComponentUrl(
                                accessibilityData,
                                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_INACTIVE_LIKE,
                                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                              )}
                              className="communities-normal-icon common-cursor-pointer"
                            />
                          </div>
                        )}
                        {post.like_count > 0 ? (
                          <div
                            className="ml-2 communities-count common-cursor-pointer"
                            onClick={() => {
                              history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                                referId: post.post_id,
                                parentType: FEATURE_TYPE.COMMUNITY,
                              });
                            }}>
                            {post.like_count}
                          </div>
                        ) : (
                          <div className="ml-2 communities-count common-cursor-pointer">0 </div>
                        )}
                      </>
                    )}
                    {showCommentButton && (
                      <>
                        <div
                          className={
                            showLikeButton
                              ? 'communities-flex ml-3 mr-3 communities-width common-cursor-pointer'
                              : 'communities-flex mr-3 communities-width common-cursor-pointer'
                          }
                          onClick={() => {
                            if (communityData.is_member && communityData.approved) {
                              logEvent(
                                ANALYTICS_EVENT_TYPES.COMMUNITIES_POST_COMMENT,
                                post.update_id,
                                ANALYTICS_ITEM_NAMES.COMMENT
                              );
                              history.push(NAVIGATION_ROUTES.COMMENTS, {
                                referId: post.post_id,
                                likeType: FEATURE_TYPE.COMMUNITY,
                                fromRoute: NAVIGATION_ROUTES.POST_DETAIL,
                              });
                            } else {
                              setShowDialog(true);
                            }
                          }}>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POST_COMMENT,
                              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                            )}
                            className="communities-normal-icon"
                          />
                          <div className="ml-2 communities-count">
                            {post.comment_count > 0 ? post.comment_count : 0}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    {post.tags.length > 0 && (
                      <div
                        className="common-cursor-pointer d-flex align-items-center"
                        onClick={() => {
                          history.push(NAVIGATION_ROUTES.COMMUNITY_POST_TAGGED, {
                            employees: post.tags,
                          });
                        }}>
                        <div className="d-flex align-items-center">
                          {post.tags.length >= 3 ? (
                            <div className="d-flex align-items-center">
                              <div className="communities-image-first position-relative ">
                                {post.tags.length >= 1 && (
                                  <UserProfilePhoto
                                    imageBaseUrl={post.tags[0].profile_picture.base_url}
                                    imagePath={post.tags[0].profile_picture.image_path}
                                    imageClass="common-user-profile-photo-xs"
                                    employeeId={post.tags[0].employee_id}
                                  />
                                )}
                              </div>
                              <div className="communities-image-second position-relative ">
                                {post.tags.length >= 2 && (
                                  <UserProfilePhoto
                                    imageBaseUrl={post.tags[1].profile_picture.base_url}
                                    imagePath={post.tags[1].profile_picture.image_path}
                                    imageClass="common-user-profile-photo-xs"
                                    employeeId={post.tags[1].employee_id}
                                  />
                                )}
                              </div>
                              {post.tags.length >= 3 && (
                                <UserProfilePhoto
                                  imageBaseUrl={post.tags[2].profile_picture.base_url}
                                  imagePath={post.tags[2].profile_picture.image_path}
                                  imageClass="common-user-profile-photo-xs"
                                  employeeId={post.tags[2].employee_id}
                                />
                              )}
                            </div>
                          ) : post.tags.length >= 2 ? (
                            <div className="d-flex align-items-center">
                              <div className="communities-image-first position-relative ">
                                {post.tags.length >= 1 && (
                                  <UserProfilePhoto
                                    imageBaseUrl={post.tags[0].profile_picture.base_url}
                                    imagePath={post.tags[0].profile_picture.image_path}
                                    imageClass="common-user-profile-photo-xs"
                                    employeeId={post.tags[0].employee_id}
                                  />
                                )}
                              </div>
                              <div>
                                {post.tags.length >= 2 && (
                                  <UserProfilePhoto
                                    imageBaseUrl={post.tags[1].profile_picture.base_url}
                                    imagePath={post.tags[1].profile_picture.image_path}
                                    imageClass="common-user-profile-photo-xs"
                                    employeeId={post.tags[1].employee_id}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {post.tags.length >= 1 && (
                                <UserProfilePhoto
                                  imageBaseUrl={post.tags[0].profile_picture.base_url}
                                  imagePath={post.tags[0].profile_picture.image_path}
                                  imageClass="common-user-profile-photo-xs"
                                  employeeId={post.tags[0].employee_id}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        {post.tags.length > 3 && (
                          <div className="communities-tag-count-text ml-2">{`+${
                            post.tags.length - 3
                          }`}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailScreen;
