import axios from 'axios';
import { version } from '../../package.json';
import { showToast } from '../utils/common';
import { ENCODING_TYPE, ERROR_MESSAGES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { LOCAL_HOST_URL } from './ApiUrls';
import { store } from '../config/store';
import { saveShowUnAuthorised } from '../state/EStoreData';

export const callApi = async (requireConfig = {}, isEstore = false) => {
  const config = {
    ...requireConfig,
    timeout: 50000,
  };
  const request = {};
  try {
    const response = await axios(config);
    if (response.status === 200 || response.status === 201) {
      if (response.data.status) {
        request.response = response.data;
      } else if (response.data.maintenance) {
        // handleMaintenanceMode();
      } else if (response.data.locked) {
        // handleLockedAccount();
      } else if (response.data.error) {
        if (response?.config?.data && Array.isArray(response?.config?.data)) {
          if (response?.config?.data?.includes('only_validation')) {
            request.response = response.data;
          }
        } else {
          showToast(response.data.error);
          request.response = response.data;
        }
      } else {
        request.response = response.data;
      }
    }
  } catch (error) {
    console.error('error', error);
    if (error.response) {
      if (error.response.status === 400) {
        error.response.data.message && showToast(error.response.data.message);
      }
      if (error.response.status === 403) {
        // handleMaintenanceMode();
      } else if (error.response.status === 401) {
        if (isEstore) {
          store.dispatch(saveShowUnAuthorised(true));
          request.response = {};
        } else {
          window.location.replace(process.env.REACT_APP_LOGIN_URL);
        }
        // handleUnauthorizedAccess();
      } else {
        throw ERROR_MESSAGES.API_MESSAGE_DEFAULT;
      }
    } else {
      throw ERROR_MESSAGES.API_MESSAGE_DEFAULT;
    }
  }
  return request;
};

export const callApiStore = async (requireConfig = {}) => {
  const config = {
    ...requireConfig,
    timeout: 50000,
  };
  const request = {};
  try {
    const response = await axios(config);
    if (response.status === 200) {
      if (response.data.status) {
        request.response = response.data;
      } else if (response.data.maintenance) {
        // handleMaintenanceMode();
      } else if (response.data.locked) {
        // handleLockedAccount();
      } else if (response.data.error) {
        showToast(response.data.error);
        request.response = response.data;
      } else {
        request.response = response.data;
      }
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        // handleMaintenanceMode();
      } else if (error.response.status === 401) {
        window.location.replace(process.env.REACT_APP_LOGIN_URL);
        // handleUnauthorizedAccess();
      } else if (error.response.status === 400 && typeof error.response?.data === 'object') {
        request.response = { status: false, ...error.response?.data };
      } else {
        throw ERROR_MESSAGES.API_MESSAGE_DEFAULT;
      }
    } else {
      throw ERROR_MESSAGES.API_MESSAGE_DEFAULT;
    }
  }
  return request;
};

export const apiRequest = (
  request,
  method,
  payload,
  encodingType = ENCODING_TYPE.FORM_URL_ENCODED
) => {
  // temporary change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';

  let url = '/api';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/api`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/api`;
  }
  let options = {
    method,
    url: url,
    params: { endpoint: baseUrl + request },
    data: payload,
    headers: {
      'App-Version': version,
      'Content-Type': encodingType,
    },
    json: true,
  };
  if (method === 'GET') {
    options = {
      method,
      url: url,
      params: { endpoint: baseUrl + request, ...payload },
      headers: {
        'App-Version': version,
        Accept: 'application/json',
        //Authorization: `Bearer ${jwtToken}`,
      },
      json: true,
    };
  }

  if (method === 'POST' && encodingType === ENCODING_TYPE.FORM_DATA) {
    options = { ...options, ...{ url: `${url}/formdata` } };
  }
  if (method === 'POST' && encodingType === ENCODING_TYPE.RAW) {
    options = { ...options, ...{ url: `${url}/raw` } };
  }
  return callApi(options);
};

export const msApiRequest = (
  request,
  method,
  payload,
  encodingType = ENCODING_TYPE.FORM_URL_ENCODED
) => {
  const baseUrl = '';

  let url = '/service/api';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/service/api`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/service/api`;
  }
  let options = {
    method,
    url: url,
    params: { endpoint: baseUrl + request },
    data: payload,
    headers: {
      'App-Version': version,
      'Content-Type': encodingType,
    },
    json: true,
  };
  if (method === 'GET') {
    options = {
      method,
      url: url,
      params: { endpoint: baseUrl + request, ...payload },
      headers: {
        'App-Version': version,
        Accept: 'application/json',
        //Authorization: `Bearer ${jwtToken}`,
      },
      json: true,
    };
  }

  if (method === 'POST' && encodingType === ENCODING_TYPE.FORM_DATA) {
    options = { ...options, ...{ url: `${url}/formdata` } };
  }
  if (method === 'POST' && encodingType === ENCODING_TYPE.RAW) {
    options = { ...options, ...{ url: `${url}/raw` } };
  }
  return callApi(options);
};

export const apiRequestUser = (request, method, payload) => {
  // temporary change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';

  let url = '/microstore/user';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/microstore/user`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/microstore/user`;
  }
  let options = {
    method,
    url: url,
    params: { endpoint: baseUrl + request },
    data: payload,
    headers: {
      'App-Version': version,
      'Content-Type': 'application/json',
    },
    json: true,
  };
  if (method === 'GET') {
    options = {
      method,
      url: url,
      params: { endpoint: baseUrl + request, ...payload },
      headers: {
        'App-Version': version,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //Authorization: `Bearer ${jwtToken}`,
      },
      json: true,
    };
  }

  if (method === REQUEST_TYPE.POST || method === REQUEST_TYPE.PUT) {
    options.data = payload;
  }
  return callApi(options, true);
};

export const apiRequestGenericToken = (request, method, payload, genericToken = false) => {
  const baseUrl = '';
  let url = '/api';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/api`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/api`;
  }

  let options = {};
  if (method === 'GET') {
    options = {
      method,
      url: url + `${genericToken ? '/generic' : ''}`,
      params: { endpoint: baseUrl + request, ...payload },
      headers: genericToken
        ? {
            'App-Version': version,
            Accept: 'application/json',
            // do not use generic tokens for api calls
            // Authorization: `Bearer ${jwtToken}`,
          }
        : {
            'App-Version': version,
            Accept: 'application/json',
            // do not use generic tokens for api calls
            //Authorization: `Bearer ${jwtToken}`,
          },
      json: true,
    };
  }
  return callApi(options);
};

export const apiRequestGetToken = (request, method, payload) => {
  // temporary change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';
  // do not use generic tokens for api calls
  // const jwtToken = getGenericToken();

  let url = '/api';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/api`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/api`;
  }

  let options = {};
  if (method === 'GET') {
    options = {
      method,
      url: url,
      params: { endpoint: baseUrl + request, ...payload },
      headers: {
        'App-Version': version,
        Accept: 'application/json',
        // do not use generic token for api calls
        // Authorization: `Bearer ${jwtToken}`,
      },
      json: true,
    };
  }
  return callApi(options);
};

export const apiRequestForErrorLogs = (payload, encodingType = ENCODING_TYPE.FORM_URL_ENCODED) => {
  // temporary change
  // const baseUrl = getBaseUrl();
  const baseUrl = '';
  // do not use generic tokens for api calls
  // const jwtToken = getToken();
  const method = REQUEST_TYPE.POST;
  let url = '/api';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/api`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/api`;
  }
  let options = {
    method,
    url: url,
    params: { endpoint: baseUrl + 'log/errors' },
    data: payload,
    headers: {
      'App-Version': version,
      'Content-Type': encodingType,
      // do not use generic tokens for api calls
      // Authorization: `Bearer ${jwtToken}`,
    },
    json: true,
  };
  if (method === 'POST' && encodingType === ENCODING_TYPE.FORM_DATA) {
    options = { ...options, ...{ url: `${url}/formdata` } };
  }
  if (method === 'POST' && encodingType === ENCODING_TYPE.RAW) {
    options = { ...options, ...{ url: `${url}/raw` } };
  }
  return callApi(options);
};

export const handleError = async (_err, errorInfo_, url = '', screen_name = '') => {
  return;
  // remove error handling
  // const _params = new URLSearchParams();
  // _params.append('name', _err?.name || _err);
  // _params.append('message', _err?.message || _err);
  // _params.append('params', JSON.stringify(errorInfo_));
  // _params.append('url', url);
  // _params.append('screen_name', screen_name);
  // _params.append('headers', '');
  // try {
  //   const errorLogResponse = await apiRequestForErrorLogs(_params);
  //   console.log('ERR RESP :: ', errorLogResponse);
  // } catch (err) {
  //   console.log('ERR IN HANDLER :: ', err);
  // }
};

export const apiRequestEStore = (request, method, payload) => {
  const baseUrl = '';

  let url = '/microstore/general';
  if (process.env.NODE_ENV === 'development') {
    url = `${LOCAL_HOST_URL}/microstore/general`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `${LOCAL_HOST_URL}/microstore/general`;
  }
  let options = {
    method,
    url: url,
    params: { endpoint: baseUrl + request },
    data: payload,
    headers: {
      'App-Version': version,
      'Content-Type': 'application/json',
    },
    json: true,
  };
  if (method === 'GET') {
    options = {
      method,
      url: url,
      params: { endpoint: baseUrl + request, ...payload },
      headers: {
        'App-Version': version,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      json: true,
    };
  }

  if (method === REQUEST_TYPE.POST || method === REQUEST_TYPE.PUT) {
    options.data = payload;
  }
  return callApi(options);
};
