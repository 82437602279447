import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../constants/AppConstants';
import {
  COMMENT_BY_ID,
  DELETE_COMMENT,
  EDIT_COMMENT,
  LIKE_UNLIKE_COMMENT,
} from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import { isEmptyText, showToast } from '../../../utils/common';
import { saveCommentCountData } from '../../../state/CommentData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import BackButton from '../../ui/common_back_button/BackButton';
import CommentItem from './component/CommentItem';
import LoadMoreBtn from '../../ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../ui/default_content_view/DefaultContentView';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
} from '../../components/hashtagsAndMentionsInput/hashtagUtils';
import CustomCommentBox from '../../ui/comment_box/CustomCommentBox';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const CommentScreen = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [comments, setComments] = useState([]);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState('');
  const [mentions, setMentions] = useState([]);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [commentIdForUndo, setCommentIdForUndo] = useState();
  const [undo, setUndo] = useState(false);
  const [copyOfStateToUndo, setCopyOfStateToUndo] = useState(null);

  const callComments = useCallback(
    async (pageIdVar) => {
      if (isLoading) return;
      setIsLoading(true);
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      setShowEmptyView(false);
      const get_params = {
        type: params.likeType,
        page_id: pageIdVar,
      };
      const commentApiResponse = await apiRequest(
        COMMENT_BY_ID + params.referId,
        REQUEST_TYPE.GET,
        get_params
      );
      if (Object.keys(commentApiResponse).length > 0) {
        if (commentApiResponse.response.status === true) {
          if (commentApiResponse.response.data.feeds) {
            const commentsData = commentApiResponse.response.data.feeds?.reverse();
            if (commentsData?.length === 0) {
              setShowEmptyView(true);
            }
            if (commentsData.length > 0) {
              setComments((prevComments) => {
                const updatedComments = commentsData.map((newComment) => {
                  const existingCommentIndex = prevComments.findIndex(
                    (prevComment) => prevComment.comment_id === newComment.comment_id
                  );
                  if (existingCommentIndex !== -1) {
                    const updatedComment = { ...newComment, ...prevComments[existingCommentIndex] };
                    prevComments[existingCommentIndex] = updatedComment;
                  } else {
                    prevComments.unshift(newComment);
                  }
                  return newComment;
                });

                return [...prevComments];
              });
              setPageId(pageIdVar);
              setLoadMoreFlag(true);
              setLoadMoreBtn(true);
            } else {
              setLoadMoreBtn(false);
              setLoadMoreFlag(false);
            }
          }
        }
      } else {
        setShowEmptyView(true);
      }
      setIsLoading(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    },
    [dispatch, params.likeType, params.referId]
  );
  useEffect(() => {
    if (copyOfStateToUndo && undo) {
      deleteCommentApi(commentIdForUndo, 'undo');
      setUndo(false);
      setCommentIdForUndo(null);
    }
  }, [copyOfStateToUndo, undo, commentIdForUndo]);
  const callCommentsForLoadmore = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    setShowEmptyView(false);
    const get_params = {
      type: params.likeType,
      page_id: 1,
    };
    const commentApiResponse = await apiRequest(
      COMMENT_BY_ID + params.referId,
      REQUEST_TYPE.GET,
      get_params
    );
    if (Object.keys(commentApiResponse).length > 0) {
      if (commentApiResponse.response.status === true) {
        if (commentApiResponse.response.data.feeds) {
          const commentsData = commentApiResponse.response.data.feeds;
          if (commentsData?.length === 0) {
            setShowEmptyView(true);
          }
          if (commentsData.length > 0) {
            setComments(commentsData);
            setPageId(1);
            setLoadMoreFlag(true);
            setLoadMoreBtn(true);
          } else {
            setLoadMoreBtn(false);
            setLoadMoreFlag(false);
          }
        }
      }
    } else {
      setShowEmptyView(true);
    }
    setIsLoading(false);
    dispatch(saveProgressLoadingState({ isProgressLoading: false }));
  }, [dispatch, params.likeType, params.referId]);

  const renderScreen = useCallback(async () => {
    callComments(pageId);
  }, [callComments]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const loadMore = () => {
    if (loadMoreFlag) {
      callComments(pageId + 1);
    }
  };

  const sendCommentFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    mentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (image != '') {
      tempDes = tempDes + ` <egami>${image}</egami>`;
    }

    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const formData = new URLSearchParams();
        formData.append('type', params.likeType);
        formData.append('comment', tempDes);
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + params.referId,
          REQUEST_TYPE.POST,
          formData
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response) {
              setEditorState(EditorState.createEmpty());
              setImage('');
              dispatch(saveCommentCountData(apiResponse.response.data.comment_count));
              showToast(apiResponse.response.message);
              setComments([]);
              callComments(1);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const getCommentText = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...mentions,
    ]);
    setEditorState(editorState2);
  };

  const undoCommentFunction = (commentId) => {
    setCommentIdForUndo(commentId);
    setUndo(true);
  };

  const showToastWithMessage = (commentId, message) => {
    showToast(
      <div className="d-flex flex-row common-comment-toast-row">
        <div className="common-comment-toast-text">{message}</div>
        <div
          className="common-comment-toast-button"
          onClick={() => {
            undoCommentFunction(commentId);
          }}>
          UNDO
        </div>
      </div>
    );
  };

  const deleteCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'delete');
  };

  const deleteCommentApi = async (commentId, type) => {
    try {
      setShowDeleteLoader(true);
      const formData = new URLSearchParams();
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + DELETE_COMMENT,
        REQUEST_TYPE.POST,
        formData
      );
      setShowDeleteLoader(false);
      if (apiResponse.response.status) {
        setLoadMoreBtn(true);
        setLoadMoreFlag(true);
        if (type === 'delete') {
          const commentToDelete = comments.find((comment) => comment.comment_id === commentId);
          const commentIndex = comments.findIndex((comment) => comment.comment_id === commentId);
          const {
            comments: updatedComments,
            removedParentComment,
            removedParentIndex,
            removedChildComments,
          } = removeComment(comments, commentId);
          setComments([...updatedComments]);
          setCopyOfStateToUndo({ removedParentComment, removedParentIndex, removedChildComments });
          showToastWithMessage(commentId, apiResponse.response.message);
        }
        if (type === 'undo') {
          if (copyOfStateToUndo) {
            console.log('entering into undo block');
            const { removedParentComment, removedParentIndex, removedChildComments } =
              copyOfStateToUndo;
            console.log('after getting data', copyOfStateToUndo);
            if (removedParentComment || removedChildComments?.length > 0) {
              const newComments = [...comments];

              if (removedParentComment) {
                newComments.splice(removedParentIndex, 0, removedParentComment);
              }

              removedChildComments.forEach((removedChild) => {
                const parentComment = newComments.find(
                  (comment) => comment.comment_id === removedChild.parent_comment_id
                );

                if (parentComment) {
                  parentComment.child_comments.splice(removedChild.child_index, 0, removedChild);
                }
              });
              console.log('newComments', newComments);
              setComments([...newComments]);
              setCopyOfStateToUndo(null);
            }
          } else {
            console.error('Error: No comment data available for undo.');
          }
        }
      }
    } catch (err) {
      console.log('new data to test', err);
      setShowDeleteLoader(false);
    }
  };

  function removeComment(comments, targetCommentId) {
    const removedChildComments = [];
    let removedParentComment = null;
    let removedParentIndex = -1;

    comments.forEach((parentComment, parentIndex) => {
      parentComment.child_comments = parentComment.child_comments.filter((child, childIndex) => {
        if (child.comment_id === targetCommentId) {
          removedChildComments.push({
            ...child,
            parent_comment_id: parentComment.comment_id,
            parent_comment_index: parentIndex,
            child_index: childIndex,
          });
          return false;
        }
        return true;
      });

      if (parentComment.comment_id === targetCommentId) {
        removedParentComment = {
          ...parentComment,
          parent_comment_index: parentIndex,
        };
        removedParentIndex = parentIndex;
        comments.splice(parentIndex, 1);
      }
    });

    return { comments, removedParentComment, removedParentIndex, removedChildComments };
  }

  const editCommentFunction = async (commentId, comment, mentions, image) => {
    if (!isEmptyText(comment)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.DEFAULT,
          })
        );
        const formData = new URLSearchParams();
        formData.append('comment', comment);
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + commentId + '/' + EDIT_COMMENT,
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (apiResponse.response.status) {
          showToast(apiResponse.response.message);
          if (apiResponse.response.comments) {
            const updatedData = apiResponse.response?.updated_info?.feed;
            const previousComments = comments;
            const updatedComments = previousComments.map((comment) => {
              const updatedComment = updatedData.find(
                (updated) => updated.comment_id === comment.comment_id
              );

              if (updatedComment) {
                return { ...comment, ...updatedComment };
              }
              return comment;
            });
            setLoadMoreBtn(true);
            setLoadMoreFlag(true);
            setComments(updatedComments);
          }
        }
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const likeUnlikeCommentApi = async (commentId) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiParams = new URLSearchParams();
      apiParams.append('component_refer_id', params.referId);
      apiParams.append('type', params.likeType);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + LIKE_UNLIKE_COMMENT,
        REQUEST_TYPE.POST,
        apiParams
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            const updatedData = apiResponse.response?.updated_info?.feed;
            const previousComments = comments;
            const updatedComments = previousComments.map((comment) => {
              const updatedComment = updatedData.find(
                (updated) => updated.comment_id === comment.comment_id
              );

              if (updatedComment) {
                return { ...comment, ...updatedComment };
              }
              return comment;
            });
            setComments(updatedComments);
          }
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressWhoLikedHandler = (commentId) => {
    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: params.referId,
      parentType: params.likeType,
      commentId: commentId,
    });
  };

  const onMention = (mention) => {
    setMentions((prev) => [...prev, mention]);
  };

  const renderComments = () => {
    if (comments && comments.length > 0) {
      return comments.map((item) => {
        return (
          <CommentItem
            setComments={setComments}
            comments={comments}
            key={item.comment_id}
            commentId={item.comment_id}
            date={item.comment_on}
            employee={item.employee}
            commentReplies={item.child_comments}
            likeType={params.likeType}
            referId={params.referId}
            callCommentsApi={callComments}
            commentLikeCount={item.like_count}
            commentHasLiked={item.liked}
            onPressWhoLikedHandler={onPressWhoLikedHandler}
            likeUnlikeCommentApi={likeUnlikeCommentApi}
            message={item.comment}
            showDeleteLoader={showDeleteLoader}
            editComment={editCommentFunction}
            deleteComment={(commentId) => {
              deleteCommentFunction(commentId);
            }}
            mentions={item.taggged_employees}
            commentText={item.comment_text}
          />
        );
      });
    } else {
      return (
        showEmptyView &&
        comments?.length === 0 && (
          <DefaultContentView message={ERROR_MESSAGES.NO_COMMENTS_TO_SHOW} />
        )
      );
    }
  };

  return (
    <div className="container">
      <BackButton />
      {loadMoreBtn && comments?.length > 0 && (
        <div className="mb-2 mt-4">
          <div className="loadMoreContainer">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
              )}
              className="load-more-icon mr-2"
              onClick={loadMore}
            />
            <div className="loadmoreText" onClick={loadMore}>
              Load previous comments
            </div>
          </div>
        </div>
      )}
      <div>{renderComments()}</div>
      {comments?.length > 0 && (
        <div className="mb-2 mt-4">
          <div onClick={callCommentsForLoadmore} className="loadMoreContainer">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
              )}
              className="load-more-icon mr-2"
            />
            <div className="loadmoreText">Load latest comment</div>
          </div>
        </div>
      )}
      <CustomCommentBox
        getCommentText={sendCommentFunction}
        onChangehandler={getCommentText}
        showLoader={progressLoadingState.isProgressLoading}
        setEditorState={setEditorState}
        editorState={editorState}
        enableUserMentions={true}
        enableHashtags={false}
        enableImageUpload={true}
        image={image}
        comments={comments}
        setImage={setImage}
        onMention={onMention}
      />
    </div>
  );
};

export default CommentScreen;
