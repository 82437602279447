import { version } from '../../../package.json';

export const FIREBASE_DB_VERSION = '6_8_6';

export const CURRENT_MOBILE_APP_VERSION = version;

export const DEFAULT_ISD_CODE = '91';
export const DEFAULT_REGION_CODE = 'IN';

export const SEARCH_DEBOUNCE_WAIT_TIME = 400;

export const SCREEN_DENSITY_QUALIFIERS = {
  LDPI: 'ldpi',
  MDPI: 'mdpi',
  HDPI: 'hdpi',
  XHDPI: 'xhdpi',
  XXHDPI: 'xxhdpi',
  XXXHDPI: 'xxxhdpi',
};

export const GREETING_CARD_EXCLUSION_LIST = ['1'];

export const SCREEN_TITLE = {
  HOME: 'HOME',
  NEWS_BAORD: 'NEWS BOARD',
  OUR_JOURNEY: 'OUR JOURNEY',
  LOANS: 'LOANS',
  MYINCENTIVES: 'MY INCENTIVES',
  EXPENSES: 'EXPENSES',
  BUSINESS_DASHBOARD: 'BUSINESS DASHBOARD',
  POLICIES: 'POLICIES',
  MENU: 'MENU',
  MENU_PERSONALIZE: 'MENU PREFERENCES',
  DEAR_EMPLOYER: 'DEAR MICROLANDER',
  NEWS: 'MICROLAND IN THE NEWS',
  BROADCASTS: 'BROADCASTS',
  MI_HR: 'MiHR SUPPORT',
  LIKEDPEOPLE: 'LIKED BY',
  DIRECTORY: 'DIRECTORY',
  WISHES: 'WISHES',
  CELEBRATIONS: 'CELEBRATIONS',
  EVENTS: 'EVENTS',
  FACILITY: 'FACILITIES',
  OUR_CUSTOMER: 'OUR CUSTOMERS',
  GALLERY: 'GALLERY',
  COMMENTS: 'COMMENTS',
  MAIN_FILTER: 'FILTERS',
  ECARDS: 'E-CARDS',
  SOM: 'STARS OF MICROLAND',
  NEW_JOINEES: 'NEW JOINEES',
  TAG_EMPLOYEE: 'TAG A COLLEAGUE',
  SESSION_DETAILS: 'AGENDA DETAILS',
  LOGIN: 'LOGIN',
  ASK_SESSION_QUESTION: 'ASK QUESTION',
  CEO_CHAT: 'CEO CHAT',
  IJPR: 'IJP & REFERRALS',
  IJP_APPLY: 'IJP',
  IJP_REFER: 'REFERRALS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  THE_WALL: 'THE WALL',
  VIDEOS: 'VIDEOS',
  WALL_TAGGED: 'TAGGED IN THIS POST',
  WRITE_WALL_COMMENT: 'WRITE A COMMENT',
  POSH: 'POSH(PREVENTION OF SEXUAL HARASSMENT)',
  LEAVE: 'LEAVE',
  SALARY: 'SALARY',
  ATTENDANCE: 'ATTENDANCE',
  MICROVAX: 'MICROVAX',
  MICROSELECT: 'MICROSELECT',
  BREAKOUTS: 'BREAKOUTS',
  MICRO_UNIV: 'MicroUniv',
  QUERY: 'COMMENTS/QUERIES',
  SELECT_DATE: 'SELECT DATE',
  PARTICIPANTS: 'PARTICIPANTS',
  MICROGIVE: 'MICROGIVE',
  CONFIRM_DONATION: 'CONFIRM DONATION',
  VOLUNTEERING_LOG: 'Volunteering log',
  MY_DONATIONS: 'My donations',
  FAQ: "FAQ's",
  UPDATES: 'Updates',
  POLLS_SURVEYS: 'POLLS & SURVEYS',
  POLLS_RESULTS: 'POLLS RESULTS',
  POLL: 'POLL',
  SURVEY: 'SURVEY',
  SURVEY_PARTICIPATE: 'SURVEY',
  MY_PROFILE_DETAIL_EDIT: 'PROFILE',
  MY_ASSET_DETAIL: 'ASSET DETAILS',
  COMMUNITIES: 'COMMUNITIES',
  FORUMS: 'FORUMS',
  VIEW_MEMBERS: 'VIEW MEMBERS',
  WRITE_POST: 'WRITE A POST',
  URL_POST: "URL'S TO THIS POST",
  ASK_ORG: 'ASK MICROLAND',
  ASK_ORG_DETAIL: 'REQUEST DETAILS',
  ASK_ORG_SEARCH_RESULT: 'SEARCH RESULTS',
  ASK_ORG_SEARCH_RESULT_DETAIL: 'REQUEST DETAILS',
  ASK_ORG_SELECT_SERVICE: 'SELECT SERVICE LINE',
  ASK_ORG_SELECT_CATEGORY: 'SELECT CATEGORY',
  ASK_ORG_SELECT_SUB_CATEGORY: 'SELECT SUB-CATEGORY',
  ASK_ORG_SELECT_CLASSIFICATION: 'SELECT CLASSIFICATION',
  ASK_ORG_SELECT_NEW_SERVICE_REQUEST: 'NEW SERVICE REQUEST',
  ASK_ORG_REQUEST_CREATED: 'NEW SERVICE REQUEST',
  ASK_ORG_ESCALATE: 'URGENT - ESCALATE',
  ASK_ORG_FILTER: 'FILTERS',
  CONTESTS: 'CONTESTS',
  WOMENS_DAY: "WOMEN'S DAY",
  CHEERS: 'CHEERS HOME',
  NOMINATION: 'NOMINATION',
  SELF: 'SELF',
  SELECT_MEMBER: 'Select Member',
  ALLOCATE_POINTS_INDIVIDUALLY: 'Allocate Points Individually',
  DONATE: 'Donate',
  ELIGIBLE_AWARDS: 'ELIGIBLE AWARD',
  MY_POINTS: 'My Points',
  MY_TEAM: 'My Team',
  PEER: 'PEER',
  POINTS_REDEEMED: 'Points Redeemed',
  REDEEM: 'Redeem',
  REWARD_DETAILS: 'Reward Details',
  NOMINATION_REQUEST: 'Nomination Requests',
  ARTICLES: 'Articles',
  DIGITAL_WARRIORS: 'Digital Warriors',
  DETAILS: 'DETAILS',
  FROM_CHIEF_OF_MICROLAND: 'From Chief of Microland',
  THE_MICROLANDER_PLEDGE: 'The Microlander Pledge',
  WALLPAPERS: 'Wallpapers',
  PASSWORD_RESET: 'PASSWORD RESET',
  TRAVEL_REQUEST_ADD_USER: 'Create Traveller',
  TRAVEL_REQUEST_EDIT_USER: 'Edit Traveller',
  IJPR_SAVED_JOBS: 'SAVED JOBS',
  BIRTHDAY: 'BIRTHDAY',
  ALL_WISHES: 'ALL WISHES',
  ANNIVERSARY: 'ANNIVERSARY',
  NEW_JOINEE: 'NEW JOINEE',
  ADVANCEMENT: 'ADVANCEMENT',
  HOLIDAY_CALENDAR: 'HOLIDAY CALENDAR',
  INSURANCE: 'INSURANCE',
  RETURN_POLICY: 'Return and Exchange Policy',
  PRIVACY_POLICY: 'Privacy Policy',
  MI_STORE_TC: 'Terms of Use',
  MI_STORE_FAQ: 'Frequently Asked Questions',
};

export const NAVIGATION_ROUTES = {
  MLTV: '/MLTV',
  HOME: '/Home',
  WEBVIEWER: '/WebViewer',
  NEWS_BOARD: 'NewsBoard',
  OUR_JOURNEY: '/OurJourney',
  LOANS: 'Loans',
  EXPENSES: 'Expenses',
  BUSINESS_DASHBOARD: '/BusinessDashboard',
  SALES_INCENTIVE: '/SalesIncentive',
  POLICIES: '/Policies',
  MENU: 'Menu',
  MENU_PERSONALIZE: 'MenuPersonalize',
  DEAR_EMPLOYER: '/DearML',
  DEAR_EMPLOYER_CONTENT: '/DearEmployerContent',
  DEAR_EMPLOYER_SEARCH: '/DearEmployerSearch',
  ML_MONDAY_SEARCH: '/MLMondaySearch',
  NEWS: '/News',
  NEWS_CONTENT: '/NewsContent',
  NEWS_SEARCH: '/NewsSearch',
  GLOBAL_SEARCH: '/GlobalSearch',
  BROADCASTS: '/Broadcasts',
  // PUBLIC_PROFILE: '/profile/',
  MLTV_SEARCH: '/MltvSearch',
  BROADCASTS_SEARCH: '/BroadcastsSearch',
  LIKED_PEOPLE: '/LikedPeople',
  BROADCAST_DETAILS: '/BroadcastDetails',
  MI_HR_SUPPORT: '/MiHRSupport',
  MI_HR_DETAILS: '/MiHRDetails',
  MI_HR_SEARCH: '/MiHRSearch',
  MI_CHILD_CARE: '/MiCare',
  MI_CHILD_CARE_CONTENT: '/MiChildCare',
  MICROBIZ: '/MicroBiz',
  MICROBIZ_DETAIL: '/MicroBiz/article',
  MICROBIZ_SEARCH: '/MicroBiz/search',
  BTO: '/Bto',
  BTO_DETAIL: '/Bto/article',
  BTO_SEARCH: '/Bto/search',
  ANNOUNCEMENT_DETAIL: '/AnnouncementDetail',
  DIRECTORY: '/Directory',
  MY_PROFILE_DETAIL_EDIT: '/MyProfileDetailEdit',
  MY_PROFILE_DETAIL: '/MyProfileDetail',
  ALL_WISHES: '/AllWishes',
  CELEBRATION_WISHES: '/CelebrationWishes',
  EMPLOYEE_PROFILE_DETAIL: '/EmployeeProfileDetail',
  ORG_STRUCTURE: '/OrgStructure',
  MY_PROFILE_ASSET: '/MyAsset',
  PROFILE_DETAIL: 'ProfileDetail',
  WISHES: '/Wishes',
  MAINTENANCE: 'Maintenance',
  LOCKED: 'Locked',
  CELEBRATIONS: '/Celebrations',
  EVENTS: '/Events',
  EVENTS_CONTENT: '/EventsContent',
  EVENTS_GALLERY: '/GalleryEvents',
  EVENTS_DATE: '/EventsDate',
  EVENTS_SEARCH: '/EventsSearchScreen',
  FACILITY: '/Facilities',
  FACILITY_CONTENT: '/FacilityContent',
  OUR_CUSTOMER: '/OurCustomers',
  OUR_CUSTOMER_CONTENT: '/OurCustomerContent',
  OUR_CUSTOMER_RESEARCHERS_CONTENT: '/OurCustomerResearchers',
  OUR_CUSTOMER_SEARCH_SCREEN: '/OurCustomerSearchScreen',
  OUR_CUSTOMER_KNOW_SEARCH_SCREEN: '/OurCustomerSearchScreenKnow',
  OUR_CUSTOMER_ALL_SCREEN: '/OurCustomerKnowScreen',
  GALLERY_COMMON: '/GalleryScreen',
  COMMENTS: '/Comments',
  POLICY_DETAIL_SCREEN: '/Policies',
  POLICY_SEARCH_SCREEN: '/PolicySearch',
  MAIN_FILTER: 'MainFilter',
  ECARDS: '/ECards',
  ECARDS_CONTENT: '/ECardsContent',
  NEW_JOINEES_LOWER_LEVELS: '/NewJoinees',
  SOM_MAIN: '/StarsOfML',
  PDFVIEW: '/PdfView',
  ON_BOARD_SCREEN: 'OnBoardScreen',
  SPLASH_SCREEN: 'SplashScreen',
  TERMS_CONDITIONS_SCREEN: '/TermsAndConditions',
  LOGIN_SCREEN: 'LoginScreen',
  PASSCODE_SCREEN: 'PasscodeScreen',
  PASSCODE_MAIN_SCREEN: 'PasscodeMainScreen',
  OTP_VERIFICATION_SCREEN: 'OtpVerificationScreen',
  SOM_TAB: '/SomTab',
  SOM_GALLERY: '/SomGallery',
  SOM_FOREWORD: '/SomForeword',
  TAG_EMPLOYEE: '/TagEmployee',
  SOM_EMPLOYEE_DETAILS: '/StarEmployeeDetails',
  SESSION_DETAILS: '/SessionDetails',
  NEWJOINEE_SESSION_DETAILS: '/NewJoineeSessionDetails',
  NEWJOINEE_INDUCTION: '/JoineeInduction',
  MOBILE_VALIDATE_SCREEN: 'MobileNumberValidateScreen',
  EMAIL_VALIDATE_SCREEN: 'EmailValidateScreen',
  PRE_TOUR_GUIDE_SCREEN: 'PreTourGuideScreen',
  TOUR_GUIDE_ONE_SCREEN: 'TourGuideOneScreen',
  TOUR_GUIDE_TWO_SCREEN: 'TourGuideTwoScreen',
  TOUR_GUIDE_THREE_SCREEN: 'TourGuideThreeScreen',
  TOUR_GUIDE_FOUR_SCREEN: 'TourGuideFourScreen',
  TOUR_GUIDE_FIVE_SCREEN: 'TourGuideFiveScreen',
  TOUR_GUIDE_SIX_SCREEN: 'TourGuideSixScreen',
  ASK_SESSION_QUESTION: '/AskSessionQuestion',
  CEO_CHAT: '/CEOChat',
  CEO_CHAT_CONTENT: '/CEOChatContent',
  CEO_CHAT_COMMENT: '/CEOChatComment',
  CEO_ASK_QUESTION: '/CEOAskQuestion',
  CEO_ADD_COMMENT: '/CeoAddComment',
  CEO_TAG_EMPLOYEE: '/CEOTagEmployee',
  CEO_ARCHIVE_CHATS: 'CEOArchiveChats',
  CEO_ARCHIVE_LISTS: 'CEOArchiveLists',
  GALLERY: '/Gallery',
  GALLERY_SEARCH: '/GallerySearch',
  IJPR: '/Ijpr',
  IJPR_SEARCH_RESULT: '/Ijpr/ijp/search',
  IJP_REFER_SEARCH_RESULT: '/ijpr/all/search',
  IJP_REFER_ELIGIBLE_SEARCH_RESULT: '/ijpr/eligible/search',
  IJPR_DETAIL: '/Ijpr/ijp/result',
  IJP_REFER_DETAIL: '/ijpr/result',
  IJP_DETAIL: '/IjpDetail',
  IJP_REFER: 'IjpRefer',
  IJP_APPLY: 'IjpApply',
  IJPR_APPLIED: 'IjprApplied',
  NOTIFICATION_LISTING: '/Notifications',
  NOTIFICATION: '/Notification',
  PASSWORD_RESET: '/PasswordReset',
  THE_WALL: '/TheWall',
  THE_WALL_COMMENT: '/TheWall/comment',
  THE_WALL_CREATE_POST: '/TheWall/create',
  THE_WALL_EDIT_POST: '/TheWall/post/:id/edit',
  THE_WALL_POST_DETAIL: '/TheWall/post',
  THE_WALL_TAGGED_PEOPLE: '/TheWall/tags',
  PDF_FULL_SCREEN: '/PdfFullScreen',
  BREAKOUTS: '/Breakouts',
  ESG: '/Esg',
  ESG_SEARCH: '/EsgSearch',
  ESG_DETAIL: '/Esg',
  MLF: '/Mlf',
  MLF_SEARCH: '/MlfSearch',
  MLF_DETAIL: '/Mlf',
  BREAKOUTS_TAB_SCREEN: '/Breakouts',
  BREAKOUTS_INFO_DETAIL_SCREEN: '/BreakoutsInfoDetail',
  BREAKOUT_GALLERY_SCREEN: '/BreakoutsGallery',
  GALLERY_FULL_IMAGE_SCREEN: '/GalleryFullImage',
  MICRO_UNIV: '/MicroUniv',
  MICROVAX: '/MicroVax',
  MICROSELECT: '/MicroSelect',
  MICRO_GIVE: '/MicroGive',
  POSH: '/Posh',
  DONATION_SEARCH_SCREEN: '/DonationSearch',
  DONATION_TABS_SCREEN: '/Donation',
  VOLUNTEERING_LOG_SCREEN: '/VolunteeringLog',
  MY_DONATIONS_SCREEN: '/MyDonations',
  PROJECT_DETAIL_SCREEN: '/ProjectDetail',
  PROJECTS_SEARCH_SCREEN: '/ProjectsSearch',
  PROJECT_DETAIL_SEARCH_SCREEN: '/ProjectDetailSearch',
  VOLUNTEER_SEARCH_SCREEN: '/VolunteerSearch',
  PROJECT_TABS_SCREEN: '/Project',
  VOLUNTEER_TABS_SCREEN: '/Volunteer',
  QUERY_SCREEN: '/Query',
  DONATION_EVENT_BY_DATE_SCREEN: '/DonationEventByDate',
  PROJECT_DETAIL_BY_DATE_SCREEN: '/ProjectDetailByDate',
  SELECT_DONATION_SCREEN: '/SelectDonation',
  SELECT_DATE_SCREEN: '/SelectDate',
  VOLUNTEER_PARTICIPATES_SCREEN: '/VolunteerParticipates',
  HTML_VIEW_SCREEN: '/HTMLView',
  TAG_PEOPLE_SCREEN: '/TagPeople',
  MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN: '/MicrogiveGalleryTagsDetail',
  UPDATES_SCREEN: '/Updates',
  POLLS_AND_SURVEYS: '/Polls',
  POLLS_RESULTS: '/PollsResults',
  SURVEYS_PARTICIPATE: '/SurveysParticipate',
  SURVEY_SUCCESS: '/SurveySuccess',
  MY_ASSET_DETAIL: '/MyAssetDetail',
  COMMUNITIES: '/Communities',
  COMMUNITIES_DETAIL: '/Communities',
  VIEW_MEMBERS: '/ViewMembers',
  VIEW_COMMUNITY_POSTS: '/CommunityPosts',
  // COMMUNITIES_MAINSCREEN_POSTS: '/Communities',
  POST_DETAIL: '/CommunityPost',
  COMMUNITY_POST_TAGGED: '/CommunityPostTagged',
  WRITE_POST: '/WriteCommunityPost',
  EDIT_POST: '/EditPost',
  COMMUNITY_COMMENT_SCREEN: '/CommunityComment',
  RESET_PASSCODE_SCREEN: 'ResetPasscodeScreen',
  ASK_ORG: '/AskOrg',
  ASK_ORG_DETAIL: 'AskOrgDetail',
  ASK_ORG_SEARCH_RESULT: 'AskOrgSearchResult',
  ASK_ORG_SELECT_SERVICE: 'AskOrgSelectService',
  ASK_ORG_SELECT_CATEGORY: 'AskOrgSelectCategory',
  ASK_ORG_SELECT_SUB_CATEGORY: 'AskOrgSelectSubCategory',
  ASK_ORG_SELECT_CLASSIFICATION: 'AskOrgSelectClassification',
  ASK_ORG_SELECT_NEW_SERVICE_REQUEST: 'AskOrgNewServiceRequest',
  ASK_ORG_REQUEST_CREATED: 'AskOrgRequestCreated',
  ASK_ORG_ESCALATE: 'AskOrgEscalate',
  ASK_ORG_FILTER: 'AskOrgFilter',
  CONTESTS: '/Contests',
  CONTEST_DETAIL: '/ContestDetail',
  CONTEST_GALLERY: '/ContestGallery',
  CONTEST_DATE: '/ContestDate',
  CONTESTS_SEARCH: '/ContestsSearchScreen',
  CHEERS_SCREEN: '/Cheers',
  CHEERS_DETAIL: '/CheersDetailScreen',
  PROFILE_CHEERS_LISTING_SCREEN: '/ProfileCheersListing',
  NOMINATIONS_SCREEN: '/Nominations',
  SELF_SCREEN: '/Self',
  MY_MANAGER_SCREEN: '/MyManager',
  PEER_SCREEN: '/Peer',
  MY_TEAM_SCREEN: '/MyTeam',
  VIEW_ELIGIBLE_AWARD_SCREEN: '/ViewEligibleAward',
  VIEW_PEER_AWARD_SCREEN: '/ViewPeerAward',
  EMPLOYEE_SELECTION_SCREEN: '/EmployeeSelection',
  ALLOCATE_POINT_PEER_SCREEN: '/AllocatePointPeer',
  VIEW_MY_TEAM_AWARD_SCREEN: '/ViewMyTeamAward',
  EMPLOYEE_SELECTION_MY_TEAM_SCREEN: '/EmployeeSelectionMyTeam',
  MY_POINTS_SCREEN: '/MyPoints',
  REDEEM_POINTS_SCREEN: '/RedeemPoints',
  DONATE_POINTS_SCREEN: '/DonatePoints',
  ALL_EMPLOYEE_SCREEN: '/AllEmployee',
  POINTS_REDEEMED_SCREEN: '/PointsRedeemed',
  VIEW_NOMINATIONS_SCREEN: '/ViewNominations',
  REWARD_DETAILS_SCREEN: '/RewardDetail',
  WOMENS_DAY: '/WomensDay',
  WOMENS_DAY_LISTING: '/WomensDay',
  WOMENS_DAY_GALLERY: '/WomensDayGallery',
  WOMENS_DAY_GALLERY_FULL_IMAGE: '/WomensDayGalleryfullImage',
  WOMENS_DAY_WATCH_VIDEO: '/WomensDayWatchVideo',
  TAGGED_PEOPLE_SCREEN: '/TaggedPeopleScreen',
  ANNIVERSARY_SCREEN: '/Anniversary',
  ANNIVERSARY_ARTICLE_SCREEN: '/Anniversary/article',
  ANNIVERSARY_DAY_SCREEN: '/AnniversaryDay',
  ANNIVERSARY_DAY_GALLERY_FULL_IMAGE: '/AnniversaryDayGalleryfullImage',
  ANNIVERSAY_GALLERY_SCREEN: '/AnniversaryGallery',
  WATCH_LIVE_SCREEN: '/WatchLive',
  WALL_PAPERS_SCREEN: '/Wallpapers',
  CONTESTS_DETAIL_SCREEN: '/ContestsDetail',
  DIGITAL_WARRIOR_SCREEN: '/DigitalWarrior',
  WALLPAPER_CONTENT_SCREEN: '/WallpaperContent',
  ALL_EMPLOYEE_ANNIVERSARY_SCREEN: '/AllEmployeeAnniversary',
  MOMENT_TAG_DETAIL_SCREEN: '/MomentTagDetails',
  ADD_CONTESTS_SCREEN: '/AddContests',
  ADD_CONTESTS_THANKS_SCREEN: '/AddContestsThanks',
  PLEDGE_SCREEN: '/Pledge',
  TAKE_THE_PLEDGE_SCREEN: '/TakeThePledge',
  PLEDGE_THANKS_SCREEN: '/PledgeThanks',
  PANDEMIC_SUPPORT: '/PandemicSupport',
  FULL_SCREEN_VIDEO: '/FullScreenVideo',
  DOWNLOAD_APP: '/DownloadApp',
  GCDO: '/Gcdo',
  GCDO_DETAIL: '/Gcdo',
  GCDO_SEARCH: '/GcdoSearch',
  POLLS_NOTIFICATION_SCREEN: '/Polls',
  SURVEYS_NOTIFICATION_SCREEN: '/Surveys',
  MICROKNOW: '/MicroKnow',
  MICROKNOW_SEARCH: '/MicroKnowSearch',
  MICRO_LAW: '/MicroLaw',
  MICRO_LAW_CONTENT: '/MicroLawDetail',
  DI: '/Dni',
  MICROLANDERS_PLEDGE: '/MicrolandersPledge',
  DI_SEARCH: '/DISearch',
  DI_EVENT_DATE: '/DIEventDate',
  PODCASTS: '/Podcasts',
  CHAT: '/Chat',
  PODCASTS_CONTENT: '/Podcasts/Channel',
  PODCASTS_EPISODE_DETAIL: '/Podcasts/Channel/Episode',
  PODCASTS_SEARCH: '/PodcastsSearchScreen',
  WALLPAPERS: '/Wallpaper',
  TRAVEL_REQUEST: '/TravelRequest',
  TRAVEL_REQUEST_REVIEW: '/TravelRequest',
  IJPR_SAVED_JOBS: '/ijpr/saved-jobs',
  POLICY_TAB_SCREEN: '/Policies/geo',
  POSH_RAISE_CONCERN: '/Posh/RaiseConcern',
  ML_MONDAY: '/MicrolandMonday',
  ML_MONDAY_CONTENT: '/MicrolandMondayContent',
  DOWNLOAD: '/download',
  MICROLAND_VALUES: '/MicrolandValues',
  EMERGENCY_PLAYBOOK: '/EmergencyPlaybook',
  EMERGENCY_PLAYBOOK_CONTENT: '/EmergencyPlaybookDetail',
  VARIABLE_PAY: '/VariablePay',
  HOLIDAY_SCREEN: '/HolidayDetail',
  NOTIFICATION_SEARCH_SCREEN: '/NotificationSearchScreen',
  ASK_ORG_SEARCH_RESULTS_SCREEN: '/AskOrgSearchResult',
  ASK_ORG_RESULTS_DETAIL: '/AskOrgSearchResultDetail',
  MI_AUCTION: '/MiAuction',
  MI_AUCTION_TC: '/MiAuctionTC',
  FEEDBACK_SCREEN: '/SubmitFeedback',
  MICROGIVE_FEEDBACK_SCREEN: '/MicrogiveFeedback',
  MICRO_STORE_MAIN_SCREEN: '/MicroStore',
  MICRO_STORE_POLICY_SCREEN: '/MicroStore/Policy',
  MICRO_STORE_ALL_PRODUCTS_SCREEN: '/MicroStore/AllProducts',
  MICRO_STORE_PRODUCT_SCREEN: '/MicroStore/Product',
  MICRO_STORE_CART_SCREEN: '/MicroStore/shopping-bag',
  MI_STORE_CONFIRM_ADDERSS: '/MicroStore/shopping-bag/delivery-estimate',
  MI_STORE_ORDER_TC: '/MicroStore/shopping-bag/delivery-estimate/terms&conditions',
  MI_STORE_ORDER_SUCCESS: '/MicroStore/shopping-bag/delivery-estimate/terms&conditions/success',
  MI_STORE_ADDRESS_LIST_SCREEN: '/MicroStore/addresses',
  MI_STORE_ADD_ADDRESS_SCREEN: '/MicroStore/addresses/add-new-address',
  MI_STORE_EDIT_ADDRESS_SCREEN: '/MicroStore/addresses/edit-address',
  MI_STORE_SELECT_ADDRESS_SCREEN: '/MicroStore/addresses/select-address',
  MI_STORE_ORDER_LIST_SCREEN: '/MicroStore/orders-list',
  MI_STORE_SHIPMENT_DETAILS: '/MicroStore/orders-list/shipment',
  MI_STORE_ORDER_DETAILS: '/MicroStore/orderlist/order',
  MI_STORE_INVOICE_SCREEN: '/MicroStore/shipment/invoice',
  ATTENDANCE: '/Attendance',
  ML35_PICTURES_SCREEN: '/ML35Pictures',
  NEO_SESSION_DETAIL: '/NeoSessionDetail',
  ML_DAY_GALLERY_SCREEN: '/MicrolandDayGallery',
  COMMON_GALLERY_FULL_SCREEN: '/GalleryFullScreen',
  SEAT_BOOKING_MAIN_SCREEN: '/SeatBooking',
  VEHICLE_PARKING_MAIN_SCREEN: '/VehicleParking',
  BOOK_A_PARKING_SCREEN: '/BookAParking',
  BOOK_A_SEAT_SCREEN: '/BookASeat',
  VEHICLE_BOOKING_PREVIEW_SCREEN: '/VehicleBookingPreview',
  SEAT_BOOKING_PREVIEW_SCREEN: '/SeatBookingPreview',
  SEAT_LAYOUT: '/SeatLayout',
  PARKING_LAYOUT: '/ParkingLayout',
};

export const HOME_SECTION_IDENTIFIERS = {
  QUICK_ACCESS: '_quickAccess',
  FOOTER: '_footer',
};

export const HOME_SECTION_TEMPLATES = {
  CLASSIC_TEXT: 'classic_text',
  HORIZONTAL_ICONS_SMALL: 'horizontal_small_icons',
  HORIZONTAL_ICONS_LARGE: 'horizontal_large_icons',
  PROFILE_ICONS: 'profile_icons',
  DYNAMIC_COLUMNS_TEXT: 'dynamic_columns_text',
  ADJACENT_COLUMNS_TEXT: 'adjacent_columns_text',
  NEO: 'neo',
  MLTV: 'mltv',
};

export const ACCESSIBILITY_IDENTIFIERS = {
  HEADER: '_headerWeb',
  NEWS: '_news',
  MLTV: '_mltv',
  BROADCASTS: '_broadcasts',
  DEAR_EMPLOYER: '_dearML',
  DIRECTORY: '_directory',
  COMMON: '_common',
  CELEBRATIONS: '_celebrations',
  EVENTS: '_events',
  POLICIES: '_policies',
  ECARDS: '_ecards',
  SOM: '_starsOfML',
  NEW_JOINEES: '_newJoinees',
  SPLASH: '_splashPage',
  APP_AUTH: '_appAuth',
  TOUR_GUIDE: '_tourGuide',
  GALLERY: '_gallery',
  IJPR: '_ijpr',
  WALKTHROUGH: '_walkthrough',
  THE_WALL: '_theWall',
  BREAKOUTS: '_breakouts',
  ESG: '_esg',
  MLF: '_mlf',
  CEO_CHAT: '_ceoChat',
  MICROGIVE: '_microGive',
  POLLS: '_polls',
  PROFILE: '_profile',
  ALL_WISHES: '_allWishes',
  HOME: '_home',
  COMMUNITIES: '_communities',
  ASK_ORG: '_askOrg',
  CONTESTS: '_contests',
  WOMENS_DAY: '_womensDay',
  CHEERS: '_cheers',
  THIRTY_ONE_QUESTIONS: '_31Q',
  FAVORITES_SECTION: '_section_favorites',
  PASSWORD_RESET: '_passwordReset',
  BUSINESS_DASHBOARD: '_businessDashboard',
  SALES_INCENTIVE: '_salesIncentive',
  FACILITIES: '_facilities',
  PANDEMIC_SUPPORT: '_covidSupport',
  POSH: '_posh',
  OUR_CUSTOMERS: '_ourCustomers',
  GCDO: '_gcdo',
  MICROBIZ: '_microBiz',
  BTO: '_bto',
  MICROKNOW: '_microKnow',
  MICRO_LAW: '_legal',
  ANNIVERSARY_DAY: '_anniversaryDay',
  MICROVAX: '_microVax',
  MICROSELECT: '_microSelect',
  LEAVE: '_leave',
  SALARY: '_salary',
  ATTENDANCE: '_attendance',
  EXPENSES: '_expenses',
  LOANS: '_loans',
  MYINCENTIVES: '_myIncentives',
  DNI: '_dni',
  ANALYTICS_DASHBOARD: '_analyticsDashboard',
  FACEBOOK: '_facebook',
  INSTAGRAM: '_instagram',
  PODCASTS: '_podcasts',
  CHAT: '_chat',
  WALLPAPERS: '_wallpapers',
  TRAVEL_REQUEST: '_travel',
  TIMESHEET: '_timesheet',
  OUR_JOURNEY: '_ourJourney',
  MICROLAND_VALUES: '_microlandValues',
  TRAVEL_DOMESTIC: '_travelDomestic',
  ML_MONDAY: '_microlandMonday',
  CONTINUOUS_FEEDBACK: '_continuousFeedback',
  ACTIVITIES_ACHIVEMENNTS: '_activitiesAchievements',
  ONBOARDING_DASHBOARD: '_onboardingDashboard',
  MI_HR_SUPPORT: '_miHRSupport',
  MI_HR: '_miHR',
  MI_CHILD_CARE: '_michildcare',
  VARIABLE_PAY: '_variablePay',
  EMERGENCY_PLAYBOOK: '_emergencyPlaybook',
  MICRO_UNIV: '_microUniv',
  MI_AUCTION: '_miAuction',
  INSURANCE: '_insurance',
  MICRO_STORE: '_microStore',
  ML_35: '_anniversary_ml35',
  VEHICLE_PARKING: '_vehicleParking',
};

export const ACCESSIBILITY_HEADER_COMPONENTS = {
  RIGHT_ARROW: 'rightArrow',
  ACTIVE_LIKE: 'activeLike',
  COMMENT: 'comment',
  INACTIVE_LIKE: 'inactiveLike',
  SHARE: 'share',
  SEARCH: 'search',
  CALENDER: 'calendar',
  SEARCH_CANCEL: 'searchCancel',
  LEFT_ARROW: 'leftArrow',
  DRAWER: 'drawer',
  NOTIFICATION: 'notification',
  NOTIFICATIONV2: 'newNotification',
  VOICE_CONTROL: 'voiceControl',
  TAG_EMPLOYEE: 'tagEmployee',
  ARCHIVE: 'archive',
  TERMS_CONDITIONS: 'termsAndConditions',
  MORE_INFO: 'moreInfo',
  CLOSE: 'close',
  GALLERY: 'gallery',
  SEARCH_V2: 'searchV2',
  NEWSEARCH_V2: 'newSearchV2',
  BOOKMARK_OUTLINE: 'bookmarkOutline',
  BOOKMARK_FILLED: 'bookmarkFilled',
};
export const ENCODING_TYPE = {
  FORM_DATA: 'multipart/form-data',
  FORM_URL_ENCODED: 'application/x-www-form-urlencoded',
  RAW: 'application/json',
};

export const ACCESSIBILITY_FEATURE_COMPONENTS = {
  LOADING_IMAGE: 'lodingUrl',
  BUSINESS_DASHBOARD_BANNER: 'business_dashboard_banner',
  SALES_INCENTIVE_BANNER: 'sales_incentive_banner',
  CELEBRATIONS_SEARCH_FAILED: 'celebrations_search_failed',
  COMMON_DEFAULT_PROFILE: 'default_profile_icon',
  COMMON_FILTER: 'filter_icon',
  MENUSCREEN_LEFT_ICON_URL: 'menuscreen_left_icon_url',
  MENUSCREEN_RIGHT_ICON_URL: 'menuscreen_right_icon_url',
  MENUSCREEN_ICON_URL: 'menuscreen_icon_url',
  HOMECREEN_LEFT_ICON_URL: 'homescreen_left_icon_url',
  HOMECREEN_RIGHT_ICON_URL: 'homescreen_right_icon_url',
  DIRECTORY_BADGE_0_4: 'directory_badge_0_4',
  DIRECTORY_BADGE_5_9: 'directory_badge_5_9',
  DIRECTORY_BADGE_10_14: 'directory_badge_10_14',
  DIRECTORY_BADGE_15_19: 'directory_badge_15_19',
  DIRECTORY_BADGE_20_24: 'directory_badge_20_24',
  DIRECTORY_BADGE_25_29: 'directory_badge_25_29',
  DIRECTORY_BADGE_30: 'directory_badge_30',
  DIRECTORY_BADGE_35: 'directory_badge_35',
  DIRECTORY_MODAL_CLOSE: 'directory_modal_close',
  DIRECTORY_RIGHT_ARROW: 'directory_right_arrow',
  DIRECTORY_SEARCH_FAILED: 'directory_search_failed',
  ICON_URL: 'icon_url',
  TARGET_URL: 'target_url',
  TITLE: 'title',
  ROUTE: 'route',
  BROADCAST_CALENDER: 'broadcasts_calendar',
  BROADCAST_COMMENT: 'broadcasts_comment_footer',
  BROADCAST_SEND_COMMENT: 'broadcasts_send_comment',
  DEARML_CALENDER: 'dearML_calendar',
  DEARML_COMMENT_FOOTER: 'dearML_comment_footer',
  DEARML_SEND_COMMENT: 'dearML_send_comment',
  NEWS_CALENDER: 'news_calendar',
  NEWS_COMMENT_FOOTER: 'news_comment_footer',
  NEWS_SEND_COMMENT: 'news_send_comment',
  SEND_COMMENT_BLUE: 'comment_send_blue',
  EVENTS_GALLERY: 'eventsscreen_gallery_icon',
  POLICIES_RIGHT_ARROW: 'policies_right_arrow',
  POLICIES_LOCATION_MARKER: 'policies_location_marker',
  ECARDS_LEFT_ARROW: 'ecards_left_arrow',
  ECARDS_RIGHT_ARROW: 'ecards_right_arrow',
  ECARDS_SHARE: 'ecards_card_share',
  SOM_BADGE_5: 'starsOfOrg_badge_5',
  SOM_BADGE_10: 'starsOfOrg_badge_10',
  SOM_BADGE_15: 'starsOfOrg_badge_15',
  SOM_BADGE_20: 'starsOfOrg_badge_20',
  SOM_BADGE_25: 'starsOfOrg_badge_25',
  SOM_BADGE_30: 'starsOfOrg_badge_30',
  SOM_BADGE_35: 'starsOfOrg_badge_35',
  SOM_BADGE_40: 'starsOfOrg_badge_40',
  SOM_BADGE_45: 'starsOfOrg_badge_45',
  SOM_BADGE_50: 'starsOfOrg_badge_50',
  SOM_TESTIMONIAL_STAR_TRUE: 'starsOfOrg_testimonial_true_star',
  SOM_TESTIMONIAL_STAR_FALSE: 'starsOfOrg_testimonial_false_star',
  SOM_EMPLOYEE_SELECT: 'starsOfOrg_select_employee',
  SOM_TAG_EMPLOYEE: 'starsOfOrg_tag_employee',
  SOM_EMPLOYEE_CANCEL: 'starsOfOrg_cancel',
  DIRECTORY_LINKEDIN: 'directory_linkedin',
  DIRECTORY_TWITTER: 'directory_twitter',
  DIRECTORY_WEBSITE: 'directory_website_url',
  NEW_JOINEES_LINK: 'new_joinees_link',
  NEW_JOINEES_PDF_ATTACHMENT: 'new_joinees_pdf_attachment',
  NEW_JOINEES_LINK_SQUARE: 'new_joinees_link_square',
  NEW_JOINEES_RATE_STAR: 'new_joinees_rate_star',
  NEW_JOINEES_DEFAULT_PROFILE: 'new_joinees_default_profile',
  NEW_JOINEES_VIDEO_PLAY_BUTTON: 'new_joinees_video_play_button',
  MORE_INFO: 'common_moreinfo',
  COMPANY_LOGO: 'company_logo',
  ERROR: 'error',
  PASSCODE_DELETE: 'passcode_delete',
  PASSCODE_PROCEED: 'passcode_proceed',
  ICON_2: 'icon_2',
  ICON_3: 'icon_3',
  ICON_4: 'icon_4',
  ICON_5: 'icon_5',
  GALLERY_LOCATION: 'galleryscreen_location_marker',
  IJPR_LOCATION_MARKER: 'ijpr_location_marker',
  IJPR_JOB_POSTING_CARD: 'ijpr_job_posting_card',
  IJPR_RIGHT_ARROW: 'ijpr_right_arrow',
  IJPR_SEARCH: 'ijpr_search',
  IJPR_SEACH_BUTTON_ICON: 'ijpr_search_button_icon',
  FACILITY_BUSINESS_CARD: 'facilities_business_card',
  WALL_ACTIVE_LIKE: 'theWall_active_like',
  WALL_INACTIVE_LIKE: 'theWall_inactive_like',
  WALL_COMMENT_TAG: 'theWall_comment_tag',
  WALL_COMMENT: 'theWall_post_comment',
  WALL_UPLOAD_PHOTO: 'theWall_upload_photo',
  WALL_RIGHT_ARROW: 'theWall_right_arrow',
  WALL_VIDEO: 'theWall_video',
  WALL_PDF: 'theWall_pdf_attachment',
  WALL_TAG_EMPLOYEE: 'theWall_tag_employee',
  WALL_ADD_LINK: 'theWall_add_link',
  WALL_CANCEL: 'theWall_cancel',
  WALL_DOWN_ARROW: 'theWall_down_arrow',
  WALL_UP_ARROW: 'theWall_up_arrow',
  WALL_REMOVE_PHOTO: 'theWall_remove_photo',
  BREAKOUTS_POST_COMMENT: 'breakouts_post_comment',
  BREAKOUTS_ACTIVE_LIKE: 'breakouts_active_like',
  BREAKOUTS_INACTIVE_LIKE: 'breakouts_inactive_like',
  BREAKOUTS_RATE_STAR: 'breakouts_rate_star',
  BREAKOUTS_LEFT_ARROW: 'breakouts_left_arrow',
  BREAKOUTS_RIGHT_ARROW: 'breakouts_right_arrow',
  BREAKOUTS_UPLOAD_PHOTO: 'breakouts_upload_photo',
  BREAKOUTS_LINK_SQUARE: 'breakouts_link_square',
  BREAKOUTS_PDF_ATTACHMENT: 'breakouts_pdf_attachment',
  BREAKOUTS_CALENDER_FILTER: 'breakouts_calendar_filter',
  BREAKOUTS_RECENT_FILTER: 'breakouts_recent_filter',
  BREAKOUTS_BREAKOUT_SUCCESS: 'breakouts_success',
  CEO_CHAT_ACTIVE_LIKE: 'ceoChat_active_like',
  CEO_CHAT_INACTIVE_LIKE: 'ceoChat_inactive_like',
  CEO_CHAT_COMMENT: 'ceoChat_comment',
  CEO_CHAT_TAG_EMPLOYEE: 'ceoChat_tag_employee',
  CEO_CHAT_REPLY: 'ceoChat_tag_reply',
  MICROGIVE_FAQ: 'microgive_faq',
  MICROGIVE_SHARE_COMMENT: 'microgive_share_comment',
  MICROGIVE_CALENDER: 'microgive_calendar',
  MICROGIVE_LOCATION_MARKER: 'microgive_location_marker',
  MICROGIVE_TAG_EMPLOYEE: 'microgive_tag_employee',
  MICROGIVE_SHARE_IMAGE: 'microgive_share_image',
  MICROGIVE_UPLOAD_PHOTO: 'microgive_upload_photo',
  MICROGIVE_SUCCESS: 'microgive_success',
  POLLS_NEXT_ARROW: 'polls_next_arrow',
  POLLS_PREV_ARROW: 'polls_previous_arrow',
  POLLS_SUCCESS: 'polls_success',
  PROFILE_DEFAULT_PICTURE: 'profile_default_picture',
  PROFILE_EDIT: 'profile_edit',
  PROFILE_CELEBRATION_WISHES: 'profile_celebration_wishes',
  DEI_CHAMP_CARD: 'dei_champ_card',
  DEI_CHAMP_BADGE: 'dei_champ_badge',
  PROFILE_CALENDAR: 'profile_calendar',
  PROFILE_DOT_INDICATOR: 'profile_dot_indicator',
  PROFILE_BACKGROUND_PATTERN: 'profile_background_pattern',
  COMMUNITIES_CLOSE: 'communities_close',
  COMMUNITIES_ACTIVE_LIKE: 'communities_active_like',
  COMMUNITIES_INACTIVE_LIKE: 'communities_inactive_like',
  COMMUNITIES_ADD_LINK: 'communities_add_link',
  COMMUNITIES_CLOSED_GALLERY: 'communities_closed_gallery',
  COMMUNITIES_OPEN_GALLERY: 'communities_open_gallery',
  COMMUNITIES_MEMBERS: 'communities_members',
  COMMUNITIES_POSTS: 'communities_posts',
  COMMUNITIES_TAG_PEOPLE: 'communities_tag_people',
  COMMUNITIES_POST_COMMENT: 'communities_post_comment',
  COMMUNITIES_MORE_OPTIONS: 'communities_more_options',
  COMMUNITIES_PLUS_ICON: 'community_plus_icon',
  MICROGIVE_CLOSE_EVENT_DIALOG: 'microgive_close_event_dialog',
  MICROGIVE_POST_COMMENT: 'microgive_post_comment',
  MICROGIVE_SEARCH_FAILED: 'microgive_search_failed',
  HOME_VOICE_DIALOG_START_RECORD: 'home_voice_dialog_start_record',
  HOME_GREETING_CARD_DIALOG_CLOSE: 'home_greeting_card_dialog_close',
  HOME_GREETING_CARD_ADVANCEMENT_BACKGROUND: 'home_greeting_card_advancement_background',
  HOME_GREETING_CARD_ANNIVERSARY_BACKGROUND: 'home_greeting_card_anniversary_background',
  HOME_GREETING_CARD_BIRTHDAY_BACKGROUND: 'home_greeting_card_birthday_background',
  HOME_GREETING_CARD_ADVANCEMENT_SIGN_OFF: 'home_greeting_card_advancement_sign_off',
  HOME_GREETING_CARD_ANNIVERSARY_SIGN_OFF: 'home_greeting_card_anniversary_sign_off',
  HOME_GREETING_CARD_BIRTHDAY_SIGN_OFF: 'home_greeting_card_birthday_sign_off',
  HOME_EDIT_FAVORITES: 'home_edit_favorites',
  HOME_NEW_POST: 'home_new_post',
  ASK_ORG_TIMELINE_STAR: 'ask_org_timeline_star',
  ASK_ORG_UPLOAD_PHOTOS: 'ask_org_upload_photos',
  ASK_ORG_UPLOAD: 'ask_org_upload',
  ASK_ORG_PREVIOUS_ANSWER: 'ask_org_previous_answer',
  ASK_ORG_NEXT_ANSWER: 'ask_org_next_answer',
  ASK_ORG_SEARCH_QUESTIONS: 'ask_org_search_questions',
  ASK_ORG_SUCCESS: 'ask_org_success',
  ASK_ORG_SUCCESS_V2: 'ask_org_success_v2',
  ASK_ORG_FILTER: 'ask_org_filter',
  ASK_ORG_CALENDAR: 'ask_org_calendar',
  ASK_ORG_TIMELINE_CALENDAR: 'ask_org_timeline_calendar',
  ASK_ORG_RATE_STAR: 'ask_org_rate_star',
  ASK_ORG_CLOSE: 'ask_org_close',
  CHAT_BOT: 'chat_bot',
  CHAT_BOT_WHITE: 'chat_bot_white',
  ASK_ORG_PDF_ATTACHMENT: 'ask_org_pdf_attachment',
  ASK_ORG_HAPPY_TO_HELP: 'ask_org_happy_to_help',
  CONTESTS_RIGHT_ARROW: 'contests_right_arrow',
  WOMENS_DAY_LIVE_VIDEO: 'womens_day_live_video',
  WOMENS_DAY_PLAY_VIDEO: 'womens_day_play_video',
  WOMENS_DAY_REFRESH: 'womens_day_refresh',
  WOMENS_DAY_WATCH_LIVE: 'womens_day_watch_live',
  WOMENS_DAY_CPO_BACKGROUND: 'womens_day_cpo_message_background',
  WOMENS_DAY_SIGNATURE: 'womens_day_cpo_signature',
  COMMITMENT_PLUS_ICON: 'commitment_plus_icon',
  CHEERS_ACTIVE_LIKE: 'cheers_active_like',
  CHEERS_INACTIVE_LIKE: 'cheers_inactive_like',
  CHEERS_POST_COMMENT: 'cheers_post_comment',
  CHEERS_SUCCESS: 'cheers_success',
  CHEERS_TAG_EMPLOYEE: 'cheers_tag_employee',
  CHEERS_APPLAUSE: 'cheers_applause',
  CHEERS_BRIGHT_BEGINNER: 'cheers_bright_beginner',
  CHEERS_CEO_CHOICE: 'cheers_ceo_choice',
  CHEERS_CREAM_OF_TEAM: 'cheers_cream_of_team',
  CHEERS_CUSTOMER_CHOICE: 'cheers_customers_choice',
  CHEERS_DEFAULT_BADGE: 'cheers_default_badge',
  CHEERS_HALL_OF_FAME: 'cheers_hall_of_fame',
  CHEERS_KEY_CONTRIBUTOR: 'cheers_key_contributor',
  CHEERS_REWARD_CARD: 'cheers_reward_card',
  CHEERS_SUPER_SKIPPER: 'cheers_super_skipper',
  CHEERS_SUPER_SQUAD: 'cheers_super_squad',
  CHEERS_TECK_GHEEK: 'cheers_tech_geek',
  CHEERS_TECH_WIZARDS: 'cheers_tech_wizard',
  CHEERS_WOW: 'cheers_wow',
  CHEERS_ARROW_DOWN: 'cheers_arrow_down',
  CHEERS_ARROW_UP: 'cheers_arrow_up',
  CHEERS_ADD_POINT: 'cheers_add_point',
  CHEERS_ADD_POINT_DISABLED: 'cheers_add_point_disabled',
  CHEERS_SUBTRACT_POINT: 'cheers_subtract_point',
  CHEERS_SUBTRACT_POINT_DISABLED: 'cheers_subtract_point_disabled',
  CHEERS_DEPENDABLE_BADGE: 'cheers_dependable_badge',
  CHEERS_INNOVATOR_BADGE: 'cheers_innovator_badge',
  CHEERS_ISRCHAMP_BADGE: 'cheers_isrchamp_badge',
  CHEERS_ISRCHAMPIONSTROPHY_BADGE: 'cheers_isrchampionstrophy_badge',
  CHEERS_PROBLEMSOLVER_BADGE: 'cheers_problemsolver_badge',
  CHEERS_SPEEDYFINISHER_BADGE: 'cheers_speedyfinisher_badge',
  CHEERS_TEAMPLAYER_BADGE: 'cheers_teamplayer_badge',
  CHEERS_CLOSE: 'cheers_close',
  CHEERS_DOWNLOAD_CERTIFICATE: 'cheers_certificate_download',
  ANNIVERSARY_ACTIVE_LIKE: 'anniversary_active_like',
  ANNIVERSARY_INACTIVE_LIKE: 'anniversary_inactive_like',
  ANNIVERSARY_TAG_EMPLOYEE: 'anniversary_tag_employee',
  ANNIVERSARY_POST_COMMENT: 'anniversary_post_comment',
  ANNIVERSARY_UPLOAD: 'anniversary_upload',
  ANNIVERSARY_LIVE_VIDEO: 'anniversary_live_video',
  ANNIVERSARY_PLAY_VIDEO_WHITE: 'anniversary_play_video_white',
  ANNIVERSARY_PLAY_VIDEO_RED: 'anniversary_play_video_red',
  ANNIVERSARY_WATCH_LIVE: 'anniversary_watch_live',
  ANNIVERSARY_CEO_MESSAGE_BACKGROUND: 'anniversary_ceo_message_background',
  ANNIVERSARY_CEO_SIGNATURE: 'anniversary_ceo_signature',
  ANNIVERSARY_IMAGE_DOWLOAD: 'anniversary_image_download',
  ANNIVERSARY_IMAGE_CLOSE: 'anniversary_image_close',
  ANNIVERSARY_REFRESH: 'anniversary_refresh',
  ANNIVERSARY_SUCCESS: 'anniversary_success',
  ANNIVERSARY_CLOSE: 'anniversary_close',
  ANNIVERSARY_RIGHT_ARROW: 'anniversary_right_arrow',
  MENUSCREEN_ADD_FAVORITE: 'menuscreen_add_favorite',
  MENUSCREEN_ADDED_FAVORITE: 'menuscreen_added_favorite',
  MENUSCREEN_SELECTED_FAVORITE: 'menuscreen_selected_favorite_circle',
  MENUSCREEN_NOTSELECTED_FAVORITE: 'menuscreen_notselected_favorite_circle',
  MENUSCREEN_REMOVE_FAVORITE_CERCLE: 'menuscreen_remove_favorite_cercle',
  MENUSCREEN_REMOVE_FAVORITE: 'menuscreen_remove_favorite',
  GALLERY_SEARCH_FAILED: 'galleryscreen_search_failed',
  ECARDS_DOWNLOAD_CARD: 'ecards_card_download',
  PROFILE_ID_CARD: 'profile_id_card',
  PROFILE_ID_CARD_TENURE: 'profile_id_card_tenure',
  PROFILE_ID_CARD_BADGE_5: 'profile_id_card_badge_5',
  PROFILE_ID_CARD_BADGE_10: 'profile_id_card_badge_10',
  PROFILE_ID_CARD_BADGE_15: 'profile_id_card_badge_15',
  PROFILE_ID_CARD_BADGE_20: 'profile_id_card_badge_20',
  PROFILE_ID_CARD_BADGE_25: 'profile_id_card_badge_25',
  PROFILE_ID_CARD_BADGE_30: 'profile_id_card_badge_30',
  COMMON_BOT_FACE: 'common_bot_face',
  COMMON_BOT_AVATAR: 'common_bot_avatar',
  THIRD_PARTY_WEBSITE: 'third_party_website',
  PROFILE_SHOW_MORE: 'profile_show_more',
  PROFILE_SHOW_LESS: 'profile_show_less',
  PROFILE_CALL: 'profile_call',
  PROFILE_SMS: 'profile_sms',
  PROFILE_HIERARCHY_EXPAND: 'profile_hierarchy_expand',
  PROFILE_HIERARCHY_COLLAPSE: 'profile_hierarchy_collapse',
  PROFILE_UPLOAD_PHOTO: 'profile_upload_photo',
  PROFILE_VISIBLE: 'profile_visible',
  PROFILE_HIDE: 'profile_hide',
  PROFILE_COPY: 'profile_copy',
  PROFILE_EMAIL: 'profile_email',
  PROFILE_CELEBRATION: 'profile_celebration',
  PROFILE_WISHES: 'profile_wishes',
  PROFILE_VIEW_HIERARCHY: 'profile_view_hierarchy',
  PROFILE_STARS: 'profile_stars',
  PROFILE_VIDEO_CALL: 'profile_video_call',
  PROFILE_BADGE: 'profile_idcard',
  PROFILE_VIDEO_CALL_DISABLED: 'profile_video_call_disabled',
  PROFILE_EMAIL_DISABLED: 'profile_email_disabled',
  PROFILE_CALL_DISABLED: 'profile_call_disabled',
  PROFILE_MAIL_BLUE: 'profile_mail_blue',
  PROFILE_CALENDER_BLUE: 'profile_calender_blue',
  PROFILE_CALENDER_OUTLINE_RED: 'profile_calendar_outline_red',
  PROFILE_CALENDER_OUTLINE_GREY: 'profile_calendar_outline_grey',
  PROFILE_SMS_DISABLED: 'profile_post_comment',
  COMMENTS_MORE_VERTICAL: 'more_vertical',
  READ_OUT_LOUD_START: 'common_speak_out_start',
  READ_OUT_LOUD_PAUSE: 'common_speak_out_pause',
  EMOJI_SMILE_ICON: 'common_emoji_smile',
  COMMON_COMPANY_LOGO: 'common_company_logo',
  COMMON_CIRCULAR_CAMERA_RED: 'common_circular_camera_red',
  COMMON_CLOSE_EYE_RED: 'common_close_eye_red',
  COMMON_OPEN_EYE_RED: 'common_open_eye_red',
  COMMON_OPEN_EYE_BLUE: 'common_open_eye_blue',
  COMMON_SELECT_ARROW: 'common_select_arrow',
  COMMON_FILTER_WHITE: 'common_filter_white',
  COMMON_EMPTY_STATE: 'common_empty_state',
  COMMON_LINKEDIN: 'common_linkedin',
  COMMON_LINKEDIN_DISABLED: 'common_linkedin_disabled',
  COMMON_QUERY: 'common_query',
  COMMON_DOCUMENT: 'common_document',
  COMMON_LOCKED: 'common_locked',
  COMMON_PIN: 'common_pin',
  COMMON_PAUSE: 'common_pause',
  COMMON_PLAY: 'common_play',
  COMMON_CIRCULAR_PLAY: 'common_circular_play',
  COMMON_CHAT_WHITE: 'common_chat_white',
  COMMON_GROUP_WHITE: 'common_group_white',
  COMMON_GROUP_THEME: 'common_group_theme',
  COMMON_CIRCULAR_GROUP_GREY: 'common_circular_group_grey',
  COMMON_CIRCULAR_CAMERA_GREY: 'common_circular_camera_grey',
  COMMON_PDF_BLACK_OUTLINE: 'common_pdf_black_outline',
  COMMON_NEW_JOINEE_WELCOME_CARD: 'new_joinee_welcome_card',
  COMMON_CHAT: 'profile_chat',
  COMMON_CHAT_DISABLED: 'profile_chat_disabled',
  COMMON_CIRCULAR_PLAY_OUTLINE_BLUE: 'common_circular_play_outline_blue',
  COMMON_ALERT: 'common_alert',
  COMMON_ATTACHMENT_BLUE: 'common_attachment_blue',
  COMMON_CIRCULAR_ARROW_DOWN_GREY: 'common_circular_arrow_down_grey',
  COMMON_CIRCULAR_ARROW_UP_GREY: 'common_circular_arrow_up_grey',
  COMMON_CLOSE_GREY: 'common_close_grey',
  COMMON_INFO: 'common_info',
  COMMON_TRASH_RED: 'common_trash_red',
  PODCASTS_BANNER: 'podcasts_banner',
  COMMON_BOOKMARK_OUTLINE_GREY: 'common_bookmark_outline_grey',
  COMMON_BOOKMARK_FILLED_GREY: 'common_bookmark_filled_grey',
  COMMON_CIRCULAR_CHAT_GREY: 'common_circular_chat_grey',
  COMMON_HEXAGON_WALLPAPER: 'common_hexagon_wallpaper',
  COMMON_SEARCH_GREY: 'common_search_grey',
  COMMON_RIGHT_ARROW: 'common_right_arrow',
  COMMON_LEFT_ARROW: 'common_left_arrow',
  COMMON_UP_ARROW: 'common_up_arrow',
  COMMON_DOWN_ARROW: 'common_down_arrow',
  COMMON_CELEBRATION_WISHES: 'common_celebration_wishes',
  COMMON_DOWNLOAD_ICON_RED: 'common_download_icon_red',
  COMMON_DOWNLOAD_ICON_WHITE: 'common_download_icon_white',
  COMMON_DOWNLOAD_ICON_GRAY: 'common_download_icon_gray',
  PROFILE_DEI_STAR: 'profile_dei_star',
  PROFILE_ESG_STAR: 'profile_esg_star',
  COMMON_ML_ICON: 'common_ml_icon',
  QUESTION_MARK_WHITE: 'question_mark_white',
  STORE_TRY_ON: 'try_on',
  STORE_SORT: 'sort',
  STORE_ORDER_SUCCESS: 'order_success',
  STORE_FILTER: 'filter',
  STORE_DELETE: 'delete',
  STORE_PERSON: 'person',
  STORE_PERSON_ACTIVE: 'person_active',
  STORE_HOME: 'home',
  STORE_HOME_ACTIVE: 'home_active',
  STORE_GRID: 'grid',
  STORE_GRID_ACTIVE: 'grid_active',
  ADDRESS_BOOK_LOCATION: 'address_book_location',
  ORDER_HISTORY_CART: 'order_history_cart',
  STORE_CART: 'store_cart',
  ACTIVE_STAR: 'active_star',
  IN_ACTIVE_STAR: 'in_active_star',
  CART_BLUE: 'cart_blue',
  DELIVERY_NOTE: 'delivery_note',
  SF_CLOSE_ICON: 'sf_close_icon',
  GREEN_ERROR: 'green_error',
  RED_ERROR: 'red_error',
  GEO_WARNING: 'geo_warning',
  BC_PICTURE: 'bc_picture',
  BC_QR_CODE: 'bc_qr_code',
  BC_TEXT: 'bc_text',
  BC_VCF: 'bc_vcf',
  OFFICE_LOCATION_ICON: 'office_location_icon',
};

export const LOADING_MESSAGES = {
  DEFAULT: 'Loading...',
  DEAR_EMPLOYER_CONTENT_MAIN: 'Fetching articles details..',
  LIKE_UPDATE: 'Updating like count',
  COMMENT_UPDATE: 'Updating comment...',
  NEWS_CONTENT_MAIN: 'Fetching News...',
  FETCH_EVENTS: 'Fetching event details...',
  EVENT_UPDATING: 'Updating...',
  EVENT_REGISTER: 'Registering event...',
  FETCH_FACILITY: 'Fetching facilities...',
  FETCH_CHEERS: 'Fetching cheers...',
  FETCH_FACILITY_ID: 'Fetching facility...',
  FETCH_ECARD: 'Fetching E-CARDS...',
  APPLY_FOR_JOB: 'Applying for job...',
  REFER_FOR_JOB: 'Referring for job...',
  SUBMIT_RATING: 'Submitting Rating...',
  FETCH_EMPLOYEE_DETAILS: 'Fetching employee details...',
  FETCH_COMMUNITY: 'Fetching communities',
  FETCH_COMMUNITY_POST: 'Fetching community posts',
  ASK_ORG_REOPENING_TICKET: 'Reopening ticket...',
  ASK_ORG_UPDATING_APPROVAL_STATUS: 'Updating ticket...',
  FETCH_CONTESTS: 'Fetching contests',
  CONTEST_REGISTER: 'Registering contest...',
  POSTING_DISCUSSION: 'Posting on Discussion...',
  SUBMIT_NOMINATIONS: 'Submitting nominations...',
  LEAVE_FORM: 'Leaving Community..',
  REPORTING_POST: 'Reporting post...',
  PUBLISHING: 'Publishing...',
  CREATING_GROUP: 'Creating group, please wait...',
  UPLOADING_GROUP_ICON: 'Uploading group icon, please wait...',
  UPDATING: 'Updating...',
  ADDING_MEMBERS: 'Adding Members, please wait...',
  FORWARDING_MESSAGES: 'Forwarding Message...',
  GENERATING_BUSINESS_CARD: 'Generating business card...',
};

export const SUCCESS_MESSAGES = {
  MESSAGE_SENT: 'Message Sent',
  CHECKLIST_UPDATED: 'Checklist Updated',
  SESSION_QUESTION_SENT: 'Thanks for your question.',
  NEW_JOINEE_EVENT_REGISTERED: 'Thank you for participating.',
  IJPR_JOB_APPLIED_TITLE: 'Application Submitted',
  IJPR_JOB_APPLIED_SUBTITLE:
    'Thank you. Your application for this job has been submitted successfully.',
  IJPR_REFERRAL_APPLIED_TITLE: 'Thank You',
  IJPR_REFERRAL_APPLIED_SUBTITLE: `Thank you for submitting your application! You can expect a response within 3 working days. For any queries, feel free to contact us at ijp@microland.com. We're here to support you!
Best of luck, and thank you for your interest in growing within the organization!`,
  MICROGIVE_INITIATIVE_THANKS:
    'Thank you for expressing your\ninterest to volunteer for this initiative',
  SURVEY_SUCCESS: 'Thank you for participating in the survey',
  CONTEST_REGISTER_SUCESS: 'Contest registered successfully',
  EVENT_REGISTER_SUCESS: 'Registered successfully.',
  PHONE_NUMBER_HIDDEN_SUCCESS: 'Phone number hidden from public view',
  BLOOD_GROUP_HIDDEN_SUCCESS: 'Blood group hidden from public view',
  BIRTHDAY_HIDDEN_SUCCESS: 'Birthday is hidden from public view',
  COVID19_VACCINATION_STATUS_HIDDEN_SUCCESS:
    'COVID-19 vaccination status is hidden from public view',
  PHONE_NUMBER_VISIBLE_SUCCESS: 'Phone number visible for public view',
  BLOOD_GROUP_VISIBLE_SUCCESS: 'Blood group visible for public view',
  COVID19_VACCINATION_STATUS_VISIBLE_SUCCESS:
    'COVID-19 vaccination status is visible for public view',
  BIRTHDAY_VISIBLE_SUCCESS: 'Birthday is visible for public view',
  CHAT_CLEARED: 'The chat has been cleared successfully',
};

export const ERROR_MESSAGES = {
  ID_CARD_PROFILE_PIC_EMPTY: 'Please update your Formal Profile Picture on MiHR.',
  DEFAULT: 'Something went wrong. Please try again later',
  UNAUTHORIZED_USER: 'Unauthorized access, connection unsuccessful',
  FIREBASE_DB_ERROR: 'Error fetching firebase data',
  API_TITLE_DEFAULT: 'Error',
  API_MESSAGE_DEFAULT: 'Something went wrong. Please try again later',
  NO_INTERNET: 'Please check internet connection',
  SEARCH_NO_RESULTS: 'Sorry, no matches found.',
  BROADCAST_NO_DATA: 'No data found.',
  DIRECTORY_NO_DATA: 'Sorry, no directory data found',
  EMPTY_TEXT: 'Please enter a text',
  IJPR_INVALID_FIRST_NAME: 'Please enter a valid first name',
  IJPR_INVALID_LAST_NAME: 'Please enter a valid last name',
  IJPR_INVALID_EMAIL: 'Please enter a valid email address',
  IJPR_INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
  IJPR_INVALID_LINKEDIN_PROFILE_URL: 'Please enter a valid url',
  IJPR_AGREE_TO_TERMS: 'Please check the terms',
  IJPR_NO_POSTINGS: 'No job postings to show',
  IJPR_NO_REFERRALS: 'You have not referred anyone yet',
  SURVEYS_MANDATORY_FIELD: 'Please answer the question.',
  SURVEYS_MANDATORY_FIELDS: 'Please answer all mandatory questions',
  IMAGE_FORMAT_NOT_SUPPORTED: 'File format is not supported.',
  SESSION_NO_QUESTIONS_ASKED: 'No Questions Asked Yet',
  ASK_ORG_NO_PENDING_APPROVALS: 'You have no requests pending approval',
  ASK_ORG_NO_PENDING_REQUESTS: 'You have not raised any request',
  ASK_ORG_NO_ESCALATION_MATRIX: 'No escalation matrix found',
  ASK_ORG_NEW_REQUEST_MANDATORY_DESCRIPTION: 'Description is mandatory',
  ASK_ORG_NEW_REQUEST_FILE_SIZE_EXCEEDED: 'File size should be less than 3 Mb',
  ASK_ORG_NEW_REQUEST_TOTAL_PHOTOS_EXCEEDED: 'Sorry, you can only upload a maximum of 5 photos',
  ASK_ORG_NO_FILTERS_AVAILABLE: 'No requests avilable for the filter criteria',
  STAY_TUNED: 'Stay tuned for the action',
  POLL_PARTICIPATED: "You've already participated in this poll",
  SURVEY_PARTICIPATED: "You've already participated in this Survey",
  DAY_ONE_FEEDBACK_SUBMITTED: 'You have already completed this survey.',
  COMMUNITY_POST_TAG: 'You cant tag this post',
  MENU_REACHED_MAXIMUM_FAVOURITES: 'You can choose only 4 favourites',
  MENU_REACHED_MINIMUM_FAVOURITES: 'Please select a minimum of 2 favourites',
  MENU_NO_OPTIONAL_FEATURES_AVAILABLE: 'There are no features available for this section',
  ENTER_POINTS: 'Please enter the points',
  ENTER_POINTS_MULTIPLE_TEN: 'Please enter the points in multiples of 10',
  NOT_SUFFICIENT_POINTS: 'You do not have sufficient points to donate',
  POINTS_SHARED: 'Point will be shared only in 100 denomination',
  SELECT_RECIPIENTS: 'Please select a recipient',
  SELECT_MORE_RECIPIENTS: 'Please select more than one recipient',
  MINIMUM_POINTS_TO_REDEEM: 'Please enter atleast minimum points to Redeem',
  POINTS_TO_REDEEM_SHOULD_BE_MULTIPLES_OF_10: 'Redeeming points should be multiples of 10',
  MAXIMUM_POINTS_TO_REDEEM: 'You can only redeem 100000 points at once',
  ENTER_ALL_FIELDS: 'Please enter all mandatory fields',
  MANAGER_REFERENCE: 'Manager Reference can not be empty',
  STAY_TUNED_FOR_UPDATES: 'Stay tuned for updates...',
  EXCHANGE_YOUR_THOUGHTS: 'Exchange your thoughts',
  NO_REFERENCE_MATERIAL_AVAILABLE: 'No reference materials are available',
  BREAKOUTS_GALLERY_NO_IMAGE: 'Add to the memories on Gallery. Share an image(s)',
  ML_35_GALLERY_NO_IMAGE: 'Share your pictures in this space.',
  NO_DATA_FOUND: 'No data found.',
  NO_RESULTS_FOUND: 'No results found',
  PROFILE_NOT_FOUND: 'This profile is no longer available.',
  AGENDA_MESSAGE:
    'Welcome. You will be able to view the scheduled sessions for this BreakOut here on Agenda...',
  STAY_TUNED_FOR_UPCOMING_EVENTS: 'Stay tuned for upcoming events',
  NO_EVENT_ON_THIS_DAY: 'No events on this day',
  NO_PROJECT_TO_SHOW: 'No projects to show',
  NO_AWARD_TO_SHOW: 'No Awards to show',
  CEO_TAG_EMPLOYEE_MAX_LIMIT: 'Maximum 5 people are allowed to tag.',
  POINTS_NO_DATA: 'No data found.',
  UPDATE_PROFILE_PHOTO: 'Please update your Photo',
  UPDATE_PERSONAL_NUMBER: 'Please update your personal contact number',
  UPDATE_EMERGENCY_NUMBER: 'Please update your emergency contact number',
  UPDATE_EMERGENCY_NAME: 'Please update your emergency contact name',
  INVALID_PERSONAL_NUMBER: 'Please enter valid personal phone number',
  INVALID_EMERGENCY_NUMBER: 'Please enter valid emergency phone number',
  INVALID_BUSINESS_NUMBER: 'Please enter valid business phone number',
  INVALID_EMERGENCY_EMAIL: 'Please enter valid emergency email address',
  INVALID_LINKEDIN_LINK:
    'Invalid LinkedIn URL. Please make sure the format follows https://www.linkedin.com/in/xyz',
  INVALID_WEBSITE_LINK: 'Invalid Website URL',
  INVALID_INSTAGRAM_LINK: 'Invalid Instagram URL',
  INVALID_FACEBOOK_LINK: 'Invalid Facebook URL',
  INVALID_TWITTER_LINK: 'Invalid Twitter URL',
  INVALID_EXTENSION_FORMAT: 'Invalid extension',
  INVALID_PRONOUNS: 'Invalid Pronouns',
  DISPLAY_NAME_MAX_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for nick name field. Please try shortening the text',
  DESCRIPTION_MAX_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for description field. Please try shortening the text',
  ADDRESS_MAX_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for address field. Please try shortening the text',
  FORM_ERRORS: 'You have unresolved errors on this page, please correct them to proceed',
  PANDEMIC_SUPPORT_NO_ADVISORIES: 'No advisories to show',
  PANDEMIC_SUPPORT_NO_GUIDES: 'No guides to show',
  PANDEMIC_SUPPORT_NO_CONVOS: 'No conversations to show',
  WALL_TAG_ERROR: "You can't tag this comment",
  WALL_FILE_ALREADY_PRESENT: 'File already present.',
  WALL_FILE_MAX_SIZE_ERROR: 'File size should be less than 2MB',
  WALL_MAX_YOUTUBE_LINKS: 'You can add maximum 10 youtube links',
  WALL_LINK_ALREADY_ADDED: 'Link is already added.',
  NO_IMAGES_TO_SHOW: 'No images to show',
  NO_VIDEOS_TO_SHOW: 'No videos to show',
  NO_POST_AVAILABLE: 'No post Available',
  NO_DI_UPCOMING_EVENTS: 'There are no upcoming events. Please come back later.',
  NO_DI_PAST_EVENTS: 'There are no past events.',
  DI_NO_IMAGES: 'No images to show',
  IJPR_NO_JOBS_APPLIED: 'You have not applied for any job yet',
  IJPR_NO_JOBS_AVAILABLE:
    'Sorry! There are no internal job postings at the moment. Please come back again.',
  IJPR_NOT_ELIGIBLE:
    'Thank you for visiting the IJP module. Our policy covers employees between bands P1 to P5. Since your level is outside of the mentioned bands, there are no opportunities to show for IJPs.',
  IJPR_NO_REFERRALS_AVAILABLE:
    'Sorry! There are no referrals at the moment. Please come back again.',
  UPDATE_FIRST_NAME: 'Please update first name',
  UPDATE_EMAIL: 'Please update email address',
  UPDATE_PHONE: 'Please update phone number',
  UPDATE_RELATION_DESCRIPTION: 'Please update how do you know the candidate field',
  UPDATE_RELATION_TENURE: 'Please update how long have you known the candidate field',
  UPDATE_RESUME: 'Please upload a resume',
  INVALID_EMAIL: 'Please enter valid email address',
  INVALID_PHONE_NUMBER: 'Please enter valid phone number',
  FIRST_NAME_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for first name field. Please try shortening the text',
  LAST_NAME_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for last name field. Please try shortening the text',
  EMPLOYER_NAME_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for employer name field. Please try shortening the text',
  CANDIDATE_LOCATION_CHAR_LIMIT_EXCEEDED:
    'Character limit exceeded for candidate location field. Please try shortening the text',
  FILE_SHOULD_LESS_3MB: 'File size should be less than 3 Mb',
  NO_COMMENTS_TO_SHOW: 'No comments to show',
  NO_CHATS_YET: 'No chats yet!!',
  NO_PODCAST_YET: 'No podcast yet',
  NOT_PART_GROUP: 'You are no longer a member of this group',
  NO_WALLPAPERS: 'No wallpapers to show',
  EMPTY_CONCERN: 'Please enter your concern',
  EMPTY_QUESTION: 'Please enter a question',
  EMPTY_ANSWER: 'Please enter your answer',
  UPDATE_LAST_NAME: 'Please update last name',
  SELECT_DOB: 'Please select date of birth',
  SELECT_GENDER: 'Please select gender',
  SELECT_NATIONALITY: 'Please select nationality',
  UPDATE_CONTACT_NUMBER: 'Please update contact number',
  INVALID_CONTACT_NUMBER: 'Please enter valid contact number',
  UPDATE_NATIONALITY: 'Please update nationality',
  UPDATE_GENDER: 'Please update gender',
  UPDATE_DOB: 'Please update date of birth',
  NO_REQUESTS_TO_SHOW: 'No requests to show',
  NO_UPCOMING_REQUESTS_TO_SHOW: 'No upcoming travel to show',
  NO_APPROVALS_TO_SHOW: 'No approvals to show',
  EMPTY_REASON: 'Please enter your reason',
  PROVIDE_JUSTIFICATION: 'Please provide a justification',
  IJPR_NO_JOBS_SAVED: 'You have not saved any job yet',
  INVALID_SUBJECT: 'Please enter a valid subject',
  DEI_EMPTY: 'DEI champions not yet identified.',
  ESG_EMPTY: 'ESG champions not yet identified.',
  NO_UPCOMING_SESSIONS: 'No upcoming sessions available',
  NO_PAST_SESSIONS: 'No sessions found',
  NO_ATTENDEES: 'No Attendees found',
  NO_MEMBERS_AVAILABLE: 'No Members Found',
  NEWS_LETTER_NOT_FOUND: 'News letter not found',
  NO_HOLIDAYS_FOUND: 'No holidays found',
  NO_SOM_TABS: 'Stay tuned for latest updates',
  NO_ONGOING_AUCTION: 'There are no ongoing auctions.',
  NO_ADDRESSES_FOUND: 'Please add your address to ensure smooth delivery.',
  PRODUCT_DETAILS_NOT_FOUND: 'Product details not found',
  ESTORE_NO_ORDERS_FOUND: 'You did not place any orders',
  NO_NEO_SESSION_FOUND: 'No neo sessions found',
  NO_DOCUMENTS_FOUND: 'No documents found',
  NO_BOOKINGS: 'No bookings found',
};

export const MESSAGES = {
  MAINTENANCE: 'The app is currently undergoing maintenance. Please check back later.',
  LOCKED:
    'You have exceeded 5 attempts to enter your PIN. Please try again after 24 hours or contact the Administrator',
  NO_FILTERS: 'No filters applied',
  DISABLED:
    'Your account has been disabled. Please contact Microland administrator on happytohelp@microland.com.',
  POSH_ALERT: 'Are you sure you want to raise a sexual harassment concern with the POSH Committee?',
  ORDER_ID_COPIED: 'Order Id Copied',
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  GC_ID_COPIED: 'GC Id Copied',
  GC_CODE_COPIED: 'GC Code Copied',
  ALREADY_SUBMITTED: 'You have already submitted your post.',
  NOMINEE_NOT_EMPTY: 'Nominee name can not be empty',
  CEO_CHAT_ADMIN_REVIEW:
    'All questions asked will be reviewed by the admin before making it visible on the chat session.',
  CANNOT_COMMENT_LIKE:
    'You cannot like or comment on a post unless you are a member of the community',
  TITLE_EMPTY: 'Title should not be empty',
  DESCRIPTION_EMPTY: 'Description should not be empty',
  ENTER_URL: 'Please enter URL',
  INVALID_URL: 'Invalid URL',
  LINK_ADDED: 'Link already added',
  MAX_YOUTUBE_LINKS: 'You can add maximum 10 youtube links',
  MAX_LINKS: 'You can add maximum 10 links',
  CANNOT_TAG: (count) => `Maximum ${count} employees can be tagged to the post`,
  UNSAVED_CHANGES: 'You have unsaved changes. Are you sure to discard them and leave the screen?',
  NO_WISHES: 'No wishes to show',
  DELETE_POST: 'Are you sure you want to delete this post?',
  GROUP_DELETED_BY_ADMIN: 'Group has been deleted by admin',
  YOU_REMOVED_FROM_GROUP: 'You are removed from this group',
  YOU_EITHER_REMOVED: 'You are either removed from this group or group has been deleted by admin',
  EXITED_GROUP: 'You have exited the group successfully!!',
  CANNOT_EXIT_GROUP: 'You cannot exit unless you choose an admin for this group',
  GROUP_DELETED: 'Group has been deleted',
  ENTER_GROUP_NAME: 'Enter a group name',
  GROUP_CREATED: 'Group created successfully',
  GROUP_NAME: 'Group name should be more than 2 characters',
  ALREADY_MEMBER: 'Already a member',
  ALREADY_SELECTED: 'Already selected',
  GROUP_NAME_UPDATED: 'The group name has been updated',
  DOWNLOAD_SUCCESS: 'Downloaded Successfully!!',
  ENTER_MESSAGE: 'Enter a message',
  POSH_ALERT_MESSAGE: 'Please enter your concern in the box below',
  POSH_RAISE_CONCERN_MESSAGE:
    'Enter your concern in the below box and tap on the submit button to send it to POSH Committee.',
  POSH_RAISE_CONCERN_CONFIRMATION_MESSAGE:
    'You are about to submit a Sexual Harassment complaint. Are you sure you would like to proceed?',
  ESTORE_DELIVERY_NOTE:
    'Product won’t be delivered to Bengaluru based addresses. All employees must collect the ordered products in-person from 1B, Ecospace office.',
  NO_SEATS_FOUND: 'Sorry! No seats found',
  NO_PARKING_SPACES_FOUND: 'Sorry! No parking spaces found',
};

export const WISH_TYPES = {
  BIRTHDAY: 'birthday',
  ANNIVERSARY: 'anniversary',
  ADVANCEMENT: 'advancement',
  NEW_JOINEE: 'newjoinee',
};

export const WISHES_TITLES_SUBTITLES = {
  SELF_TITLE_BIRTHDAY: 'Happy Birthday!',
  SELF_TITLE_ADVANCEMENT: 'Congratulations on your Career Advancement!',
  SELF_TITLE_NEW_JOINEE: 'Welcome aboard!',

  SELF_SUB_TITLE_BIRTHDAY: 'See the wishes',
  SELF_SUB_TITLE_ANNIVERSARY: 'See the wishes',
  SELF_SUB_TITLE_ADVANCEMENT: 'See the wishes',
  SELF_SUB_TITLE_NEW_JOINEE: 'See the wishes',

  OTHER_TITLE_BIRTHDAY: 'Celebrating a birthday!',
  OTHER_TITLE_ANNIVERSARY: 'Celebrating a service anniversary!',
  OTHER_TITLE_ADVANCEMENT: 'Celebrating a career advancement!',
  OTHER_TITLE_NEW_JOINEE: 'New Joinee',
};

export const CELEBRATION_IDENTIFIERS = {
  ADVANCEMENTS: 'advancements',
  BIRTHDAYS: 'birthdays',
  NEW_JOINEE: 'joinees',
  ANNIVERSARIES: 'anniversaries',
};

export const BUTTONTEXT = {
  NEWS_CLEAR_FILTER: 'CLEAR FILTERS',
  NEWS_CANCEL: 'CANCEL',
  NEWS_DONE: 'DONE',
  VIEW_GALLERY: 'View Gallery',
  REGISTER: 'Register',
  ALREADY_REGISTER: 'You are already registered',
  OK: 'OK',
  SHARE_BUSINESS_CARD: 'Share business card',
  SHARE_FACILITY: 'Share facility details',
  COMMUNITY_ADMIN_APPROVAL: 'Waiting for admin approval',
};
export const NO_DATA_TEXT = {
  DEFAULT: 'No data',
  NO_DATA_FOUND: 'No data found',
  NO_COMMENTS_FOUND: 'No comments found',
  NO_REPLY_FOUND: 'No replies found',
  NO_POLICIES_FOUND: 'No Policies Found',
  NO_SUB_CATEGORIES_FOUND: 'No Sub Categories Found',
  NO_UPCOMING_CONTESTS: 'No upcoming contests',
  NO_UPCOMING_EVENTS: 'No upcoming events',
  NO_PAST_CONTESTS: 'No past contests',
  NO_PAST_EVENTS: 'No past events',
};

export const firebaseOptions = {
  databaseURL: 'https://staging-corpone.firebaseio.com/',
  projectId: 'staging-corpone',
  apiKey: 'AIzaSyDezhssG5wkkOWTvp8yWK1L8RpFIzYmVoo',
  appId: '1:240921489470:android:12afd81c6983de7176e16f',
  messagingSenderId: '240921489470',
  storageBucket: 'staging-corpone.storage.firebase.com',
};

export const CUSTOMERS = {
  FACILITY_ID: 'facility_id',
  DEAL_YEAR: 'years',
  REGION_ID: 'region_id',
};

export const HOLIDAY = {
  HOLIDAY_ID: 'key',
};

export const API_PARAMS = {
  EMPLOYEES: {
    GEOGRAPHY_ID: 'geography_id',
    LOCATION_ID: 'location_id',
    FUNCTION_ID: 'function_id',
    LEVEL_ID: 'level_id',
    LAYER_ID: 'layer_id',
    RANGE: 'range',
    BLOOD_GROUP: 'blood_group',
  },
  ASK_ORG: {
    REQUEST_STATUS: 'request_status',
    REQUEST_APPROVAL: 'request_approval',
  },
};

export const ASK_ORG_REQUEST_STATUS_IDENTIFIERS = {
  ALL: 'all',
  ACTIVE: 'active',
  ONHOLD: 'onhold',
  RESOLVED: 'resolved',
};

export const ASK_ORG_REQUEST_APPROVAL_IDENTIFIERS = {
  ALL: 'all',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  REQUESTED: 'requested',
};

export const FILTER_TITLES = {
  GEOGRAPHY: 'Geography',
  LOCATION: 'Location',
  FUNCTION: 'Function',
  LEVEL: 'Band',
  LAYER: 'Sub-band',
  SERVICE_TENURE: 'Service Tenure',
  BLOOD_GROUP: 'Blood Group',
  FACILITIES: 'FACILITIES',
  DEAL_YEAR: 'CUSTOMER SINCE',
  REGIONS: 'GEOGRAPHY',
  COUNTRY: 'COUNTRY',
  HOLIDAY_YEAR: 'YEAR',
};

export const FILTER_IDENTIFIERS = {
  GEOGRAPHIES: 'geographies',
  LOCATIONS: 'locations',
  FUNCTIONS: 'functions',
  LEVELS: 'levels',
  LAYERS: 'layers',
  SERVICE_TENURES: 'serviceTenures',
  BLOOD_GROUPS: 'bloodGroup',
  REQUEST_STATUSES: 'requestStatuses',
  REQUEST_APPROVALS: 'requestApprovals',
  FACILITIES: 'facilities',
  DEAL_YEAR: 'dealYear',
  REGIONS: 'regions',
  HOLIDAY_YEAR: 'holidayYear',
};

export const NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS = {
  OVERVIEWS: 'overviews',
  CHECKLISTS: 'checklists',
  CALENDARS: 'calendars',
  QUICKLINK: 'quicklink',
  MY_INDUCTION: 'induction',
  DAY_ONE_FEEDBACK: 'feedback',
  ALL_INDUCTION: 'allinduction',
  NEO: 'neo',
};

export const NEW_JOINEES_HIGHER_LEVELS_IDENTIFIERS = {
  SESSION: 'session',
  INFO: 'info',
  RATE: 'rate',
  QUESTIONS: 'questions',
};

export const SESSION_DETAIL_IDENTIFIERS = {
  DETAIL: 'detail',
  MEDIA: 'media',
  QUESTIONS: 'questions',
};

export const IJPR_IDENTIFIERS = {
  IJP: 'ijp',
  REFERRALS: 'referrals',
  ALL_IJP: 'all_ijp',
  MY_APPLICATIONS: 'my_applications',
  MY_REFERRALS: 'my_referrals',
  APPLY: 'apply',
  REFER: 'refer',
};

export const MY_PROFILE_IDENTIFIERS = {
  PROFILE: 'profile',
  ASSETS: 'assets',
  INSURANCE: 'insurance',
  IDCARD: 'idcard',
  APP_SETTINGS: 'app_settings',
  WISHES: 'wishes',
  BUSINESS_CARD: 'businesscard',
};

export const MY_EDITABLE_PROFILE_IDENTIFIERS = {
  DETAILS: 'details',
  SOCIAL_ACCOUNTS: 'social_accounts',
};

export const ASK_ORG_IDENTIFIERS = {
  MY_REQUESTS: 'my_requests',
  HOME: 'home',
  MY_APPROVALS: 'my_approvals',
};

export const VOICE_DIALOG = {
  TITLE: 'Welcome to Voice Navigation',
};

export const storybookEnabled = false;

export const firebaseDataPayload = {
  NOTIFICATION_TYPE: 'action',
  NOTIFICATION_REFERENCE: 'reference',
  NOTIFICATION: 'notifications',
  CLASSIFIEDS: 'classifieds',
  CELEBRATIONS: 'celebrations',
  COMMUNITIES: 'communities',
  COMMUNITIES_POST: 'communitiesPost',
  CONTEST: 'contests',
  ARTICLES: 'articles',
  EMPLOYEES: 'employees',
  EVENT: 'events',
  DOWNLOADABLES: 'downloadables',
  FACILITIES: 'facilities',
  WALLPOSTS: 'wallposts',
  WALLPOST: 'wallpost',
  GRAFFITI: 'graffiti',
  IJPS: 'ijps',
  CAUSES: 'causes',
  MICROTIPS: 'microtips',
  NEWS: 'news',
  POLICIES: 'policies',
  POLLS: 'polls',
  SURVEYS: 'surveys',
  ANNOUCNEMENTS_LIST: 'announcements',
  GCDO_ANNOUCNEMENTS: 'gcdo_announcements',
  MICROBIZ: 'microbiz',
  ERP_MICROBIZ: 'erpmicrobiz',
  BTO: 'bto',
  BTO_ACTION: 'bto_action',
  BROADCAST: 'broadcast',
  MLTV: 'mltv',
  MLTV_ACTION: 'mltv_action',
  GCDO: 'gcdo',
  MICROKNOW: 'microknow',
  MICRO_LAW: 'legal',
  MICRO_LAW_ACTION: 'legal_action',
  GALLERY: 'galleries',
  QUESTION: 'question',
  QUESTION_COMMENT: 'question_comment',
  BREAKOUTS: 'breakouts',
  ACCOLADES: 'accolades',
  TESTIMONIALS: 'testimonials',
  WINS: 'wins',
  VISITS: 'visits',
  KNOW: 'know',
  CUSTOMER_ACCOLADES: 'customers_accolades',
  CUSTOMER_WINS: 'customers_wins',
  CUSTOMER_KNOW: 'customers_wins',
  CUSTOMER_TESTIMONIALS: 'customers_wins',
  CUSTOMER_VISITS: 'customers_wins',
  GALLERY_CLIENT_VISITS: 'galleries_clientvisits',
  GALLERY_EVENTS: 'galleries_events',
  GALLERY_STARS: 'galleries_stars',
  GALLERY_FACILITIES: 'galleries_facilities',
  GALLERY_MICROGIVE: 'galleries_microgive',
  GALLERY_COMMUNITIES: 'galleries_communities',
  GALLERY_CONTESTS: 'galleries_contests',
  GALLERY_LEARNING: 'galleries_learning',
  CUSTOMERS: 'customers',
  PASSWORD_RESET_SUCCESS: 'password_reset_success',
  PASSWORD_RESET_FAILED: 'password_reset_failed',
  PASSWORD_REMAINDER: 'password_reminder',
  PASSWORD_EXPIRED: 'password_expired',
  PASSWORD_RESET: 'password_reset',
  ASKML: 'askml',
  REQUEST: 'request',

  TOPIC_CELEBRATIONS: 'celebrations',
  TOPIC_CHAT: 'chat',
  TOPIC_CLASSIFIEDS: 'classifieds',
  TOPIC_COMMUNITIES: 'community',
  TOPIC_COMMUNITIES_POST: 'community_post',
  TOPIC_IJPS: 'ijps',
  TOPIC_MICROTIPS: 'microtips',
  TOPIC_NEWS: 'news',
  TOPIC_ANNOUNCEMENTS: 'article',
  EVENTS: 'event',
  LEARNING: 'learning',
  LEARNINGS: 'learnings',
  CONTESTS: 'contest',
  IJP: 'ijp',
  CAUSE: 'cause',
  BIRTHDAY: 'birthday_wishes',
  ANNIVERSARY: 'anniversary_wishes',
  ADVANCEMENT: 'advancement_wishes',
  PROFILEPIC: 'profile_picture',
  FEEDBACK: 'feedback',
  CHAT: 'chat',
  TIP: 'microtip',
  GRAFITTI: 'graffiti',
  GRAFITTIS: 'graffitis',
  SURVEY: 'survey',
  POLL: 'poll',
  POLICY: 'policy',
  FACILITY: 'facility',
  CMDCHAT: 'cmdchat',
  STARS: 'stars',
  COMMENTS: 'comments',
  GALLERY_TAG: 'gallery_tag',
  BREAKOUT_SESSION: 'breakout_session',
  BREAKOUT_UPDATE: 'breakout_update',
  BREAKOUT_DISCUSSION: 'breakout_discussion',
  MI_HR: 'mihr_action',
  ML30: 'ml30',
  DNI: 'dni',
  DNI_ANNOUCNEMENTS: 'dni_announcements',
  DNI_PLEDGE: 'dni_pledge',
  COVID_SUPPORT: 'covidsupport',
  COVID_ACTION: 'covidaction',
  TREQUEST: 'trequest',
  TRAVEL: 'travel',
  PODCASTS: 'podcasts',
  PODCAST: 'podcast',
  DASHBOARD: 'dashboard',
  MONDAY_ANNOUNCEMENTS: 'monday_announcements',
  ML_MONDAY: 'monday',
  ML_DAY_33: 'ml33',
  ML_DAY_34: 'ml34',
  ML_DAY_35: 'ml35',
  ML_DAY_33_ACTION: 'ml33_action',
  ML_DAY_34_ACTION: 'ml34_action',
  ML_DAY_35_ACTION: 'ml35_action',
  NEW_JOINEES: 'newjoinees',
  WOMENS_DAY: 'iwd_action',
  ESG: 'esg_action',
  MLF: 'mlf_action',
  BREAKOUT_ANSWERS: 'breakouts_answers',
  EMERGENCY_PLAYBOOKS: 'emergency_playbooks',
  EVENT_FEEDBACK: 'event_feedback',
  CONTEST_FEEDBACK: 'contest_feedback',
  WOMENS_DAY_REF: 'womensdays',
  MLF_REF: 'mlf',
  ESG_REF: 'esg',
  MICROKNOW_REF: 'mk_announcements',
  MI_HR_REF: 'mihr',
  MI_AUCTION: 'miauction',
  MI_AUCTION_ACTION: 'miauction_action',
  MICROGIVE_FEEDBACK: 'microgive_feedback',
  CHEER_CENTRAL: 'cheer_central',
  CHEERS_CENTRAL: 'cheers_central',
  CHEERS_NOMINATOR: 'cheers_nominator',
  CHEER_NOMINATOR: 'cheer_nominator',
  CHEERS_RECIPIENT: 'cheers_recipient',
  CHEER_RECIPIENT: 'cheer_recipient',
  CUSTOMERS_RESEARCHERS: 'customers_researches',
  RESEARCHERS: 'researches',
  ESTORE_HOME: 'estore_home',
  ESTORE: 'estore',
};

export const REQUEST_TYPE = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const ACTION_TYPES = {
  GET_ACCESSIBILITY: 'GET_ACCESSIBILITY',
  GET_ORG: 'GET_ORG',
  GET_DASHBOARD: 'GET_DASHBOARD',
  NOTIFICATION_COUNT: 'NOTIFICATION_COUNT',
  GET_MENU: 'GET_MENU',
  GET_EMPLOYEE: 'GET_EMPLOYEE',
  GET_CHEERS_POINTS: 'GET_CHEERS_POINTS',
  GET_TOKEN: 'GET_TOKEN',
  GET_TOKEN_BY_EMAIL: 'GET_TOKEN_BY_EMAIL',
  GET_TRAVEL_REQUEST_MASTER_DATA: 'GET_TRAVEL_REQUEST_MASTER_DATA',
  GET_TRAVEL_REQUEST_DATA: 'GET_TRAVEL_REQUEST_DATA',
  GET_ALL_TRAVEL_REQUEST_DATA: 'GET_ALL_TRAVEL_REQUEST_DATA',
  GET_UPCOMING_TRAVEL_REQUEST_DATA: 'GET_UPCOMING_TRAVEL_REQUEST_DATA',
  GET_TRAVEL_REQUEST_DATA_FOR_APPROVAL: 'GET_TRAVEL_REQUEST_DATA_FOR_APPROVAL',
  GET_ACCESS: 'GET_ACCESS',
};

export const FEATURE_TYPE = {
  NEWS: 'NW',
  ARTICLES: 'A',
  EVENTS: 'EV',
  FACILITIES: 'FE',
  CEO_CHAT: 'CQ',
  WALL: 'W',
  STARS: 'SE',
  COMMUNITY: 'CM',
  CHEERS: 'CA',
  CHEERS_NOMINATORS: 'CTN',
  CHEERS_RECIPIENT: 'CTR',
  PODCAST_EPISODE: 'PEP',
  BREAKOUT_UPDATE: 'BU',
  WISHES: 'WS',
  MICROLAND_DAY_34: 'MLTFCM',
  MI_AUCTION: 'AI',
  POLL: 'PO',
  SURVEY: 'SU',
  ML35VID: 'ML35VID',
  ML35PIC: 'ML35PIC',
};

export const FOREIGN_APP_CONSTANTS = {
  GREYT_HR: '_GREYTHR',
  GREYT_HR_WEB: '_GREYTHR_WEB',
  TRINET: '_TRINET',
  MICROWORKS: '_MICROWORKS',
  CONCUR_SOLUTIONS: '_CONCUR_SOLUTIONS',
  UK_EXPENSE_LINK: '_UK_EXPENSE_LINK',
  LOANS: '_LOANS',
  MYINCENTIVES: '_MYINCENTIVES',
  MICROVAX: '_MICROVAX',
  MICROSELECT: '_MICROSELECT',
  INSURANCE: '_INSURANCE',
  ML_35: '_ML35',
};

export const GEOGRAPHY_ABBREVIATIONS = {
  IND: 'IND',
  USA: 'USA',
  SAU: 'SAU',
  UK: 'UK',
};

export const GEOGRAPHY_TITLE = {
  INDIA: 'india',
};

export const WARNING_MESSAGES = {
  FILE_NOT_SUPPORTED: 'File format is not supported',
  FILE_SIZE_EXCEEDED: (fileSize = 16) => `Please upload a file under ${fileSize} Mb in size.`,
  FEATURE_NOT_AVAILABLE: 'This feature is currently not enabled for your region.',
  EMPLOYEE_ALREADY_TAGGED: 'This Microlander has already been tagged in this photo',
  MAXIMUM_NO_OF_PDF_FILES: (limit = 10) => `Maximum ${limit} pdf files are allowed.`,
  MAXIMUM_NO_OF_YOUTUBE_URLS: (limit = 10) => `Maximum ${limit} Youtube urls are allowed.`,
  MAXIMUM_NO_OF_IMAGE_FILES: (limit = 10) => `Maximum ${limit} images are allowed.`,
  MAXIMUM_NO_OF_WEB_URLS: (limit = 10) => `Maximum ${limit} web urls are allowed.`,
  MAXIMUM_CHARS_LIMIT: (limit = 1000) => `Maximum ${limit} characters are allowed.`,
};

export const GENDER_LIST = ['Male', 'Female', 'Unknown'];
export const GENDER_ITEM_LIST = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Unknown', value: 'U' },
];
export const GENDER_MAPPER = { Male: 'M', Female: 'F', Unknown: 'U' };
export const BLOOD_GROUPS = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];

export const SUPPORTED_IMAGE_FORMATS = 'image/jpeg,image/bmp,image/gif,image/jpg,image/png';
export const SUPPORTED_DOCUMENT_FORMATS_V1 =
  'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const SUPPORTED_DOCUMENT_FORMAT_PDF = 'application/pdf';

export const SUPPORTED_IMAGE_FORMATS_WEB = [
  'image/jpeg',
  'image/bmp',
  'image/gif',
  'image/jpg',
  'image/png',
];

export const SUPPORTED_PDF_FORMATS_WEB = ['application/pdf'];

export const ORG_NAMES = {
  MICROLAND: 'microland',
};

export const NOMINATIONS_LIST = [
  {
    nominations: 'Self',
    desc: 'Use this to propose yourself for a reward',
  },
  {
    nominations: 'My Manager',
    desc: 'Use this to propose your manager for a reward',
  },
  {
    nominations: 'Peer',
    desc: 'Use this to propose any Microlander for a reward',
  },
  {
    nominations: 'My Team',
    desc: 'Use this to reward your team',
  },
];

export const MICROGIVE_EVENT_TYPE = {
  VOLUNTEER: 'V',
  DONATION: 'D',
};

export const ANALYTICS_SCREEN_NAMES = {
  EMAIL_VALIDATE_SCREEN: 'Email Verification',
  LOGIN_SCREEN: 'Employee Login',
  MOBILE_VALIDATE_SCREEN: 'Phone Verification',
  TERMS_CONDITIONS_SCREEN: 'Terms & Conditions',
  ASK_ORG: 'Ask Microland',
  ASK_ORG_DETAIL: 'Ask Microland Request Details',
  ASK_ORG_SEARCH_RESULT: 'Ask Microland Search Results',
  ASK_ORG_SEARCH_RESULT_DETAIL: 'Ask Microland Request Details',
  ASK_ORG_SELECT_SERVICE: 'Ask Microland Select Service Line',
  ASK_ORG_SELECT_TICKET_TYPE: 'Ask Microland Select Ticket Type',
  ASK_ORG_SELECT_CATEGORY: 'Ask Microland Select Category',
  ASK_ORG_SELECT_SUB_CATEGORY: 'Ask Microland Select Sub-Category',
  ASK_ORG_SELECT_CLASSIFICATION: 'Ask Microland Select Classification',
  ASK_ORG_SELECT_NEW_SERVICE_REQUEST: 'Ask Microland New Service Request',
  ASK_ORG_REQUEST_CREATED: 'Ask Microland New Service Request Created',
  ASK_ORG_ESCALATE: 'Ask Microland Urgent Escalate',
  ASK_ORG_FILTER: 'Ask Microland Filters',
  ANNIVERSARY_SCREEN: 'Anniversary',
  ANNIVERSARY_DAY_CONTENT: 'ANNIVERSARY_DAY_CONTENT',
  ANNIVERSARY_DAY_GALLERY_FULL_IMAGE: 'Anniversary Day Gallery Full Image',
  ANNIVERSARY_GALLERY_SCREEN: 'Anniversary Gallery',
  ADD_CONTESTS_SCREEN: 'Add Contests',
  ADD_CONTESTS_THANKS_SCREEN: 'Add Contests Thanks',
  ALL_EMPLOYEE_ANNIVERSARY_SCREEN: 'Employee Selection',
  CONTESTS_DETAIL_SCREEN: 'Contests Detail',
  DIGITAL_WARRIOR_SCREEN: 'Digital Warrior',
  MOMENT_TAG_DETAIL_SCREEN: 'Moment Tag Detail',
  PLEDGE_SCREEN: 'Pledge',
  PLEDGE_THANKS_SCREEN: 'Pledge Thanks',
  TAKE_THE_PLEDGE_SCREEN: 'Take The Pledge',
  WALLPAPER_CONTENT_SCREEN: 'WallPaper Content',
  WALL_PAPERS_SCREEN: 'WallPapers',
  WATCH_LIVE_SCREEN: 'Watch Live',
  CONTEST_DETAIL: 'ContestContent',
  CONTESTS: 'Contests',
  BUSINESS_DASHBOARD: 'Business Dashboard',
  SALES_INCENTIVE: 'Sales Incentive',
  CELEBRATIONS: 'Celebrations',
  WISHES_CELEBRATIONS: 'Wishes Celebration',
  DIRECTORY: 'Directory',
  POLICIES: 'Policies',
  POLICY_DETAIL_SCREEN: 'Policy Details',
  POLICY_SEARCH_SCREEN: 'Policy Search',
  BREAKOUTS: 'Breakouts',
  BREAKOUT_GALLERY_SCREEN: 'Breakouts Gallery',
  BREAKOUTS_INFO_DETAIL_SCREEN: 'Breakouts Info Detail',
  BREAKOUTS_TAB_SCREEN: 'Breakouts Tabs',
  MI_HR: 'Mi HR Support',
  MI_HR_TAB_SCREEN: 'Mi HR Support Tabs',
  MI_HR_DETAILS_SCREEN: 'Mi HR Support Details Screen',
  MICRO_LAW_DETAIL_SCREEN: 'Micro Law Detail Screen',
  GALLERY_FULL_IMAGE_SCREEN: 'Breakouts Gallery Full Image',
  CHEERS_SCREEN: 'Cheers',
  ALL_EMPLOYEE_SCREEN: 'Cheers Employee Selection',
  ALLOCATE_POINT_PEER_SCREEN: 'Allocate Points Individually',
  DONATE_POINTS_SCREEN: 'Donate Points',
  EMPLOYEE_SELECTION_MY_TEAM_SCREEN: 'Employee Selection My Team',
  EMPLOYEE_SELECTION_SCREEN: 'Employee Selection Peer',
  MY_MANAGER_SCREEN: 'My Manager',
  MY_POINTS_SCREEN: 'My Points',
  MY_TEAM_SCREEN: 'My Team',
  NOMINATIONS_SCREEN: 'Nominations',
  PEER_SCREEN: 'Peer',
  POINTS_REDEEMED_SCREEN: 'Points Redeemed',
  REDEEM_POINTS_SCREEN: 'Redeem Points',
  REWARD_DETAILS_SCREEN: 'Reward Detail',
  SELF_SCREEN: 'Self',
  TAGGED_PEOPLE_SCREEN: 'Tagged People',
  VIEW_ELIGIBLE_AWARD_SCREEN: 'View Self Award',
  VIEW_MY_TEAM_AWARD_SCREEN: 'View My Team Award',
  VIEW_NOMINATIONS_SCREEN: 'View Nominations',
  VIEW_PEER_AWARD_SCREEN: 'View Peer Award',
  MICRO_GIVE: 'Microgive',
  MY_DONATIONS_SCREEN: 'My Donations',
  VOLUNTEERING_LOG_SCREEN: 'Volunteering log',
  QUERY_SCREEN: 'Microgive Query',
  PROJECT_DETAIL_SCREEN: 'Projects Detail',
  DONATION_TABS_SCREEN: 'Donation Tabs',
  DONATION_SEARCH_SCREEN: 'Donation Search',
  DONATION_EVENT_BY_DATE_SCREEN: 'Donation Search By Date',
  PROJECTS_SEARCH_SCREEN: 'Projects Search',
  PROJECT_DETAIL_SEARCH_SCREEN: 'Projects Detail Search',
  PROJECT_DETAIL_BY_DATE_SCREEN: 'Projects Detail Search By Date',
  SELECT_DONATION_SCREEN: 'Select Donation',
  VOLUNTEER_TABS_SCREEN: 'Volunteer Tabs',
  SELECT_DATE_SCREEN: 'Select Date',
  PROJECT_TABS_SCREEN: 'Project Detail Tabs',
  UPDATES_SCREEN: 'Updates',
  VOLUNTEER_PARTICIPATES_SCREEN: 'Volunteer Participants',
  MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN: 'Microgive Gallery Tags Detail',
  MICRO_UNIV: 'Microuniv',
  MICROVAX: 'MicroVax',
  SPLASH_SCREEN: 'Splash',
  ON_BOARD_SCREEN: 'Walkthrough',
  OTP_VERIFICATION_SCREEN: 'Otp Verification',
  PASSCODE_MAIN_SCREEN: 'Passcode Main',
  PASSCODE_SCREEN: 'Passcode',
  PASSWORD_RESET: 'Password Reset',
  RESET_PASSCODE_SCREEN: 'Reset Passcode',
  IJPR: 'Ijpr',
  IJPR_SEARCH_RESULT: 'IJPR_SEARCH_RESULT',
  IJP_REFER_SEARCH_RESULT: 'IJP_REFER_SEARCH_RESULT',
  IJPR_DETAIL: 'IJPR_DETAIL',
  IJP_REFER_DETAIL: 'IJP_REFER_DETAIL',
  IJP_DETAIL: 'Ijp Detail',
  IJP_REFER: 'Ijp Refer',
  IJP_APPLY: 'Ijp Apply',
  IJPR_APPLIED: 'Ijpr Applied',
  MY_ASSET_DETAIL: 'My Asset Detail',
  MY_PROFILE_DETAIL_EDIT: 'My Profile Edit',
  MY_PROFILE_DETAIL: 'My Profile Detail',
  NEW_JOINEES_HIGHER_LEVELS: 'New Joinees Higher Levels',
  NEW_JOINEES_LOWER_LEVELS: 'New Joinees Lower Levels',
  HOME: 'Home',
  MENU: 'MENU',
  MENU_PERSONALIZE: 'Menu Optional Features',
  ASK_SESSION_QUESTION: 'Ask Agenda Question',
  SESSION_DETAILS: 'Agenda Details',
  MAIN_FILTER: 'Main Filter',
  BROADCASTS: 'BROADCASTS',
  MICROBIZ: 'MICROBIZ',
  BTO: 'BTO',
  GCDO: 'GCDO',
  ESG: 'ESG',
  ESG_SEARCH: 'ESG_SEARCH',
  MLF: 'MLF',
  MLF_SEARCH: 'MLF_SEARCH',
  BROADCAST_DETAILS: 'Broadcast Details',
  BROADCAST_SEARCH: 'Broadcast Search',
  MLTV_SEARCH: 'MLTV Search',
  MICROKNOW_DETAILS: 'Microknow Details',
  GCDO_DETAILS: 'GCDO Details',
  MICROBIZ_DETAIL: 'MicroBiz Details',
  BTO_DETAIL: 'BTO Details',
  CEO_ASK_QUESTION: 'CEO_ASK_QUESTION',
  CEO_ARCHIVE_LISTS: 'CEO_ARCHIVE_LISTS',
  CEO_ARCHIVE_CHATS: 'CEO_ARCHIVE_CHATS',
  CEO_CHAT_CONTENT: 'CEO_CHAT_CONTENT',
  CEO_CHAT: 'CEO_CHAT',
  CEO_CHAT_COMMENT: 'CEO_CHAT_COMMENT',
  CEO_TAG_EMPLOYEE: 'CEO_TAG_EMPLOYEE',
  COMMUNITIES: 'Communities',
  COMMUNITIES_DETAIL: 'Communities Detail',
  COMMUNITY_POST_TAGGED: 'Community Post Tagged',
  POST_DETAIL: 'Post Detail',
  VIEW_COMMUNITY_POSTS: 'View Community Posts',
  COMMUNITY_ITEREST_POSTS: 'Community Interest Posts',
  VIEW_MEMBERS: 'View Members',
  WRITE_POST: 'Write Community Post',
  EDIT_POST: 'Edit Community Post',
  DEAR_EMPLOYER: 'DEAR_EMPLOYER',
  MICROKNOW: 'MICROKNOW',
  MICRO_LAW: 'MICRO_LAW',
  MICROKNOW_SEARCH: 'MICROKNOW_SEARCH',
  MICRO_LAW_SEARCH: 'MICRO_LAW_SEARCH',
  DEAR_EMPLOYER_CONTENT: 'DEAR_EMPLOYER_CONTENT',
  ECARDS: 'ECARDS',
  ECARDS_CONTENT: 'ECARDS_CONTENT',
  EVENTS_CONTENT: 'EVENTS_CONTENT',
  EVENTS_DATE: 'EVENTS_DATE',
  EVENTS: 'EVENTS',
  EVENTS_GALLERY: 'EVENTS_GALLERY',
  FACILITY: 'FACILITY',
  FACILITY_CONTENT: 'FACILITY_CONTENT',
  GALLERY: 'GALLERY',
  GALLERY_SEARCH: 'GALLERY_SEARCH',
  NEWS_CONTENT: 'NEWS_CONTENT',
  NEWS: 'NEWS',
  NEWS_SEARCH: 'NEWS_SEARCH',
  OUR_CUSTOMER_CONTENT: 'OUR_CUSTOMER_CONTENT',
  OUR_CUSTOMER: 'OUR_CUSTOMER',
  POLLS_AND_SURVEYS: 'POLLS_AND_SURVEYS',
  POLLS_RESULTS: 'POLLS_RESULTS',
  SURVEY_PARTICIPATE: 'SURVEY_PARTICIPATE',
  SURVEY_SUCCESS: 'SURVEY_SUCCESS',
  POSH: 'POSH',
  SOM_FOREWORD: 'SOM_FOREWORD',
  SOM_GALLERY: 'SOM_GALLERY',
  SOM_MAIN: 'SOM_MAIN',
  SOM_TAB: 'SOM_TAB',
  SOM_EMPLOYEE_DETAILS: 'SOM_EMPLOYEE_DETAILS',
  TAG_EMPLOYEE: 'TAG_EMPLOYEE',
  WALL_ADD_VIDEO: 'WALL_ADD_VIDEO',
  THE_WALL: 'THE_WALL',
  THE_WALL_POST_DETAIL: 'POSTS',
  THE_WALL_NEW_POST: 'NEW POST',
  THE_WALL_TAGGED_PEOPLE: 'THE_WALL_TAGGED_PEOPLE',
  WOMENS_DAY: 'WOMENS_DAY',
  WOMENS_DAY_LISTING: 'WOMENS_DAY_LISTING',
  WOMENS_DAY_GALLERY_FULL_IMAGE: 'WOMENS_DAY_GALLERY_FULL_IMAGE',
  WOMENS_DAY_WATCH_VIDEO: 'WOMENS_DAY_WATCH_VIDEO',
  WOMENS_DAY_GALLERY: 'WOMENS_DAY_GALLERY',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
  PANDEMIC_SUPPORT: 'COVID_SUPPORT',
  DI: 'INCLUSION & DIVERSITY',
  DI_PLEDGE_THANKS_SCREEN: 'DI Pledge Thanks',
  ANNOUNCEMENT_DETAIL: 'DI Announcements Details',
  DI_SEARCH: 'DI Search',
  DI_EVENT_DETAIL: 'DI Event Detail',
  DI_EVENT_DATE: 'DI Event Date',
  PODCASTS: 'Podcast',
  PODCASTS_CONTENT: 'Podcast Detail',
  PODCASTS_EPISODE_DETAIL: 'Podcast Episode Detail',
  CHAT: 'Chat Message',
  TRAVEL_REQUEST: 'Travel Request',
  TRAVEL_REQUEST_REVIEW: 'Travel Request Review',
  TRAVEL_REQUEST_JUSTIFICATION: 'Travel Request Justification',
  TRAVEL_REQUEST_ADD_USER: 'Travel Request Add User',
  WALLPAPERS: 'Wallpaper',
  IJPR_SAVED_JOBS: 'IJPR Saved Jobs',
  POSH_RAISE_CONCERN: 'POSH Raise Concern',
  ML_MONDAY: 'Microland Monday',
  ML_MONDAY_CONTENT: 'Microland Monday Content',
  ALL_WISHES: 'All Wishes',
  PROFILE_CHEERS_LISTING_SCREEN: 'PROFILE_CHEERS_LISTING_SCREEN',
  MICROLAND_VALUES: 'MICROLAND_VALUES',
  VARIABLE_PAY: 'VariablePay',
  EMERGENCY_PLAYBOOK: 'EMERGENCY_PLAYBOOK',
  EMERGENCY_PLAYBOOK_CONTENT: 'EMERGENCY_PLAYBOOK_CONTENT',
  HOLIDAY_CALENDAR: 'Holiday Calendar Screen',
  NOTIFICATION_SEARCH: 'Notification Search Screen',
  FEEDBACK_SCREEN: 'FEEDBACK_SCREEN',
  MI_AUCTION: 'MiAUCTION',
  MI_AUCTION_DETAILS: 'MiAUCTION_DETAILS',
  MI_AUCTION_TC: 'MiAUCTION_TEARMS_CONDITIONS',
  MI_CHILD_CARE: 'MI_CHILD_CARE',
  MI_CHILD_CARE_REQUEST: 'MI_CHILD_CARE_REQUEST',
  MI_CHILD_CARE_REQUESTS_TAB: 'MI_CHILD_CARE_REQUESTS_TAB',
  MI_CHILD_CARE_PLOICY_TAB: 'MI_CHILD_CARE_POLICY_TAB',
  MI_CHILD_CARE_COST_DETAILS_TAB: 'MI_CHILD_CARE_COST_DETAILS_TAB',
  MI_CHILD_CARE_ABOUT_TAB: 'MI_CHILD_CARE_ABOUT_TAB',
  ATTENDANCE: 'ATTENDANCE',

  MICRO_STORE_MAIN_SCREEN: 'MICRO_STORE_MAIN_SCREEN',
  MICRO_STORE_BAG_SCREEN: 'MICRO_STORE_BAG',
  MICRO_STORE_ADD_ADDRESS_SCREEN: 'MICRO_STORE_ADD_ADDRESS_SCREEN',
  MICRO_STORE_ADDRESS_LIST_SCREEN: 'MICRO_STORE_ADDRESS_LIST_SCREEN',
  MICRO_STORE_REVIEW_ORDER_SCREEN: 'MICRO_STORE_REVIEW_ORDER_SCREEN',
  MICRO_STORE_EDIT_ADDRESS_SCREEN: 'MICRO_STORE_EDIT_ADDRESS_SCREEN',
  MICRO_STORE_INVOICE_SCREEN: 'MICRO_STORE_INVOICE_SCREEN',
  MICRO_STORE_ORDER_DETAILS_SCREEN: 'MICRO_STORE_ORDER_DETAILS_SCREEN',
  MICRO_STORE_ORDER_LIST_SCREEN: 'MICRO_STORE_ORDER_LIST_SCREEN',
  MICRO_STORE_ORDER_SUCCESS_SCREEN: 'MICRO_STORE_ORDER_SUCCESS_SCREEN',
  MICRO_STORE_ORDER_TC_SCREEN: 'MICRO_STORE_ORDER_TC_SCREEN',
  MICRO_STORE_PRODUCT_DETAILS_SCREEN: 'MICRO_STORE_PRODUCT_DETAILS_SCREEN',
  MICRO_STORE_ALL_PRODUCTS_SCREEN: 'MICRO_STORE_ALL_PRODUCTS_SCREEN',
  MICRO_STORE_RETURN_SCREEN: 'MICRO_STORE_RETURN_SCREEN',
  MICRO_STORE_SELECT_ADDRESS_SCREEN: 'MICRO_STORE_SELECT_ADDRESS_SCREEN',
  MICRO_STORE_SHIPMENT_SCREEN: 'MICRO_STORE_SHIPMENT_SCREEN',
  MICRO_STORE_HOME_TAB: 'MICRO_STORE_HOME_TAB',
  MICRO_STORE_ACCOUNT_TAB: 'MICRO_STORE_ACCOUNT_TAB',
  ML35_PICTURES_SCREEN: 'ML35_PICTURES_SCREEN',
};

export const ANALYTICS_EVENT_TYPES = {
  POSH_COMPLAINT: 'POSH_COMPLAINT',
  SELECT_CONTENT: 'select_content',
  TERMS_AND_CONDITIONS_CLOSE: 'TERMS_AND_CONDITIONS_CLOSE',
  TERMS_AND_CONDITIONS_OPEN: 'TERMS_AND_CONDITIONS_OPEN',
  DISAGREE_TERMS_AND_CONDITIONS: 'DISAGREE_TERMS_AND_CONDITIONS',
  AGREE_TERMS_AND_CONDITIONS: 'AGREE_TERMS_AND_CONDITIONS',
  ASK_ORG_ANSWER_HELPFUL: 'ASK_MICROLAND_ANSWER_HELPFUL',
  ASK_ORG_ANSWER_NOT_HELPFUL: 'ASK_MICROLAND_ANSWER_NOT_HELPFUL',
  ASK_ORG_QUESTION_RESULT: 'ASK_MICROLAND_QUESTION_RESULT',
  ASK_ORG_FEEDBACK: 'ASK_MICROLAND_FEEDBACK',
  ASK_ORG_APPROVED: 'ASK_MICROLAND_APPROVED',
  ASK_ORG_REJECTED: 'ASK_MICROLAND_REJECTED',
  ASK_ORG_SEARCH_SOLUTION: 'ASK_MICROLAND_SEARCH_SOLUTION',
  ASK_ORG_RAISE_REQUEST: 'ASK_MICROLAND_RAISE_REQUEST',
  ASK_ORG_ESCALATE_VIEWED: 'ASK_MICROLAND_ESCALATE_VIEWED',
  ASK_ORG_TICKET_DETAILS_VIEWED: 'ASK_MICROLAND_TICKET_DETAILS_VIEWED',
  ASK_ORG_FILTER_APPLIED: 'ASK_MICROLAND_FILTER_APPLIED',
  ASK_ORG_MY_REQUESTS_TAB_VISIT: 'ASK_MICROLAND_MY_REQUESTS_TAB_VISIT',
  ASK_ORG_HOME_TAB_VISIT: 'ASK_MICROLAND_HOME_TAB_VISIT',
  ASK_ORG_MY_APPROVALS_TAB_VISIT: 'ASK_MICROLAND_MY_APPROVALS_TAB_VISIT',
  CONTESTS_BY_ID: 'CONTESTS_BY_ID',
  CONTESTS_REGISTER: 'CONTESTS_REGISTER',
  BUSINESS_DASHBOARD_OPEN_WEBVIEW: 'BUSINESS_DASHBOARD_OPEN_WEBVIEW',
  SALES_INCENTIVE_OPEN_WEBVIEW: 'SALES_INCENTIVE_OPEN_WEBVIEW',
  CELEBRATIONS_SHOW_CALENDAR: 'CELEBRATIONS_SHOW_CALENDAR',
  CELEBRATION_PROFILE_SELECTED: 'CELEBRATION_PROFILE_SELECTED',
  CELEBRATIONS_SHOW_CALENDER: 'CELEBRATIONS_SHOW_CALENDER',
  DIRECTORY_SEARCH_CLICKED: 'DIRECTORY_SEARCH_CLICKED',
  DIRECTORY_SEARCH_QUERY: 'DIRECTORY_SEARCH_QUERY',
  DIRECTORY_PROFILE_SELECTED: 'DIRECTORY_PROFILE_SELECTED',
  BREAKOUTS_AGENDA_TAB_VISIT: 'BREAKOUTS_AGENDA_TAB_VISIT',
  BREAKOUTS_ATTENDEES_TAB_VISIT: 'BREAKOUTS_ATTENDEES_TAB_VISIT',
  BREAKOUTS_SESSION_DISCUSSION_SCREEN_VISIT: 'BREAKOUTS_SESSION_DISCUSSION_SCREEN_VISIT',
  BREAKOUTS_DISCUSSIONS_TAB_VISIT: 'BREAKOUTS_DISCUSSIONS_TAB_VISIT',
  BREAKOUTS_GALLERY_TAB_VISIT: 'BREAKOUTS_GALLERY_TAB_VISIT',
  BREAKOUTS_INFO_TAB_VISIT: 'BREAKOUTS_INFO_TAB_VISIT',
  BREAKOUTS_UPDATES_TAB_VISIT: 'BREAKOUTS_UPDATES_TAB_VISIT',
  BREAKOUTS_UPDATES_LIKE: 'BREAKOUTS_UPDATES_LIKE',
  BREAKOUTS_UPDATES_COMMENT: 'BREAKOUTS_UPDATES_COMMENT',
  BREAKOUTS_AGENDA_DETAILS_TAB_VISIT: 'BREAKOUTS_AGENDA_DETAILS_TAB_VISIT',
  BREAKOUTS_AGENDA_MEDIA_TAB_VISIT: 'BREAKOUTS_AGENDA_MEDIA_TAB_VISIT',
  BREAKOUTS_AGENDA_QUESTIONS_TAB_VISIT: 'BREAKOUTS_AGENDA_QUESTIONS_TAB_VISIT',
  POLICIES_TAB_VISIT: 'POLICIES_TAB_VISIT',
  POLICIES_SEARCH: 'POLICIES_SEARCH',
  DI_SEARCH: 'DI_SEARCH',
  BROADCAST_SEARCH: 'BROADCAST_SEARCH',
  MICROKNOW_SEARCH: 'MICROKNOW_SEARCH',
  MICRO_LAW_SEARCH: ' MICRO_LAW_SEARCH',
  ANNIVERSARY_CHIEF_MESSAGE_VISIT: 'ANNIVERSARY_CHIEF_MESSAGE_VISIT',
  ANNIVERSARY_CENTRAL_TAB_VISIT: 'ANNIVERSARY_CENTRAL_TAB_VISIT',
  ANNIVERSARY_MOMENTS_VISIT: 'ANNIVERSARY_MOMENTS_VISIT',
  ANNIVERSARY_SUPER_VISIT: 'ANNIVERSARY_SUPER_VISIT',
  ANNIVERSARY_COMMENTS_VISIT: 'ANNIVERSARY_COMMENTS_VISIT',
  ANNIVERSARY_CONTEST_TAB_VISIT: 'ANNIVERSARY_CONTEST_TAB_VISIT',
  ANNIVERSARY_GALLERY_TAB_VISIT: 'ANNIVERSARY_GALLERY_TAB_VISIT',
  MOMENTS_LIKE: 'MOMENTS_LIKE',
  MOMENTS_COMMENT: 'MOMENTS_COMMENT',
  RESULTS_LIKE: 'RESULTS_LIKE',
  RESULTS_COMMENT: 'RESULTS_COMMENT',
  ANNIVERSARY_COMMENT: 'ANNIVERSARY_COMMENT',
  DIGITAL_WARRIOR_RULES_TAB_VISIT: 'DIGITAL_WARRIOR_RULES_TAB_VISIT',
  DIGITAL_WARRIOR_NOMINATIONS_TAB_VISIT: 'DIGITAL_WARRIOR_NOMINATIONS_TAB_VISIT',
  DIGITAL_WARRIOR_RESULTS_TAB_VISIT: 'DIGITAL_WARRIOR_RESULTS_TAB_VISIT',
  DIGITAL_WARRIOR_EBOOK_TAB_VISIT: 'DIGITAL_WARRIOR_EBOOK_TAB_VISIT',
  CONTESTS_DETAIL_TAB_VISIT: 'CONTESTS_DETAIL_TAB_VISIT',
  MAKE_DIGITAL_HAPPEN_TAB_VISIT: 'MAKE_DIGITAL_HAPPEN_TAB_VISIT',
  NOT_WITHOUT_YOU_TAB_VISIT: 'NOT_WITHOUT_YOU_TAB_VISIT',
  DONATION_TAB_VISIT: 'DONATION_TAB_VISIT',
  MICROGIVER_TAB_VISIT: 'MICROGIVER_TAB_VISIT',
  PROJECTS_TAB_VISIT: 'PROJECTS_TAB_VISIT',
  VOLUNTEER_TAB_VISIT: 'VOLUNTEER_TAB_VISIT',
  VOLUNTEER_DETAIL_TAB_VISIT: 'VOLUNTEER_DETAIL_TAB_VISIT',
  VOLUNTEER_DISCUSSION_TAB_VISIT: 'VOLUNTEER_DISCUSSION_TAB_VISIT',
  PROJECTS_DETAIL_TAB_VISIT: 'PROJECTS_DETAIL_TAB_VISIT',
  PROJECTS_GALLERY_TAB_VISIT: 'PROJECTS_GALLERY_TAB_VISIT',
  PROJECTS_PARTICIPANTS_TAB_VISIT: 'PROJECTS_PARTICIPANTS_TAB_VISIT',
  DONATION_PROJECT_DETAIL_TAB_VISIT: 'DONATION_PROJECT_DETAIL_TAB_VISIT',
  DONATION_DETAIL_TAB_VISIT: 'DONATION_DETAIL_TAB_VISIT',
  DONATION_DISCUSSIONS_TAB_VISIT: 'DONATION_DISCUSSIONS_TAB_VISIT',
  DONATION_DONOR_TAB_VISIT: 'DONATION_DONOR_TAB_VISIT',
  LEADERS_BOARD_TAB_VISIT: 'LEADERS_BOARD_TAB_VISIT',
  MY_CHEERS_TAB_VISIT: 'MY_CHEERS_TAB_VISIT',
  REWARD_CENTRAL_TAB_VISIT: 'REWARD_CENTRAL_TAB_VISIT',
  NOMINATORS_TAB_VISIT: 'NOMINATORS_TAB_VISIT',
  RECIPIENTS_TAB_VISIT: 'RECIPIENTS_TAB_VISIT',
  GIVEN_TAB_VISIT: 'GIVEN_TAB_VISIT',
  RECIEVED_TAB_VISIT: 'RECIEVED_TAB_VISIT',
  POINTS_SUMMARY_TAB_VISIT: 'POINTS_SUMMARY_TAB_VISIT',
  TOTAL_POINTS_TAB_VISIT: 'TOTAL_POINTS_TAB_VISIT',
  NOMINATIONS_REQUEST_TAB_VISIT: 'NOMINATIONS_REQUEST_TAB_VISIT',
  MICROGIVE_UPDATES_COMMENT: 'MICROGIVE_UPDATES_COMMENT',
  BREAKOUTS_DISCUSSION_COMMENT: 'BREAKOUTS_DISCUSSION_COMMENT',
  DI_DISCUSSION_COMMENT: 'DI_DISCUSSION_COMMENT',
  DONATION_DISCUSSION_COMMENT: 'DONATION_DISCUSSION_COMMENT',
  VOLUNTEER_DISCUSSION_COMMENT: 'VOLUNTEER_DISCUSSION_COMMENT',
  REWARD_CENTRAL_LIKE: 'REWARD_CENTRAL_LIKE',
  NOMINATIONS_RECIPIENT_LIKE: 'NOMINATIONS_RECIPIENT_LIKE',
  NOMINATIONS_LIKE: 'NOMINATIONS_LIKE',
  RECIPIENT_LIKE: 'RECIPIENT_LIKE',
  CHEERS_GIVEN_LIKE: 'CHEERS_GIVEN_LIKE',
  CHEERS_RECEIVED_LIKE: 'CHEERS_RECEIVED_LIKE',
  REWARD_CENTRAL_COMMENTS: 'REWARD_CENTRAL_COMMENTS',
  NOMINATIONS_RECIPIENT_COMMENT: 'NOMINATIONS_RECIPIENT_COMMENT',
  NOMINATIONS_COMMENT: 'NOMINATIONS_COMMENT',
  RECIPIENT_COMMENT: 'RECIPIENT_COMMENT',
  CHEERS_GIVEN_COMMENT: 'CHEERS_GIVEN_COMMENT',
  CHEERS_RECEIVED_COMMENT: 'CHEERS_RECEIVED_COMMENT',
  CHEERS_CERTIFICATE_DOWNLOAD: 'CHEERS_CERTIFICATE_DOWNLOAD',
  COMMITMENT_DOWNLOAD: 'WOMENS_DAY_COMMITMENT_DOWNLOAD',
  MICROGIVE_DONATION_SEARCH: 'MICROGIVE_DONATION_SEARCH',
  MICROGIVE_PROJECT_DETAIL_SEARCH: 'MICROGIVE_PROJECT_DETAIL_SEARCH',
  MICROGIVE_PROJECT_SEARCH: 'MICROGIVE_PROJECT_SEARCH',
  MICROGIVE_CERTIFICATE_SHARE: 'MICROGIVE_CERTIFICATE_SHARE',
  EMPLOYEE_SELECTION_DONATE_POINTS_SEARCH: 'EMPLOYEE_SELECTION_DONATE_POINTS_SEARCH',
  EMPLOYEE_SELECTION_MY_TEAM_SEARCH: 'EMPLOYEE_SELECTION_MY_TEAM_SEARCH',
  EMPLOYEE_SELECTION_PEER_SEARCH: 'EMPLOYEE_SELECTION_PEER_SEARCH',
  EMPLOYEE_SELECTION_ANNIVERSARY_SEARCH: 'EMPLOYEE_SELECTION_ANNIVERSARY_SEARCH',
  ANNIVERSARY_CONTESTS_TAG_EMPLOYEE: 'ANNIVERSARY_CONTESTS_TAG_EMPLOYEE',
  MICROGIVE_GALLERY_TAG_EMPLOYEE: 'MICROGIVE_GALLERY_TAG_EMPLOYEE',
  SOM_EVENT_GALLERY_TAG_EMPLOYEE: 'SOM_EVENT_GALLERY_TAG_EMPLOYEE',
  IJP_APPLY: 'IJP_APPLY',
  IJP_REFER: 'IJP_REFER',
  IJP_SHARE_REFERRAL: 'IJP_SHARE_REFERRAL',
  IJP_MY_REFERRAL_READ: 'IJP_REFERRAL_READ',
  IJP_MY_APPLICATION_READ: 'IJP_MY_APPLICATION_READ',
  IJP_READ: 'IJP_READ',
  IJP_ALL_IJP_TAB_VISIT: 'IJP_ALL_IJP_TAB_VISIT',
  IJP_MY_APPLICATIONS_TAB_VISIT: 'IJP_MY_APPLICATIONS_TAB_VISIT',
  IJP_MY_REFERRALS_TAB_VISIT: 'IJP_MY_REFERRALS_TAB_VISIT',
  IJP_TAB_VISIT: 'IJP_TAB_VISIT',
  IJP_RECENT_SEARCH_KEYWORD_CLICK: 'IJP_RECENT_SEARCH_KEYWORD_CLICK',
  IJP_FILTER_CLICK: 'IJP_FILTER_CLICK',
  IJP_SEARCH_QUERY: 'IJP_SEARCH_QUERY',
  IJP_TAB_ALL_OPENINGS_LIST_ITEM_CLICK: 'IJP_TAB_ALL_OPENINGS_LIST_ITEM_CLICK',
  IJP_TAB_LIST_ITEM_CLICK: 'IJP_TAB_LIST_ITEM_CLICK',
  IJP_SEARCH_RESULT_JOB_POST_CLICK: 'IJP_SEARCH_RESULT_JOB_POST_CLICK',
  IJP_APPLY_CLICK: 'IJP_APPLY_CLICK',
  IJP_APPLY_CONFIRM_CLICK: 'IJP_APPLY_CONFIRM_CLICK',
  IJP_REQUEST_ASSISTANCE_CLICK: 'IJP_REQUEST_ASSISTANCE_CLICK',
  IJP_REQUEST_ASSISTANCE_CONFIRM_CLICK: 'IJP_REQUEST_ASSISTANCE_CONFIRM_CLICK',
  IJP_MY_APPLICATION_ITEM_CLICK: 'IJP_MY_APPLICATION_ITEM_CLICK',
  IJP_FILTER_APPLY_CLICK: 'IJP_FILTER_APPLY_CLICK',
  IJP_ASK_HELP_CLICK: 'IJP_ASK_HELP_CLICK',
  IJP_REFERRALS_TAB_VISIT: 'IJP_REFERRALS_TAB_VISIT',
  IJP_REFERRALS_TAB_LIST_ITEM_CLICK: 'IJP_REFERRALS_TAB_LIST_ITEM_CLICK',
  IJP_REFERRALS_SEARCH_QUERY: 'IJP_REFERRALS_SEARCH_QUERY',
  IJP_REFERRALS_RECENT_SEARCH_KEYWORD_CLICK: 'IJP_REFERRAL_RECENT_SEARCH_KEYWORD_CLICK',
  IJP_REFERRALS_TAB_ALL_OPENINGS_CLICK: 'IJP_REFERRALS_TAB_ALL_OPENINGS_CLICK',
  IJP_REFERRALS_SEARCH_RESULT_POST_CLICK: 'IJP_REFERRALS_SEARCH_RESULT_POST_CLICK',
  IJP_REFERRALS_REFER_CLICK: 'IJP_REFERRALS_REFER_CLICK',
  IJP_REFERRALS_FILTER_CLICK: 'IJP_REFERRALS_FILTER_CLICK',
  IJP_REFERRALS_FILTER_APPLY_CLICK: 'IJP_REFERRALS_FILTER_APPLY_CLICK',
  IJP_REFERRALS_CONSENT_ACCEPTED: 'IJP_REFERRALS_CONSENT_ACCEPTED',
  IJP_REFERRALS_FORM_SUBMIT_CLICK: 'IJP_REFERRALS_FORM_SUBMIT_CLICK',
  IJP_MY_REFERRALS_ITEM_CLICK: 'IJP_MY_REFERRALS_ITEM_CLICK',
  MY_PROFILE_INFO_UPDATED: 'MY_PROFILE_INFO_UPDATED',
  MY_PROFILE_ASSET_VIEW: 'MY_PROFILE_ASSET_VIEW',
  MY_PROFILE_PROFILE_TAB_VISIT: 'MY_PROFILE_PROFILE_TAB_VISIT',
  MY_PROFILE_ASSETS_TAB_VISIT: 'MY_PROFILE_ASSETS_TAB_VISIT',
  MY_PROFILE_INSURANCE_TAB_VISIT: 'MY_PROFILE_INSURANCE_TAB_VISIT',
  MY_PROFILE_WISHES_TAB_VISIT: 'MY_PROFILE_WISHES_TAB_VISIT',
  MY_PROFILE_IDCARD_TAB_VISIT: 'MY_PROFILE_IDCARD_TAB_VISIT',
  MY_PROFILE_DETAILS_TAB_VISIT: 'MY_PROFILE_DETAILS_TAB_VISIT',
  MY_PROFILE_SOCIAL_ACCOUNTS_TAB_VISIT: 'MY_PROFILE_SOCIAL_ACCOUNTS_TAB_VISIT',
  MY_PROFILE_APP_SETTINGS_TAB_VISIT: 'MY_PROFILE_APP_SETTINGS_TAB_VISIT',
  NEW_JOINEES_HIGHER_LEVELS_SUBMIT_SESSION_RATING: 'NEW_JOINEES_SUBMIT_AGENDA_RATING',
  NEW_JOINEES_LOWER_LEVELS_CHECKLIST_SAVE: 'NEW_JOINEES_CHECKLIST_SAVE',
  NEW_JOINEES_LOWER_LEVELS_REGISTER_NEO: 'NEW_JOINEES_REGISTER_NEO',
  NEW_JOINEES_LOWER_LEVELS_VIEW_QUICK_LINK: 'NEW_JOINEES_VIEW_QUICK_LINK',
  NEW_JOINEES_OVERVIEW_TAB_VISIT: 'NEW_JOINEES_OVERVIEW_TAB_VISIT',
  NEW_JOINEES_CHECKLIST_TAB_VISIT: 'NEW_JOINEES_CHECKLIST_TAB_VISIT',
  NEW_JOINEES_CALENDAR_TAB_VISIT: 'NEW_JOINEES_CALENDAR_TAB_VISIT',
  NEW_JOINEES_QUICK_LINKS_TAB_VISIT: 'NEW_JOINEES_QUICK_LINKS_TAB_VISIT',
  NEW_JOINEES_AGENDA_TAB_VISIT: 'NEW_JOINEES_AGENDA_TAB_VISIT',
  NEW_JOINEES_INFO_TAB_VISIT: 'NEW_JOINEES_INFO_TAB_VISIT',
  NEW_JOINEES_FEEDBACK_TAB_VISIT: 'NEW_JOINEES_FEEDBACK_TAB_VISITS',
  NEW_JOINEES_QUESTIONS_TAB_VISIT: 'NEW_JOINEES_QUESTIONS_TAB_VISIT',
  NEW_JOINEES_RATE_TAB_VISIT: 'NEW_JOINEES_RATE_TAB_VISIT',
  NEW_JOINEES_AGENDA_DETAILS_TAB_VISIT: 'NEW_JOINEES_AGENDA_DETAILS_TAB_VISIT',
  NEW_JOINEES_AGENDA_MEDIA_TAB_VISIT: 'NEW_JOINEES_AGENDA_MEDIA_TAB_VISIT',
  NEW_JOINEES_AGENDA_QUESTIONS_TAB_VISIT: 'NEW_JOINEES_AGENDA_QUESTIONS_TAB_VISIT',
  HOME_VOICE_ASSISTANT_VIEW: 'HOME_VOICE_ASSISTANT_VIEW',
  HOME_EDIT_FAVORITE: 'HOME_EDIT_FAVORITE',
  HOME_NAVIGATE_FROM_FAVORITE: 'HOME_NAVIGATE_FROM_FAVORITE',
  HOME_SECTION_CLICK: 'HOME_SECTION_CLICK',
  HOME_DIALOG_VOICE_ASSISTANT_CLICK: 'HOME_DIALOG_VOICE_ASSISTANT_CLICK',
  MENU_FEATURE_NAVIGATE: 'MENU_FEATURE_NAVIGATE',
  MENU_FAVORITES_EDIT: 'MENU_FAVORITES_EDIT',
  ASK_NEW_JOINEES_HIGHER_LEVELS_SESSION_QUESTION: 'ASK_NEW_JOINEES_AGENDA_QUESTION',
  ASK_BREAKOUTS_SESSION_QUESTION: 'ASK_BREAKOUTS_AGENDA_QUESTION',
  BREAKOUTS_SUBMIT_SESSION_RATING: 'BREAKOUTS_SUBMIT_SESSION_RATING',
  FILTER_SELECTED: 'FILTER_SELECTED',
  BROADCAST_TAB_VISIT: 'BROADCAST_TAB_VISIT',
  DI_ANNOUNCEMENTS_TAB_VISIT: 'DI_ANNOUNCEMENTS_TAB_VISIT',
  DI_EVENTS_TAB_VISIT: 'DI_EVENTS_TAB_VISIT',
  DI_PODCAST_TAB_VISIT: 'DI_PODCAST_TAB_VISIT',
  DI_GALLERY_TAB_VISIT: 'DI_GALLERY_TAB_VISIT',
  DI_DISCUSSIONS_TAB_VISIT: 'DI_DISCUSSIONS_TAB_VISIT',
  DI_PLEDGE_TAB_VISIT: 'DI_PLEDGE_TAB_VISIT',
  GCDO_TAB_VISIT: 'GCDO_TAB_VISIT',
  ESG_TAB_VISIT: 'ESG_TAB_VISIT',
  MICROBIZ_TAB_VISIT: 'MICROBIZ_TAB_VISIT',
  BTO_TAB_VISIT: 'BTO_TAB_VISIT',
  BTO_FAQ_TAB_VISIT: 'BTO_FAQ_TAB_VISIT',
  BROADCAST_COMMENT: 'BROADCAST_COMMENT',
  GCDO_COMMENT: 'GCDO_COMMENT',
  BROADCAST_UPDATE_LIKE: 'BROADCAST_UPDATE_LIKE',
  MICROKNOW_UPDATE_LIKE: 'MICROKNOW_UPDATE_LIKE',
  DI_ANNOUNCEMENT_UPDATE_LIKE: 'DI_ANNOUNCEMENT_UPDATE_LIKE',
  GCDO_UPDATE_LIKE: 'GCDO_UPDATE_LIKE',
  MICROBIZ_UPDATE_LIKE: 'MICROBIZ_UPDATE_LIKE',
  BTO_UPDATE_LIKE: 'BTO_UPDATE_LIKE',
  BROADCAST_READ: 'BROADCAST_READ',
  MICROKNOW_READ: 'MICROKNOW_READ',
  MI_HR_READ: 'MI_HR_SUPPORT_READ',
  MICRO_LAW_READ: 'MICRO_LAW_READ',
  MI_HR_UPDATE_LIKE: 'MI_HR_SUPPORT_UPDATE_LIKE',
  MICRO_LAW_UPDATE_LIKE: 'MICRO_LAW_UPDATE_LIKE',
  MICRO_LAW_UPDATE_COMMENT: 'MICRO_LAW_UPDATE_COMMENT',
  DI_ANNOUNCEMENT_READ: 'DI_ANNOUNCEMENT_READ',
  GCDO_READ: 'GCDO_READ',
  MICROBIZ_READ: 'MICROBIZ_READ',
  BTO_READ: 'BTO_READ',
  CEO_ASK_QUESTION: 'CEO_ASK_QUESTION',
  CEO_LATEST_REPLY: 'CEO_LATEST_REPLY',
  CEO_QUESTION_COMMENT: 'CEO_QUESTION_COMMENT',
  CEO_QUESTION_LIKE: 'CEO_QUESTION_LIKE',
  CEO_CHAT_SEARCH: 'CEO_CHAT_SEARCH',
  COMMUNITY_ADMIN_REPORT: 'COMMUNITY_ADMIN_REPORT',
  COMMUNITY_COMMENT: 'COMMUNITY_COMMENT',
  DEAR_EMPLOYER_COMMENT: 'DEAR_EMPLOYER_COMMENT',
  DEAR_EMPLOYER_LIKE: 'DEAR_EMPLOYE_LIKE',
  ECARDS_TAB_VISIT: 'ECARDS_TAB_VISIT',
  E_CARDS_SHARE: 'E_CARDS_SHARE',
  CONTESTS_TAB_VISIT: 'CONTESTS_TAB_VISIT',
  EVENTS_TAB_VISIT: 'EVENTS_TAB_VISIT',
  EVENTS_SHOW_CALENDER: 'EVENTS_SHOW_CALENDER',
  EVENTS_REGISTER: 'EVENTS_REGISTER',
  EVENTS_VIEW_GALLERY: 'EVENTS_VIEW_GALLERY',
  EVENTS_LIKE_GALLERY: 'EVENTS_LIKE_GALLERY',
  FACILITY_TAB_VISIT: 'FACILITY_TAB_VISIT',
  SHARE_BUSINESS_CARD: 'SHARE_BUSINESS_CARD',
  FACILITY_LIKE: 'FACILITY_LIKE',
  GALLERY_TAB_VISIT: 'GALLERY_TAB_VISIT',
  NEWS_LIKE: 'NEWS_LIKE',
  NEWS_COMMENT: 'NEWS_COMMENT',
  NEWS_SHARE: 'NEWS_SHARE',
  OUR_CUSTOMERS_TAB_VISIT: 'OUR_CUSTOMERS_TAB_VISIT',
  OUR_CUSTOMERS_LIKE: 'OUR_CUSTOMERS_LIKE',
  OUR_CUSTOMER_COMMENT: 'OUR_CUSTOMER_COMMENT',
  POLL_TAB_VISIT: 'POLL_TAB_VISIT',
  SURVEYS_TAB_VISIT: 'SURVEYS_TAB_VISIT',
  SURVEY_PARTICIPATE: 'SURVEY_PARTICIPATE',
  POLLS_VOTE: 'POLLS_VOTE',
  SOM_EVENT_TAB: 'SOM_EVENT_TAB',
  SOM_FORWARD_TAB: 'SOM_FORWARD_TAB',
  SOM_EBOOK_TAB: 'SOM_EBOOK_TAB',
  SOM_STAR_TAB: 'SOM_STAR_TAB',
  STARS_OF_MICROLAND_COMMENT: 'STARS_OF_MICROLAND_COMMENT',
  STARS_OF_MICROLAND_LIKE: 'STARS_OF_MICROLAND_LIKE',
  THE_WALL_LIKE: 'THE_WALL_LIKE',
  THE_WALL_POST: 'THE_WALL_POST',
  THE_WALL_CREATE_NEW_POST_CLICK: 'THE_WALL_CREATE_NEW_POST_CLICK',
  THE_WALL_DELETE_POST_CLICK: 'THE_WALL_DELETE_POST_CLICK',
  THE_WALL_EDIT_POST_CLICK: 'THE_WALL_EDIT_POST_CLICK',
  THE_WALL_REPORT_POST_CLICK: 'THE_WALL_REPORT_POST_CLICK',
  THE_WALL_VIEW_POST_TAGGED_PEOPLE_CLICK: 'THE_WALL_VIEW_POST_TAGGED_PEOPLE_CLICK',
  THE_WALL_PUBLISH_NEW_POST_CLICK: 'THE_WALL_PUBLISH_NEW_POST_CLICK',
  THE_WALL_UPDATE_POST_CLICK: 'THE_WALL_UPDATE_POST_CLICK',
  THE_WALL_SEARCH_CLICKED: 'THE_WALL_SEARCH_CLICKED',
  CEO_CHAT_SEARCH_CLICKED: 'CEO_CHAT_SEARCH_CLICKED',
  WOMENS_DAY_CENTRAL_TAB: 'WOMENS_DAY_CENTRAL_TAB',
  WOMENS_DAY_MEET_THE_SPEAKERS_TAB: 'WOMENS_DAY_MEET_THE_SPEAKERS_TAB',
  WOMENS_DAY_FEEDBACKS_TAB: 'WOMENS_DAY_FEEDBACKS_TAB',
  WOMENS_DAY_CONVO_TAB: 'WOMENS_DAY_CONVO_TAB',
  WOMENS_DAY_CPOMESSAGE_TAB: 'WOMENS_DAY_CPOMESSAGE_TAB',
  WOMENS_DAY_GALLERY_TAB: 'WOMENS_DAY_GALLERY_TAB',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
  PANDEMIC_SUPPORT_ADVISORIES_TAB_VISIT: 'COVID_SUPPORT_ADVISORIES_TAB_VISIT',
  PANDEMIC_SUPPORT_GUIDE_TAB_VISIT: 'COVID_SUPPORT_GUIDE_TAB_VISIT',
  PANDEMIC_SUPPORT_CONVO_TAB_VISIT: 'COVID_SUPPORT_CONVO_TAB_VISIT',
  PANDEMIC_SUPPORT_ADVISORIES_ARTICLE_VIEW: 'COVID_SUPPORT_ADVISORIES_ARTICLE_VIEW',
  PANDEMIC_SUPPORT_GUIDE_VIDEO_VIEW: 'COVID_SUPPORT_GUIDE_VIDEO_VIEW',
  PANDEMIC_SUPPORT_GUIDE_PDF_VIEW: 'COVID_SUPPORT_GUIDE_PDF_VIEW',
  PANDEMIC_SUPPORT_CONVO_COMMENT: 'COVID_SUPPORT_CONVO_COMMENT',
  GLOBAL_SEARCH_TAB_VISIT: 'GLOBAL_SEARCH_TAB_VISIT',
  CHATBOT_OPEN: 'CHATBOT_OPEN',
  CHATBOT_VISIT: 'CHATBOT_VISIT',
  CHATBOT_USER_MESSAGE: 'CHATBOT_USER_MESSAGE',
  DI_EVENTS_VIEW_GALLERY: 'DI_EVENTS_VIEW_GALLERY',
  DI_EVENTS_REGISTER: 'DI_EVENTS_REGISTER',
  COMMUNITIES_POST_LIKE: 'COMMUNITIES_POST_LIKE',
  COMMUNITIES_POST_COMMENT: 'COMMUNITIES_POST_COMMENT',
  PODCAST_ABOUT_TAB_VISIT: 'PODCAST_ABOUT_TAB_VISIT',
  PODCAST_CHANNELS_TAB_VISIT: 'PODCAST_CHANNELS_TAB_VISIT',
  PODCAST_EPISODE_LIKE: 'PODCAST_EPISODE_LIKE',
  PODCAST_EPISODE_PLAY: 'PODCAST_EPISODE_PLAY',
  TRAVEL_REQUEST_PLAN_TRAVEL_TAB_VISIT: 'TRAVEL_REQUEST_PLAN_TRAVEL_TAB_VISIT',
  TRAVEL_REQUEST_UPCOMNG_TRAVEL_TAB_VISIT: 'TRAVEL_REQUEST_UPCOMNG_TRAVEL_TAB_VISIT',
  TRAVEL_REQUEST_ALL_REQUESTS_TAB_VISIT: 'TRAVEL_REQUEST_ALL_REQUESTS_TAB_VISIT',
  TRAVEL_REQUEST_FOR_APPROVAL_TAB_VISIT: 'TRAVEL_REQUEST_FOR_APPROVAL_TAB_VISIT',
  TR_PLAN_TRAVEL_SAVE_DRAFT_CLICK: 'TR_PLAN_TRAVEL_SAVE_DRAFT_CLICK',
  TR_PLAN_TRAVEL_PROCEED_CLICK: 'TR_PLAN_TRAVEL_PROCEED_CLICK',
  TR_PLAN_TRAVEL_ADD_TRAVELLER_CLICK: 'TR_PLAN_TRAVEL_ADD_TRAVELLER_CLICK',
  TR_PLAN_TRAVEL_ADD_ANOTHER_TRAVEL_CLICK: 'TR_PLAN_TRAVEL_ADD_ANOTHER_TRAVEL_CLICK',
  TR_PLAN_TRAVEL_ADD_GUEST_CLICK: 'TR_PLAN_TRAVEL_ADD_GUEST_CLICK',
  TR_PLAN_TRAVEL_ADD_ANOTHER_ACCOM_CLICK: 'TR_PLAN_TRAVEL_ADD_ANOTHER_ACCOM_CLICK',
  TR_UPCOMING_TRAVEL_TAB_ITEM_CLICK: 'TR_UPCOMING_TRAVEL_TAB_ITEM_CLICK',
  TR_ALL_REQUESTS_TAB_ITEM_CLICK: 'TR_ALL_REQUESTS_TAB_ITEM_CLICK',
  TR_FOR_APPROVAL_TAB_ITEM_CLICK: 'TR_FOR_APPROVAL_TAB_ITEM_CLICK',
  TR_REVIEW_TAKE_ACTION_CLICK: 'TR_REVIEW_TAKE_ACTION_CLICK',
  TR_ADD_TRAVELLER_CREATE_CLICK: 'TR_ADD_TRAVELLER_CREATE_CLICK',
  TR_ADD_TRAVELLER_SAVE_TRAVELLER_CLICK: 'TR_ADD_TRAVELLER_SAVE_TRAVELLER_CLICK',
  WALLPAPERS_TAB_VISIT: 'WALLPAPER_TAB_VISIT',
  WALLPAPERS_SHARE: 'WALLPAPER_SHARE',
  WALLPAPERS_DOWNLOAD: 'WALLPAPER_SHARE',
  WALLPAPERS_SWITCH_CLICK: 'WALLPAPERS_SWITCH_CLICK',
  IJP_BOOKMARK_TAB_VISIT: 'IJP_BOOKMARK_TAB_VISIT',
  REFERRAL_BOOKMARK_TAB_VISIT: 'REFERRAL_BOOKMARK_TAB_VISIT',
  IJP_BOOKMARK_JOB_POST_CLICK: 'IJP_BOOKMARK_JOB_POST_CLICK',
  REFERRAL_BOOKMARK_JOB_POST_CLICK: 'REFERRAL_BOOKMARK_JOB_POST_CLICK',
  CHAT_ADD_PARTICIPANTS_SEARCH: 'CHAT_ADD_PARTICIPANTS_SEARCH',
  SHARE_BUSINESS_CARD_EMAIL: 'SHARE_BUSINESS_CARD_EMAIL',
  ML_MONDAY_COMMENT: 'ML_MONDAY_COMMENT',
  ML_MONDAY_LIKE: 'ML_MONDAY_LIKE',
  MI_HR_TAB_VISIT: ' MI_HR_SUPPORT_TAB_VISIT',
  MI_HR_ACTIVITIES_UPDATE: 'MI_HR_SUPPORT_ACTIVITIES_UPDATE',
  PROFILE_CHAT: 'PROFILE_CHAT',
  PROFILE_CALL: 'PROFILE_CALL',
  PROFILE_EMAIL: 'PROFILE_EMAIL',
  PROFILE_VIDEO_ON: 'PROFILE_VIDEO_ON',
  PROFILE_VIDEO_OFF: 'PROFILE_VIDEO_OFF',
  PROFILE_MEET: 'PROFILE_MEET',
  ESG_UPDATE_LIKE: 'ESG_UPDATE_LIKE',
  ESG_SEARCH_QUERY: 'ESG_SEARCH_QUERY',
  ESG_READ: 'ESG_READ',
  ESG_SEARCH_CLICKED: 'ESG_SEARCH_CLICKED',
  ESG_CALENDAR_FILTER: 'ESG_CALENDAR_FILTER',
  MLF_UPDATE_LIKE: 'ESG_UPDATE_LIKE',
  MLF_SEARCH_QUERY: 'MLF_SEARCH_QUERY',
  MLF_READ: 'MLF_READ',
  MLF_SEARCH_CLICKED: 'MLF_SEARCH_CLICKED',
  MLF_CALENDAR_FILTER: 'MLF_CALENDAR_FILTER',
  EIGHTYG_DOWNLOAD_CERTIFICATE: 'EIGHTYG_DOWNLOAD_CERTIFICATE',
  MICRO_LAW_CALENDAR_FILTER: 'MICRO_LAW_CALENDAR_FILTER',
  MICRO_LAW_SEARCH_CLICKED: 'MICRO_LAW_SEARCH_CLICKED',
  VARIABLE_PAY_TAB_VISIT: 'VARIABLE_PAY_TAB_VISIT',
  EMERGENCY_PLAYBOOK_ITEM_CLICK: 'EMERGENCY_PLAYBOOK_ITEM_CLICK',
  NOTIFICATION_SEARCH: 'NOTIFICATION_SEARCH_CLICKED',
  VARIABLE_PAY_PLAN_TAB_VISIT: 'VARIABLE_PAY_PLAN_TAB_VISIT',
  VARIABLE_PAY_ACTUALS_TAB_VISIT: 'VARIABLE_PAY_ACTUALS_TAB_VISIT',
  VARIABLE_PAY_PAYOUT_TAB_VISIT: 'VARIABLE_PAY_PAYOUT_TAB_VISIT',
  VARIABLE_PAY_TARGET_LETTER_TAB_VISIT: 'VARIABLE_PAY_TARGET_LETTER_TAB_VISIT',
  VARIABLE_PAY_STATEMENT_TAB_VISIT: 'VARIABLE_PAY_STATEMENT_TAB_VISIT',
  COMMUNITY_SEARCH_CLICK: 'COMMUNITY SEARCH CLICKED',
  COMMUNITY_SEARCH_QUERY: 'COMMUNITY_SEARCH_QUERY',
  ASK_ML_AI_SEARCH: 'ASK_ML_AI_SEARCH',
  HASHTAG_SUGGESTION_TAP: 'HASHTAG_SUGGESTION_TAP',
  HASHTAG_QUERY: 'HASHTAG_QUERY',
  HASHTAG_CLICK: 'HASHTAG_CLICK',
  USER_MENTION_CLICK: 'USER_MENTION_CLICK',
  POLL_LIKE: 'POLL_LIKE',
  POLL_COMMENT: 'POLL_COMMENT',
  SURVEY_LIKE: 'SURVEY_LIKE',
  SURVEY_COMMENT: 'SURVEY_COMMENT',
  SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',

  AUCTION_ITEM_CLICK: 'AUCTION_ITEM_CLICK',
  AUCTION_COMMENT_CLICK: 'AUCTION_COMMENT_CLICK',
  AUCTION_LIKED_COUNT: 'AUCTION_LIKED_COUNT',
  AUCTION_LIKE_CLICK: 'AUCTION_LIKE_CLICK',
  AUCTION_ARTIST_CLICK: 'AUCTION_ARTIST_CLICK',
  AUCTION_MAKE_BID_BUTTON: 'AUCTION_MAKE_BID_BUTTON',
  AUCTION_OK_THANKYOU_BUTTON: 'AUCTION_OK_THANKYOU_BUTTON',
  MI_CHILD_CARE_FETCH_CENTRES: 'MI_CHILD_CARE_FETCH_CENTRES',
  MI_CHILD_CARE_REGISTRATION: 'MI_CHILD_CARE_REGISTRATION',
  MI_CHILD_CARE_GET_DATA: 'MI_CHILD_CARE_GET_DATA',
  MI_CHILD_CARE_WITHDRAW: 'MI_CHILD_CARE_WITHDRAW',
  ATTENDANCE_REGULARISE_BUTTON_CLICK: 'ATTENDANCE_REGULARISE_BUTTON_CLICK',
  ATTENDANCE_SIGN_IN: 'ATTENDANCE_SIGN_IN',
  ATTENDANCE_SIGN_OUT: 'ATTENDANCE_SIGN_OUT',
  MLDay_35_HOME: 'MLDay_35_HOME',
  ML_35_CLICK: 'MLDay_35',
  ML_34_CLICK: 'MLDay_34',
  ML_33_CLICK: 'MLDay_33',
  ML_32_CLICK: 'MLDay_32',
  ML_31_CLICK: 'MLDay_31',
  ML_30_CLICK: 'MLDay_30',

  ESTORE_ALL_PRODUCTS: 'STORE_ALL_PRODUCTS',
  ESTORE_FEATURED_PRODUCT_CLICK: 'ESTORE_FEATURED_PRODUCT',
  ESTORE_TRENDING_PRODUCT_CLICK: 'ESTORE_TRENDING_PRODUCT',
  ESTORE_COLLECTION_CLICK: 'ESTORE_COLLECTION',
  ESTORE_RETURN_POLICY_CLICK: 'ESTORE_RETURN_POLICY',
  ESTORE_TC_CLICK: 'ESTORE_TC',
  ESTORE_FAQ_CLICK: 'ESTORE_FAQ',
  ESTORE_PRIVICY_POLICY_CLICK: 'ESTORE_PRIVICY_POLICY',
  ESTORE_ADDRESS_LIST_CLICK: 'ESTORE_ADDRESS_LIST',
  ESTORE_ORDERS_LIST_CLICK: 'ESTORE_ORDERS_LIST',
  ESTORE_SEARCH_CLICK: 'ESTORE_SEARCH',
  ESTORE_CART_CLICK: 'ESTORE_CART',
  ESTORE_SIZE_CHART_CLOSE: 'ESTORE_SIZE_CHART_CLOSE',
  ESTORE_SIZE_CHART_OPEN: 'ESTORE_SIZE_CHART_OPEN',
  ESTORE_SIZE_SELECT: 'ESTORE_SIZE_SELECT',
  ESTORE_COLOR_SELECT: 'ESTORE_COLOR_SELECT',
  ESTORE_ADDRESS_SELECT: 'ESTORE_ADDRESS_SELECT',
  ESTORE_ADD_TO_CART: 'ESTORE_ADD_TO_CART',
  ESTORE_ADD_ADDRESS: 'ESTORE_ADD_ADDRESS',
  ESTORE_CANCEL_DELETE_ADDRESS: 'ESTORE_CANCEL_DELETE_ADDRESS',
  ESTORE_CONFIRM_DELETE_ADDRESS: 'ESTORE_CONFIRM_DELETE_ADDRESS',
  ESTORE_DELETE_ADDRESS: 'ESTORE_DELETE_ADDRESS',
  ESTORE_MAKE_DEFAULT_ADDRESS: 'ESTORE_MAKE_DEFAULT_ADDRESS',
  ESTORE_ADDRESS_ITEM_CLICK: 'ESTORE_ADDRESS_ITEM_CLICK',
  ESTORE_EDIT_DEFAULT_ADDRESS: 'ESTORE_EDIT_DEFAULT_ADDRESS',
  ESTORE_EDIT_ADDRESS: 'ESTORE_EDIT_ADDRESS',
  ESTORE_ORDER_LIST_ITEM_CLICK: 'ESTORE_ORDER_LIST_ITEM_CLICK',
  ESTORE_ORDER_LIST_SCREEN_SEARCH_CLICK: 'ESTORE_ORDER_LIST_SCREEN_SEARCH_CLICK',
  ESTORE_ORDER_LIST_SCREEN_CART_CLICK: 'ESTORE_ORDER_LIST_SCREEN_CART_CLICK',
  ESTORE_SHIPMENT_DETAIL_LIST_SCREEN_CART_CLICK: 'ESTORE_SHIPMENT_DETAIL_LIST_SCREEN_CART_CLICK',
  ESTORE_SHIPMENT_DETAIL_LIST_SCREEN_SEARCH_CLICK:
    'ESTORE_SHIPMENT_DETAIL_LIST_SCREEN_SEARCH_CLICK',
  ESTORE_SHIPMENT_DETAIL_CANCLE_ORDER_CLICK: 'ESTORE_SHIPMENT_DETAIL_CANCLE_ORDER_CLICK',
  ESTORE_SHIPMENT_DETAIL_CONTINUE_SHOPING_CLICK: 'ESTORE_SHIPMENT_DETAIL_CONTINUE_SHOPING_CLICK',
  ESTORE_SHIPMENT_DETAIL_VIEW_ORDER_CLICK: 'ESTORE_SHIPMENT_DETAIL_VIEW_ORDER_CLICK:',
  ESTORE_SHIPMENT_DETAIL_GO_BACK_CLICK: 'ESTORE_SHIPMENT_DETAIL_GO_BACK_CLICK',
  ESTORE_SHIPMENT_DETAIL_DOWNLOAD_INVOICE_CLICK: 'ESTORE_SHIPMENT_DETAIL_DOWNLOAD_INVOICE_CLICK',
  ESTORE_SELECT_ADDRESS_CLICK: 'ESTORE_SELECT_ADDRESS_CLICK',
  ESTORE_RETURN_REQUEST_CANCLE_CLICK: 'ESTORE_RETURN_REQUEST_CANCLE_CLICK',
  ESTORE_RETURN_REQUEST_CONTINUE_SHOPING_CLICK: 'ESTORE_RETURN_REQUEST_CANCLE_CLICK',
  ESTORE_INVOICE_SCREEN: 'ESTORE_INVOICE_SCREEN',
  ESTORE_INVOICE_SCREEN_SHARE_CLICK: 'ESTORE_INVOICE_SCREEN_SHARE_CLICK',
  ESTORE_CART_PLACE_ORDER: 'ESTORE_CART_PLACE_ORDER',
  ESTORE_CART_PLACE_ORDER_TOAST: 'ESTORE_CART_PLACE_ORDER_TOAST',
  ESTORE_CART_PLACE_ORDER_EMPTY_ADDRESS: 'ESTORE_CART_PLACE_ORDER_EMPTY_ADDRESS',
  ESTORE_CART_SELECT_ADDRESS: 'ESTORE_CART_SELECT_ADDRESS:',
  ESTORE_CART_CHANGE_ADDRESS: 'ESTORE_CART_CHANGE_ADDRESS:',
  ESTORE_CART_ITEM_UPDATE: 'ESTORE_CART_ITEM_UPDATE',
  ESTORE_CART_ITEM_DELETE: 'ESTORE_CART_ITEM_DELETE',
  ESTORE_ORDER_SUCCESS_SCREEN_CONTINUE: 'ESTORE_ORDER_SUCCESS_SCREEN_CONTINUE',
  ESTORE_TERM_AND_CONDITION: 'ESTORE_TERM_AND_CONDITION',
  ESTORE_PRODUCT_CLICK: 'ESTORE_PRODUCT',
  ESTORE_SHIPMENT_CANCEL: 'ESTORE_SHIPMENT_CANCEL',
  ESTORE_SHIPMENT_CANCEL_NO: 'ESTORE_SHIPMENT_CANCEL_NO',
  ESTORE_CONFIRM_ADDRESS: 'ESTORE_CONFIRM_ADDRESS',
  ESTORE_UPDATE_ADDRESS: 'ESTORE_UPDATE_ADDRESS',
  ML35_VIDEO_LIKE: 'ML35_VIDEO_LIKE',
  ML35_VIDEO_COMMENTS: 'ML35_VIDEO_COMMENTS',
  ML35_VIDEO_LIKED_COUNT: 'ML35_VIDEO_LIKED_COUNT',
  ML35_PICTURE_SHARE: 'ML35_PICTURE_SHARE',
  ML35_PICTURE_DOWNLOAD: 'ML35_PICTURE_DOWNLOAD',
  NEO_SESSION_TAB_VISIT: 'NEO_SESSION_TAB_VISIT',
  NEO_PICTURES_TAB_VISIT: 'NEO_PICTURES_TAB_VISIT',
  NEO_DOCUMENTS_TAB_VISIT: 'NEO_DOCUMENTS_TAB_VISIT',
  NEO_AGENDA_TAB_VISIT: 'NEO_AGENDA_TAB_VISIT',
  NEO_ATTENDEES_TAB_VISIT: 'NEO_ATTENDEES_TAB_VISIT',
  NEO_PICTURE_UPLOAD_CLICK: 'NEO_PICTURE_UPLOAD_CLICK',
  NEO_RSVP_YES: 'NEO_RSVP_YES',
  NEO_RSVP_NO: 'NEO_RSVP_NO',
};

export const ANALYTICS_ITEM_NAMES = {
  MY_PROFILE: 'MY_PROFILE',
  COMPLAINT: 'COMPLAINT',
  PANDEMIC_SUPPORT: 'COVID_SUPPORT',
  EVENTS_CALENDER: 'EVENTS_CALENDER',
  ECARDS: 'ECARDS',
  EMAIL_VERIFICATION: 'Email Verification',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  ANSWER_FEEDBACK: 'ANSWER_FEEDBACK',
  ASK_ORG: 'ASK_MICROLAND',
  FEEDBACK: 'FEEDBACK',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  ESCALATE: 'ESCALATE',
  CONTESTS: 'CONTESTS',
  DETAILS: 'DETAILS',
  BUSINESS_DASHBOARD: 'BUSINESS_DASHBOARD',
  SALES_INCENTIVE: 'SALES_INCENTIVE',
  CELEBRATIONS: 'CELEBRATIONS',
  CELEBRATION_PROFILE: 'CELEBRATION_PROFILE',
  DIRECTORY_SEARCH: 'DIRECTORY_SEARCH',
  DIRECTORY_PROFILE: 'DIRECTORY_PROFILE',
  LIKE: 'LIKE',
  COMMENT: 'COMMENT',
  TAG_EMPLOYEE: 'TAG_EMPLOYEE',
  SEARCH: 'SEARCH',
  BREAKOUTS: 'BREAKOUTS',
  POLICIES: 'POLICIES',
  ANNIVERSARY: 'ANNIVERSARY',
  DIGITAL_WARRIOR: 'DIGITAL_WARRIOR',
  CONTESTS_DETAIL: 'CONTESTS_DETAIL',
  MICROGIVE: 'MICROGIVE',
  CHEERS: 'CHEERS',
  MY_PROFILE_ASSETS: 'MY_PROFILE_ASSETS',
  NEW_JOINEES_LOWER_LEVELS: 'NEW_JOINEES_LOWER_LEVELS',
  NEW_JOINEES_HIGHER_LEVELS: 'NEW_JOINEES_HIGHER_LEVELS',
  HOME: 'HOME',
  MENU: 'MENU',
  BROADCASTS: 'BROADCASTS',
  DI: 'DI',
  GCDO: 'GCDO',
  MICROBIZ: 'MICROBIZ',
  BTO: 'BTO',
  REPORT: 'REPORT',
  E_CARDS: 'E_CARDS',
  EVENTS: 'EVENTS',
  FACILITY: 'FACILITY',
  GALLERY: 'GALLERY',
  SHARE: 'SHARE',
  OUR_CUSTOMER: 'OUR_CUSTOMER',
  POLLS_AND_SURVEYS: 'POLLS_AND_SURVEYS',
  SURVYES: 'SURVYES',
  POLLS: 'POLLS',
  SOM: 'SOM',
  WOMENS_DAY: 'WOMENS_DAY',
  IJPR: 'IJPR',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
  CHATBOT: 'CHATBOT',
  THE_WALL: 'THE_WALL',
  PODCASTS: 'PODCASTS',
  WALLPAPERS: 'WALLPAPER',
  TRAVEL_REQUEST: 'TRAVEL_REQUEST',
  MI_HR: 'MI_HR_SUPPORT',
  ESG: 'ESG',
  MLF: 'MLF',
  EIGHTYG: 'EIGHTYG',
  MICRO_LAW: 'MICRO_LAW',
  CEO_CHAT: 'CEO_CHAT',
  VARIABLE_PAY: 'VARIABLE_PAY',
  EMERGENCY_PLAYBOOK: 'EMERGENCY_PLAYBOOK',
  COMMUNITIES: 'COMMUNITIES',
  HASHTAG: 'HASHTAG',
  AUCTION_ITEM: 'AUCTION_ITEM',
  USER_MENTION: 'USER_MENTION',
  MI_CHILD_CARE: 'MI_CHILD_CARE',
  MI_CHILD_CARE_CONTENT: 'MI_CHILD_CARE_CONTENT',
  ATTENDANCE: 'ATTENDANCE',

  ALL_PRODUCTS: 'ALL_PRODUCTS',
  FEATURED_PRODUCT: 'FEATURED_PRODUCT',
  TRENDING_PRODUCT: 'TRENDING_PRODUCT',
  RETURN_POLICY: 'RETURN_POLICY',
  FAQ: 'FAQ',
  CART: 'CART',
  PRIVICY_POLICY: 'PRIVICY_POLICY',
  ADDRESS_LIST: 'ADDRESS_LIST',
  ADDRESS: 'ADDRESS',
  ORDERS_LIST: 'ORDERS_LIST',
  SIZE_CHART: 'SIZE_CHART',
  SIZE: 'SIZE',
  COLOR: 'COLOR',
  SAVE_ADDRESS: 'SAVE_ADDRESS',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  SELECT_ADDRESS: 'SELECT_ADDRESS',
  DEFAULT_ADDRESS: 'EDIT_DEFAULT_ADDRESS',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  ADD_NEW_ADDRESS: 'ADD_NEW_ADDRESS',
  ORDERS_LIST_ITEM_CLICK: 'ORDERS_LIST_ITEM_CLICK',
  ORDERS_LIST_ITEMS_FETCH: 'ORDERS_LIST_ITEMS_FETCH',
  ORDERS_LIST_SCREEN_CART_CLICKED: 'ORDERS_LIST_SCREEN_CART_CLICKED',
  ORDERS_LIST_SCREEN_SEARCH_CLICKED: 'ORDERS_LIST_SCREEN_SEARCH_CLICKED',
  SHIPMENT_SCREEN_CART_CLICKED: ' SHIPMENT_SCREEN_CART_CLICKED',
  SHIPMENT_SCREEN_SEARCH_CLICKED: 'SHIPMENT_SCREEN_SEARCH_CLICKED',
  SHIPMENT_SCREEN_CANCEL_ORDER_CLICKED: 'SHIPMENT_SCREEN_CANCEL_ORDER_CLICKED',
  SHIPMENT_SCREEN_REQUEST_CANCEL_ORDER_CLICKED: ' SHIPMENT_SCREEN_REQUEST_CANCEL_ORDER_CLICKED',
  SHIPMENT_SCREEN_CONTINUE_SHOPING_CLICKED: 'SHIPMENT_SCREEN_CONTINUE_SHOPING_CLICKED',
  SHIPMENT_SCREEN_VIEW_ORDER_CLICKED: 'SHIPMENT_SCREEN_VIEW_ORDER_CLICKED',
  SHIPMENT_SCREEN_GO_BACK_CLICKED: 'SHIPMENT_SCREEN_GO_BACK_CLICKED',
  SHIPMENT_SCREEN_DOWNLOAD_INVOICE_CLICKED: 'SHIPMENT_SCREEN_DOWNLOAD_INVOICE_CLICKED',
  RETURN_REQUEST_CANCLE_CLICK: 'RETURN_REQUEST_CANCLE_CLICK',
  RETURN_REQUEST_CONTINUE_SHOPING_CLICK: 'RETURN_REQUEST_CANCLE_CLICK',
  INVOICE_SHARE: 'INVOICE_SHARE',
  INVOICE_SCREEN: 'INVOICE_SCREEN',
  CART_PLACE_ORDER: 'CART_PLACE_ORDER',
  CART_SELECT_ADDRESS: 'CART_SELECT_ADDRESS',
  CART_CHANGE_ADDRESS: 'CART_CHANGE_ADDRESS',
  CART_CHANGE_QUNATITY: 'CART_CHANGE_QUNATITY',
  CART_DELETE_PRODUCT: 'CART_DELETE_PRODUCT',
  CART_PRODUCT_DETAILS: 'CART_PRODUCT_DETAILS',
  ORDER_SUCCESS_CONTUNUE: 'ORDER_SUCCESS_CONTUNUE',
  ORDER_TERM_CONDITION_PLACE_ORDER: 'ORDER_TERM_CONDITION',
  ORDER_TERM_CONDITION_OKAY: 'ORDER_TERM_CONDITION',
  PRODUCT: 'PRODUCT',
  DELIVERY_ESTIMATE: 'DELIVERY_ESTIMATE',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
};

export const FILTER_ANALYTICS_PREFIXES = {
  GEOGRAPHY: 'GEO',
  LOCATION: 'LOCATION',
  FUNCTION: 'FUNCTION',
  LEVEL: 'LEVEL',
  LAYER: 'LAYER',
  SERVICE_TENURE: 'YEARWISE',
  BLOOD_GROUP: 'BLOODGROUP',
  FACILITIES: 'FACILITIES',
  DEAL_YEAR: 'DEALYEAR',
  REGIONS: 'REGIONS',
  HOLIDAY_YEAR: 'YEAR',
};

export const APP_ENVIRONMENT = {
  PRE_PROD: 'PRE_PROD',
  PROD: 'PROD',
};

export const PANDEMIC_SUPPORT_IDENTIFIERS = {
  ADVISORIES: 'advisories',
  PANDEMIC_GUIDE: 'pandemic_guide',
  CONVO: 'convo',
};

export const OUR_CUSTOMERS_IDENTIFIERS = {
  VISITS: 'visits',
  WINS: 'wins',
  KNOW: 'know',
  ACCOLADES: 'accolades',
  TESTIMONIALS: 'testimonials',
};

export const OUR_CUSTOMERS_LIKE_TYPES = {
  visits: 'CV',
  wins: 'CW',
  know: 'CK',
  accolades: 'CAC',
  testimonials: 'CT',
  researches: 'CR',
};

export const OUR_CUSTOMERS_API_TYPES = {
  visits: 'visit',
  wins: 'win',
  know: 'know',
  accolades: 'accolade',
  testimonials: 'testimonial',
  researches: 'research',
};

export const OUR_CUSTOMERS_DATA_TYPES = {
  visits: 'visits',
  wins: 'wins',
  know: 'know',
  accolades: 'accolade',
  testimonials: 'testimonial',
  researches: 'research',
};

export const ADMIN_REPORT_VALUES = [
  'Inappropriate content',
  'Individual being targetted',
  'Posting id fake/unreliable',
  'Spam',
];

export const IDCARD_COLOR_CODES = {
  ID_CARD_TENURE_5: '#89a9d8',
  ID_CARD_TENURE_10: '#193661',
  ID_CARD_TENURE_15: '#f58b3d',
  ID_CARD_TENURE_20: '#ec1c24',
  ID_CARD_TENURE_25: '#808184',
  ID_CARD_TENURE_30: '#bcce5d',
};
export const THIRTY_ONE_QUESTIONS_CATEGORY_ID = 4;

export const NUMBER_SYMBOLS_LOOKUP = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

export const CUSTOM_VALIDATION_TEST_NAMES = {
  IS_VALID_PHONE_NUMBER_FOR_REGION: 'isValidPhoneNumberForRegion',
  IS_VALID_LINKEDIN_SOCIAL_URL: 'isValidLinkedinSocialUrl',
  IS_VALID_TWITTER_SOCIAL_URL: 'isValidTwitterSocialUrl',
  IS_VALID_FACEBOOK_SOCIAL_URL: 'isValidFacebookSocialUrl',
  IS_VALID_INSTAGRAM_SOCIAL_URL: 'isValidInstagramSocialUrl',
};

export const IJPR_ACTION_TYPE = {
  APPLY: 'apply',
  REFER: 'refer',
};

export const IJPR_TRACK_STYLES = {
  GREEN: 'green',
  BLUE: 'blue',
  AMBER: 'amber',
  GREY: 'grey',
};

export const DASHBOARD_BANNER_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
  FESTIVE: 'festive',
};

export const COMMUNITY_PIN_TYPE = {
  PIN: 'pin',
  UNPIN: 'unpin',
};

export const PODCAST_TAB_IDENTIFIERS = {
  ABOUT: 'about',
  CHANNELS: 'channels',
};

export const DIALOG_TYPE = {
  ONE_ON_ONE_CHAT: 'one_on_one_chat',
  GROUP_CHAT: 'group_chat',
};

export const FIREBASE_DB_NODES = {
  MASTERDATA: 'masterdata',
  FORCE_UPDATE: 'force_update',
  FORCE_UPDATE_BUNDLE: 'force_update_bundle',
  USERS: 'users',
  GROUP: 'group',
  MESSAGES: 'messages',
};

export const ATTACHMENT_OPTIONS = {
  GALLERY: 'Gallery',
  CAMERA: 'Camera',
  E_CARD: 'E-card',
  DOCUMENT: 'Document',
};

export const MESSAGE_OPTIONS = {
  COPY: 'Copy',
  REPLY: 'Reply',
  FORWARD: 'Forward',
  EDIT: 'Edit',
  DELETE: 'Delete',
};

export const NOTIFICATION_MESSAGE_TYPES = {
  NEW_GROUP: 'NEW_GROUP',
  ADD_MEMBER: 'ADD_MEMBER',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
  USER_LEFT: 'USER_LEFT',
  CHANGE_GROUP_NAME: 'CHANGE_GROUP_NAME',
  CHANGE_GROUP_IMAGE: 'CHANGE_GROUP_IMAGE',
};

export const CHAT_LEFT_PANEL_IDENTIFIERS = {
  DEFAULT_VIEW: 'DEFAULT_VIEW',
  NEW_CHAT_VIEW: 'NEW_CHAT_VIEW',
  CONFIRM_CHAT_VIEW: 'CONFIRM_CHAT_VIEW',
  GROUP_DETAIL_VIEW: 'GROUP_DETAIL_VIEW',
  GROUP_DETAIL_ADD_MEMBERS_VIEW: 'GROUP_DETAIL_ADD_MEMBERS_VIEW',
  FORWARD_VIEW: 'FORWARD_VIEW',
};

export const TRAVEL_REQUEST_TRAVEL_TYPE = [
  { label: 'Official', value: 'O' },
  { label: 'Personal', value: 'P' },
];

export const TRAVEL_REQUEST_TRAVEL_CATEGORY = [
  { label: 'Domestic', value: 'D' },
  { label: 'International', value: 'I' },
];

export const TRAVEL_REQUEST_DESTINATION_PREF = [
  { label: 'Depart', value: 'D' },
  { label: 'Arrive', value: 'A' },
];

export const TRAVEL_REQUEST_TAB_IDENTIFIERS = {
  PLAN_TRAVEL: 'plan_travel',
  UPCOMING_TRAVEL: 'upcoming_travel',
  ALL_REQUESTS: 'all_requests',
  FOR_APPROVAL: 'for_approval',
};

export const TRAVEL_REQUEST_ACTION_OPTIONS = {
  MODIFY: 'Modify Request',
  SUBMIT: 'Submit Request',
  CANCEL: 'Cancel Request',
  REMIND_MANAGER: 'Remind Manager',
  APPROVE: 'Approve Request',
  MODIFICATION: 'Modification Required',
  REJECT: 'Reject Request',
};

export const TRAVEL_REQUEST_ACTION_ROUTES = {
  MODIFY: 'modify',
  SUBMIT: 'submit',
  CANCEL: 'cancel',
  REMIND_MANAGER: 'remind',
  APPROVE: 'approve',
  MODIFICATION: 'modification',
  REJECT: 'reject',
};

export const TRAVEL_REQUEST_STATUS_IDENTIFIERS = {
  PRE_APPROVED: '_pre_approved',
  DRAFT: '_draft',
  REQUEST_CANCELLED: '_request_cancelled',
  AWAITING_APPROVAL: '_awaiting_approval',
  APPROVE: '_approved',
  REJECTED: '_rejected',
  MODIFICATION: '_modification',
  BOOKING_IN_PRGRESS: '_booking_in_progress',
  BOOKED: '_booked',
  BOOKING_CANCELLED: '_booking_cancelled',
  BOOKING_CANCELLATION_REQUESTED: '_booking_cancellation_requested',
};

export const TRAVEL_REQUEST_FILTER_STATUS = [
  { label: 'All', value: '' },
  { label: 'Pre-Approved', value: '_pre_approved' },
  { label: 'Draft', value: '_draft' },
  { label: 'Request Cancelled', value: '_request_cancelled' },
  { label: 'Awaiting Approval', value: '_awaiting_approval' },
  { label: 'Approved', value: '_approved' },
  { label: 'Rejected', value: '_rejected' },
  { label: 'Modification', value: '_modification' },
  { label: 'Booking In Progress', value: '_booking_in_progress' },
  { label: 'Booked', value: '_booked' },
  { label: 'Booking Cancelled', value: '_booking_cancelled' },
  { label: 'Booking Cancellation Requested', value: '_booking_cancellation_requested' },
];

export const TRAVEL_REQUEST_SORTING_STATUS = [
  { label: 'Latest', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

export const ML_DAY_IDENTIFIERS = {
  ML_35_IDENTIFIER: '_anniversary_ml35',
  ML_34_IDENTIFIER: '_anniversary_ml34',
  ML_33_IDENTIFIER: '_anniversary_ml33',
  ML_32_IDENTIFIER: '_anniversary_ml32',
  ML_31_IDENTIFIER: '_anniversary_ml31',
  ML_30_IDENTIFIER: '_anniversary_ml30',
};

export const ML_DAY_TITLES = {
  _anniversary_ml33: 'MICROLAND@33',
  _anniversary_ml32: 'MICROLAND@32',
  _anniversary_ml31: 'MICROLAND@31',
  _anniversary_ml30: 'MICROLAND@30',
};

export const MI_HR_CONTENT_IDENTIFIERS = {
  CONTEST: 'contest',
  ACTIVITY: 'activity',
  ARTICLE: 'article',
  SURVEY: 'survey',
  POLL: 'poll',
};

export const WOMENS_DAY_CONTENT_IDENTIFIERS = {
  CONTEST: 'contest',
  ACTIVITY: 'activity',
  ARTICLE: 'article',
  SURVEY: 'survey',
  POLL: 'poll',
};

export const ESG_CONTENT_IDENTIFIERS = {
  CONTEST: 'contest',
  CHAMP: 'champ',
  ACTIVITY: 'activity',
  ARTICLE: 'article',
  SURVEY: 'survey',
  POLL: 'poll',
};

export const MLF_CONTENT_IDENTIFIERS = {
  ARTICLE: 'article',
  TESTIMONY: 'testimony',
  STATIC: 'static',
};

export const MI_CHILD_CARE_TAB_KEYS = {
  ABOUT: 'about',
  POLICY: 'policy',
  COST_DETAILS: 'cost_details',
  REQUESTS: 'requests',
};

export const MODAL_TITLES = {
  CONFORMATION: 'Confirmation',
  ALERT: 'Alert',
  SUBMISSION_SUCCESS: 'Submitted successfully!',
  SEATS_UNAVAILABLE: 'Seat not available',
  SPACES_UNAVAILABLE: 'Space not available',
};

export const BUTTON_TITLES = {
  YES: 'YES',
  NO: 'NO',
  OK: 'OK',
};

export const HOME_WIDGET_IDENTIFIERS = ['_microStore', '_anniversary_ml35'];
