import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getUrlWithSpecificRendition, showToast } from '../../utils/common';
import { apiRequest, handleError } from '../../services/Service';
import { COMMUNITIES_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import HtmlView from '../../common/ui/html_view/HTMLView';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useParams } from 'react-router-dom';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { saveCommunityData } from '../../state/CommunityData';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectAccessData } from '../../state/UserAccessData';

const CommunityDetailScreen = ({ history }) => {
  const { communityId } = useParams();

  const accessibilityData = useSelector(selectAccessibilityData);
  const [community, setCommunity] = useState();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const [showJoinButton, setShowJoinButton] = useState(false);
  const [showAccessJoinButton, setAccessJoinButton] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [showDoubleButton, setShowDoubleButton] = useState(false);
  const [showSingleButton, setShowSingleButton] = useState(false);
  const [isGalleryEnable, setIsGalleryEnable] = useState(true);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessData = useSelector(selectAccessData);
  const communityAccessData = accessData?.data?.find((item) => item.feature_key === '_communities');

  useEffect(() => {
    if (communityAccessData?.actions?.includes('_join')) {
      setAccessJoinButton(true);
    } else {
      setAccessJoinButton(false);
    }
  }, [communityAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.COMMUNITIES_DETAIL, NAVIGATION_ROUTES.COMMUNITIES_DETAIL);
  }, []);

  const callCommunityApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(COMMUNITIES_BY_ID + communityId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data.community) {
              dispatch(saveCommunityData(data.community));
              setCommunity(data.community);
              if (data.community.open_status === 1) {
                if (data.community.is_member) {
                  if (data.community.approved) {
                    setIsGalleryEnable(true);
                    setShowMenuIcon(true);
                    setShowJoinButton(false);
                    setShowDoubleButton(true);
                    setShowSingleButton(false);
                  } else {
                    setIsGalleryEnable(true);
                    setShowMenuIcon(false);
                    setShowJoinButton(false);
                    setShowDoubleButton(true);
                    setShowSingleButton(false);
                  }
                } else {
                  setIsGalleryEnable(true);
                  setShowMenuIcon(false);
                  setShowJoinButton(true);
                  setShowDoubleButton(true);
                  setShowSingleButton(false);
                }
              } else {
                if (data.community.is_member) {
                  if (data.community.approved) {
                    setShowMenuIcon(true);
                    setShowJoinButton(false);
                    setIsGalleryEnable(true);
                    setShowDoubleButton(true);
                    setShowSingleButton(false);
                  } else {
                    setShowMenuIcon(false);
                    setShowJoinButton(false);
                    setIsGalleryEnable(false);
                    setShowDoubleButton(false);
                    setShowSingleButton(true);
                  }
                } else {
                  setShowMenuIcon(false);
                  setShowJoinButton(true);
                  setIsGalleryEnable(false);
                  setShowDoubleButton(false);
                  setShowSingleButton(false);
                }
              }
              if (data.community.gallary.pictures && data.community.gallary.pictures.length > 0) {
                let temp_pictures = [];
                temp_pictures = data.community.gallary.pictures.map((item) => {
                  return {
                    ['picture_path']: item.picture_path,
                    ['picture_caption']: item.picture_caption,
                  };
                });
                setPictures(temp_pictures);
              }
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, COMMUNITIES_BY_ID + communityId, NAVIGATION_ROUTES.COMMUNITIES_DETAIL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [communityId, dispatch]);

  useEffect(() => {
    callCommunityApi();
  }, [callCommunityApi]);

  const callJoinApi = async () => {
    const params = new URLSearchParams();
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        COMMUNITIES_BY_ID + communityId + '/join',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            const data = apiResponse.response;
            showToast(data.message);
            callCommunityApi();
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        COMMUNITIES_BY_ID + communityId + '/join',
        NAVIGATION_ROUTES.COMMUNITIES_DETAIL
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressViewMembers = () => {
    history.push(`${NAVIGATION_ROUTES.VIEW_MEMBERS}/${communityId}`);
  };

  const onPressGallery = () => {
    if (community && pictures.length > 0) {
      history.push(NAVIGATION_ROUTES.COMMON_GALLERY_FULL_SCREEN, {
        baseUrl: community.gallary.base_url,
        data: pictures,
        title: community.title,
        id: communityId,
        fromRoute: NAVIGATION_ROUTES.COMMUNITIES,
        apiUrl: 'communities/' + communityId + '/gallery',
      });
    }
  };

  const leaveCommunityApi = async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LEAVE_FORM,
      })
    );
    try {
      const params = new URLSearchParams();
      const apiResponse = await apiRequest(
        COMMUNITIES_BY_ID + communityId + '/leave',
        REQUEST_TYPE.POST,
        params
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          history.goBack();
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        COMMUNITIES_BY_ID + communityId + '/leave',
        NAVIGATION_ROUTES.COMMUNITIES_DETAIL
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const leaveCommunityPopover = (
    <Popover>
      <Popover.Content>
        <div className="common-cursor-pointer" onClick={leaveCommunityApi}>
          Leave community
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      <BackButton />
      <div className="main-content-container">
        <div className="d-flex justify-content-center">
          {community && (
            <div className="common-container-md">
              <div className="mt-1 communities-title-text">{community.title}</div>
              <div className="d-flex align-items-center justify-content-between mb-1">
                <div className="d-flex">
                  <div
                    onClick={showDoubleButton ? onPressViewMembers : null}
                    className="d-flex mr-3">
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_MEMBERS,
                        ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                      )}
                      className="header-icon mr-1"
                    />
                    {community.total_members > 1 ? (
                      <div className="mt-1 communities-member-detail common-cursor-pointer">
                        {community.total_members} MEMBERS
                      </div>
                    ) : community.total_members === 1 ? (
                      <div className="mt-1 communities-member-detail common-cursor-pointer">
                        {community.total_members} MEMBER
                      </div>
                    ) : (
                      <div className="mt-1 communities-member-detail common-cursor-pointer">
                        0 MEMBERS
                      </div>
                    )}
                  </div>
                  <div
                    onClick={
                      showDoubleButton
                        ? () => {
                            history.push(
                              `${NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS}/${communityId}`,
                              { community }
                            );
                          }
                        : null
                    }
                    className="d-flex mr-3 ml-3">
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POSTS,
                        ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                      )}
                      className="header-icon mr-1"
                    />
                    <div className="mt-1 communities-member-detail common-cursor-pointer">
                      {community.community_posts
                        ? community.community_posts === 1
                          ? '1 POST'
                          : `${community.community_posts} POSTS`
                        : '0 POSTS'}
                    </div>
                  </div>
                  <div>
                    {isGalleryEnable === true ? (
                      <div
                        className="d-flex align-items-center ml-3 common-cursor-pointer"
                        onClick={onPressGallery}>
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSED_GALLERY,
                            ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                          )}
                          className="header-icon mr-1"
                        />
                        <div className="communities-gallery-size">
                          {community &&
                            (pictures.length === 1
                              ? pictures.length + ' PHOTO'
                              : pictures.length > 1
                              ? pictures.length + ' PHOTOS'
                              : ' 0 PHOTOS')}
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center ml-3">
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSED_GALLERY,
                            ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                          )}
                          className="header-icon mr-1"
                        />
                        <div className="communities-member-detail">
                          {community &&
                            (pictures.length === 1
                              ? pictures.length + ' PHOTO'
                              : pictures.length > 1
                              ? pictures.length + ' PHOTOS'
                              : ' 0 PHOTOS')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {showJoinButton && showAccessJoinButton && (
                  <CustomButton
                    buttonStyle="communities-button-style mr-0"
                    onClick={callJoinApi}
                    showLoader={progressLoadingState.isProgressLoading}>
                    JOIN
                  </CustomButton>
                )}
                {showMenuIcon && (
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom"
                    overlay={leaveCommunityPopover}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_MORE_OPTIONS,
                        ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                      )}
                      className="header-icon mr-0"
                    />
                  </OverlayTrigger>
                )}
              </div>
              <img
                src={getUrlWithSpecificRendition(
                  community.promo_image.base_url,
                  SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                  community.promo_image.image_path
                )}
                className="embed-responsive embed-responsive-12by7"
              />
              <HtmlView html={community.description} htmlStyle="mt-3" />
              {community.champions && community.champions.length > 0 && (
                <div className="communities-champion-text">Community Champions</div>
              )}
              <div className="d-flex flex-wrap mt-3">
                {community.champions &&
                  community.champions.length > 0 &&
                  community.champions.map((item, index) => {
                    return (
                      <div key={`${index}`} className="col-6 col-sm-3 col-lg-3 mb-4">
                        <div>
                          <UserProfilePhoto
                            imageBaseUrl={item.profile_picture.base_url}
                            imagePath={item.profile_picture.image_path}
                            imageClass="common-user-profile-photo-xl"
                            employeeId={item.employee_id}
                          />
                          <div className="text-center mt-1 communities-champion-title">
                            {item.first_name + ' ' + item.last_name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {showSingleButton && (
                <div className="communities-btn-view mt-3">
                  <CustomButton buttonStyle="communities-button-style mr-3">
                    Waiting for admin approval
                  </CustomButton>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityDetailScreen;
