import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeData } from '../../state/EmployeeData';
import { saveProgressLoadingState } from '../../state/UIState';
import {
  getDashboardFacilityData,
  getQuickBookingApi,
  getTipsApi,
  getUpcomingBookingApi,
} from './action';
import { Carousel } from 'react-bootstrap';
import { selectVehicleParkingConfig } from '../../state/MasterData';
import UpcomingItem from './components/UpcomingItem';
import QuickBookingItem from './components/QuickBookingItem';
import OfficeItem from './components/OfficeItem';
import HelpfulTipsItem from './components/HelpfulTipsItem';
import Divider from '../../common/ui/divider/Divider';
import { useHistory } from 'react-router';
import BookAParkingTab from './tabs/BookAParkingTab';

const VehicleParkingHomeTab = () => {
  const vehicleHomeConfig = useSelector(selectVehicleParkingConfig);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const dispatch = useDispatch();
  const [facilities, setFacilities] = useState();
  const [facilitiesDropDownData, setFacilitiesDropDownData] = useState(
    facilities
      ? Object.values(facilities)
          .flat()
          .map((facility) => ({
            label: facility.title,
            value: facility.facility_id,
            link: facility.link,
            address: facility.address,
          }))
      : []
  );
  const [upcomingBookings, setUpcomingBookings] = useState();
  const [quickBookings, setQuickBookings] = useState();
  const employeeData = useSelector(selectEmployeeData);
  const [tips, setTips] = useState();
  const history = useHistory();
  const [selectedBooking, setSelectedBooking] = useState(null);

  const callFacilitiesApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await getDashboardFacilityData();
      if (apiResponse.response?.status === true) {
        const data = apiResponse.response?.data || {};
        const facilitiesArray = Object.values(data).flat();
        setFacilities(facilitiesArray);
        const dropdownData = facilitiesArray.map((facility) => ({
          label: facility.title,
          value: facility.facility_id,
          link: facility.link,
          address: facility.address,
          spacesCount: facility.spacesCount,
        }));
        setFacilitiesDropDownData(dropdownData);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const callUpcomingBookingApi = useCallback(async () => {
    try {
      const response = await getUpcomingBookingApi(employeeData.employee_id);
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setUpcomingBookings(data);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [employeeData.employee_id]);

  const callQuickBookingApi = useCallback(async () => {
    try {
      const response = await getQuickBookingApi(employeeData.employee_id);
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setQuickBookings(data);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [employeeData.employee_id]);

  const callTipsApi = useCallback(async () => {
    try {
      const response = await getTipsApi();
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setTips(data.tips);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const renderScreen = useCallback(async () => {
    callFacilitiesApi();
    callUpcomingBookingApi();
    callQuickBookingApi();
    callTipsApi();
  }, [callFacilitiesApi, callQuickBookingApi, callUpcomingBookingApi, callTipsApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  return (
    <div className="seat-booking-colored-container p-2 d-flex flex-row">
      <div className="vehicle-parking-left-container mr-2">
        <div className="d-flex flex-row mb-2">
          {vehicleHomeConfig && vehicleHomeConfig.banners.length > 0 && (
            <div className="vehicle-parking-carousel-container">
              <Carousel
                activeIndex={currentActiveSlideIndex}
                className="pb-1 seat-booking-carousel mr-2"
                nextIcon={
                  vehicleHomeConfig.banners.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-next-icon" />
                  )
                }
                prevIcon={
                  vehicleHomeConfig.banners.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-prev-icon" />
                  )
                }
                onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
                {vehicleHomeConfig.banners.map((imageItem, index) => {
                  return (
                    <Carousel.Item key={`${index}`}>
                      <div className="d-flex justify-content-center">
                        <img
                          className="vehicle-parking-carousel-container seat-booking-carousel-border"
                          src={imageItem.base_url + imageItem.filename}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
          {facilitiesDropDownData && facilitiesDropDownData.length > 0 && (
            <div className="seat-booking-white-background p-2 w-100">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">OFFICE LOCATIONS</div>
              </div>
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="w-100">
                  <>
                    {facilitiesDropDownData?.map((item, index) => {
                      return (
                        <div key={`${index}`} className="w-100">
                          <OfficeItem item={item} index={index} />
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-row mb-2">
          {quickBookings && quickBookings.length > 0 ? (
            <div className="seat-booking-white-background p-2 w-50 mr-2 vehicle-parking-component-height">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">QUICK BOOKINGS</div>
              </div>
              <div className="d-flex flex-row w-100">
                {quickBookings.map((item, index) => {
                  return (
                    <div className="" key={`${index}`}>
                      <QuickBookingItem
                        item={item}
                        index={index}
                        onQuickBook={(data) => setSelectedBooking(data)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="seat-booking-white-background p-2 w-50 vehicle-parking-component-height mr-2">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">QUICK BOOKINGS</div>
              </div>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100 align-self-center
              seat-booking-quick-emp-container-height">
                <div>Book the same spot again with just a single click.</div>
                <div>Book a parking spot now</div>
              </div>
            </div>
          )}
          {upcomingBookings && upcomingBookings.length > 0 ? (
            <div className="seat-booking-white-background p-2 w-50 vehicle-parking-component-height">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">UPCOMING BOOKINGS</div>
              </div>
              {upcomingBookings.map((item, index) => {
                return (
                  <div key={`${index}`}>
                    <UpcomingItem item={item} index={index} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="seat-booking-white-background p-2 w-50 vehicle-parking-component-height">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">UPCOMING BOOKINGS</div>
              </div>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100 align-self-center
                seat-booking-quick-emp-container-height">
                <div>Your upcoming bookings will appear here</div>
                <div>Book a parking spot now</div>
              </div>
            </div>
          )}
        </div>
        {tips && tips?.length > 0 && (
          <div className="seat-booking-white-background p-2 w-100 helpful-tips-container">
            <>
              <div className="estore-home-tab-products-header my-3">
                <div className="estore-product-type-title">HELPFUL TIPS</div>
              </div>
              {tips.map((item, index) => {
                return (
                  <div key={`${index}`}>
                    <HelpfulTipsItem item={item} index={index} />
                    {index !== tips.length - 1 && <Divider />}
                  </div>
                );
              })}
            </>
          </div>
        )}
      </div>
      <div className="seat-booking-form-background vehicle-parking-right-container-width p-2">
        <BookAParkingTab location={selectedBooking} />
      </div>
    </div>
  );
};

export default VehicleParkingHomeTab;
