import React, { useCallback, useEffect, useState } from 'react';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import { useDispatch } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { msApiRequest } from '../../../services/Service';
import { FETCH_PARKING_BY_LEVEL } from '../../../services/ApiUrls';
import { REQUEST_TYPE } from '../../../common/constants/AppConstants';

const ParkingLayout = ({ location }) => {
  const layOutImageUri = location.state.selectedFloor.layOutImage;
  const height = location.state.selectedFloor.height;
  const width = location.state.selectedFloor.width;
  const entryDateTime = location.state.entryDateTime;
  const exitDateTime = location.state.exitDateTime;
  const [seats, setSeats] = useState([]);
  const dispatch = useDispatch();
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchSeatsApi = useCallback(async () => {
    setSeats([]);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const params = {
        timezone: localTimezone,
        level_id: location.state.selectedFloor.value,
        org_id: 1,
        entry_datetime: entryDateTime ?? null,
        exit_datetime: exitDateTime ?? null,
      };
      const apiResponse = await msApiRequest(FETCH_PARKING_BY_LEVEL, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.statusCode === 200) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data?.length > 0) {
              setSeats(data);
            }
          }
        }
      }
      dispatch(resetLoaderState());
    } catch (e) {
      dispatch(resetLoaderState());
      console.error(e);
    }
  }, [dispatch, entryDateTime, exitDateTime, localTimezone, location.state.selectedFloor.value]);

  useEffect(() => {
    fetchSeatsApi();
  }, [fetchSeatsApi]);

  return (
    <div className="container">
      <SectionHeader title={'MiPARKINGS'} />
      <BackButton />
      <div className="seat-layout-container">
        <img style={{ height: height, width: width }} src={layOutImageUri} alt="Parking Layout" />
        {seats.map((seat, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: `${seat.x_coordinates}px`,
              top: `${seat.y_coordinates}px`,
              width: `${seat.width}px`,
              height: `${seat.height}px`,
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
              borderRadius: '5px',
              overflow: 'auto',
              padding: '10px',
              textAlign: 'center',
              alignItems: 'center',
            }}>
            {seat.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParkingLayout;
