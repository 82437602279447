import dayjs from 'dayjs';
import React from 'react';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { useHistory } from 'react-router-dom';
import { formatDateDDthMonth } from '../../../utils/common';
import StatusIndicator from '../../../common/components/StatusIndicator';

const UpcomingItem = ({ item, index }) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const entryDatetime = item?.entry_datetime ? dayjs(item?.entry_datetime) : null;
  const exitDatetime = item?.exit_datetime ? dayjs(item?.exit_datetime) : null;
  const checkInTime = entryDatetime ? entryDatetime.format('hh:mm A') : 'N/A';
  const checkOutTime = exitDatetime ? exitDatetime.format('hh:mm A') : 'N/A';
  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.format('dddd, DD MMMM YYYY');
  };
  const formattedDate = entryDatetime ? formatDate(entryDatetime) : 'N/A';
  return (
    <div
      onClick={() =>
        history.push(NAVIGATION_ROUTES.SEAT_BOOKING_PREVIEW_SCREEN, {
          fromRoute: 'BookingScreen',
          checkInTime: checkInTime,
          checkOutTime: checkOutTime,
          parkingNumber: item?.seatData.name,
          selectedDate: formattedDate,
          entryDatetime: entryDatetime,
          exitDatetime: exitDatetime,
          space_id: item?.seatData._id,
          booking_id: item?.booking_id,
        })
      }
      className="common-cursor-pointer">
      <div className="d-flex flex-row justify-content-between">
        <div>
          <div className="base-14px-font">{item?.facilityTitle}</div>
          {item?.floorName && item?.seatData.name ? (
            <div className="booking-home-subtitle">
              {item?.floorName}, {item?.seatData.name}
            </div>
          ) : item?.seatData.name ? (
            <div className="booking-home-subtitle">{item?.seatData.name}</div>
          ) : null}
          <div className="d-flex flex-row justify-content-between">
            <div className="upcoming-booking-timing-text">{`${formatDateDDthMonth(
              entryDatetime
            )} - ${checkInTime}`}</div>
          </div>
        </div>
        <div>
          <div>{item?.status && <StatusIndicator status={item?.status} />}</div>
          <div className="d-flex flex-row justify-content-end w-100 mt-2 pt-1">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="seat-booking-home-arrow-style"
            />
          </div>
        </div>
      </div>
      {index != 1 && <Divider style={'my-2'} />}
    </div>
  );
};

export default UpcomingItem;
