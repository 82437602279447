import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { DI_DISCUSSIONS, DI_DISCUSSION_ADDPOST } from '../../../services/ApiUrls';
import { isEmptyText, showToast } from '../../../utils/common';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import DiscussionTabItem from '../../microgive/components/DiscussionTabItem';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import CommentBox from '../../../common/ui/comment_box/CommentBox';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const DiscussionTab = () => {
  const dispatch = useDispatch();
  const [discussionsList, setDiscussionsList] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DI_DISCUSSIONS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.DI);
  }, []);

  const callDiscussionListApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(DI_DISCUSSIONS, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussionsList((oldArray) => {
                  const newDiscussions = [...apiResponse.response.data.feeds.reverse()];
                  const existingDiscussionIds = new Set(oldArray.map((item) => item.discussion_id));
                  const uniqueDiscussions = newDiscussions.filter((item) => {
                    if (!existingDiscussionIds.has(item.discussion_id)) {
                      existingDiscussionIds.add(item.discussion_id);
                      return true;
                    }
                    return false;
                  });

                  return [...uniqueDiscussions, ...oldArray];
                });
                setPageId(id);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, DI_DISCUSSIONS, NAVIGATION_ROUTES.DI);
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callDiscussionListApi(1, true);
  }, [callDiscussionListApi]);

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId + 1, loadMoreFlag);
  };

  const handleChange = (value) => {
    setCommentText(value);
  };

  const callCommentApi = async () => {
    const params = new URLSearchParams();
    params.append('description', commentText.trim());
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const apiResponse = await apiRequest(DI_DISCUSSION_ADDPOST, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          setDiscussionsList([]);
          setLoadMoreFlag(true);
          callDiscussionListApi(1, true);
        }
      }
    } catch (err) {
      handleError(err, params, DI_DISCUSSION_ADDPOST, NAVIGATION_ROUTES.DI);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = async () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.DI_DISCUSSION_COMMENT,
        commentText,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      callCommentApi();
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.EXCHANGE_YOUR_THOUGHTS} />;
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      {showEmptyContentView && !discussionsList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {loadMoreFlag && !!discussionsList.length && (
            <div className="mb-2 mt-4">
              <div className="loadMoreContainer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                    ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                  )}
                  className="load-more-icon mr-2"
                  onClick={onPressLoadPrevious}
                />
                <div className="loadmoreText" onClick={onPressLoadPrevious}>
                  Load previous comments
                </div>
              </div>
            </div>
          )}
          {discussionsList.map((item, index) => {
            return <DiscussionTabItem key={`${index}`} item={item} />;
          })}
        </div>
      )}
      <CommentBox
        getCommentText={onPressSend}
        onChangehandler={handleChange}
        commentText={commentText}
        textValue="Send"
        showLoader={progressLoadingState.isProgressLoading}
      />
    </div>
  );
};

export default DiscussionTab;
