import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest } from '../../services/Service';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import DiscussionTabItem from '../microgive/components/DiscussionTabItem';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { showToast, isEmptyText } from '../../utils/common';
import CustomYoutubePlayer from '../../common/ui/youtube_video/CustomYoutubePlayer';
import CommentBox from '../../common/ui/comment_box/CommentBox';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import BackButton from '../../common/ui/common_back_button/BackButton';

const WatchLiveScreen = ({ location }) => {
  const dispatch = useDispatch();
  const { identifier } = location.state;
  const [videoId] = useState(location.state.videoId);
  const [discussions, setDiscussions] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [reverseTime, setReverseTime] = useState('');
  const [loading, setLoading] = useState(true);

  const reverseInterval = useRef();

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ANNIVERSARY_SCREEN, NAVIGATION_ROUTES.WATCH_LIVE_SCREEN);
    return () => {
      clearInterval(reverseInterval.current);
    };
  }, []);

  const callDiscussionListApi = useCallback(
    async (id, loadMore) => {
      if (loadMore) {
        try {
          const params = {
            page_id: id,
          };
          setLoading(true);
          const apiResponse = await apiRequest(
            identifier + '/discussions',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussions((oldArray) => {
                  const newDiscussions = [...apiResponse.response.data.feeds.reverse()];
                  const existingDiscussionIds = new Set(oldArray.map((item) => item.discussion_id));
                  const uniqueDiscussions = newDiscussions.filter((item) => {
                    if (!existingDiscussionIds.has(item.discussion_id)) {
                      existingDiscussionIds.add(item.discussion_id);
                      return true;
                    }
                    return false;
                  });

                  return [...uniqueDiscussions, ...oldArray];
                });
                setPageId(id);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          setLoading(false);
        }
      }
    },
    [identifier]
  );

  useEffect(() => {
    callDiscussionListApi(1, true);
  }, [callDiscussionListApi]);

  const callCommentApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const params = new URLSearchParams();
      params.append('description', commentText.trim());
      const apiResponse = await apiRequest(
        identifier + '/discussion/post',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          setDiscussions([]);
          setLoadMoreFlag(true);
          callDiscussionListApi(1, true);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressRefresh = () => {
    setPageId(1);
    setDiscussions([]);
    setLoadMoreFlag(true);
    callDiscussionListApi(1, true);
    reverseTimer();
  };

  const reverseTimer = () => {
    let timer = 15;
    reverseInterval.current = setInterval(() => {
      setReverseTime(`${timer}`);
      if (--timer < 0) {
        timer = timer - 1;
      }

      if (Math.floor(timer) === 0) {
        clearInterval(reverseInterval.current);
        setReverseTime('');
      }
    }, 1000);
  };

  const onPressSend = () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.ANNIVERSARY_COMMENT,
        commentText,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      callCommentApi();
    }
  };

  const handleChange = (value) => {
    setCommentText(value);
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId + 1, loadMoreFlag);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="main-content-container">
        <CustomYoutubePlayer videoId={videoId} />
        <ActivityLoader visible={loading} />
        <div className="mt-2">
          {loadMoreFlag && (
            <div className="mb-2 mt-4">
              <div className="loadMoreContainer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                    ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                  )}
                  className="load-more-icon mr-2"
                  onClick={onPressLoadPrevious}
                />
                <div className="loadmoreText" onClick={onPressLoadPrevious}>
                  Load previous comments
                </div>
              </div>
            </div>
          )}
          {discussions.map((item, index) => {
            return <DiscussionTabItem key={`${index}`} item={item} />;
          })}
        </div>
        {reverseTime === '' ? (
          <div className="cheers-show-hide-view mb-3">
            <div onClick={onPressRefresh} className="anniversary-refresh-button">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_REFRESH,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="anniversary-normal-icon"
              />
            </div>
          </div>
        ) : (
          <div className="cheers-show-hide-view mb-3">
            <div className="anniversary-reverse-time text-center p-1">{reverseTime}</div>
          </div>
        )}
        <CommentBox
          getCommentText={onPressSend}
          onChangehandler={handleChange}
          commentText={commentText}
          textValue="Send"
          showLoader={progressLoadingState.isProgressLoading}
        />
      </div>
    </div>
  );
};

export default WatchLiveScreen;
