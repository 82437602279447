import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { GET_WHO_LIKED, GET_WHO_LIKED_COMMENTS } from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import { saveProgressLoadingState } from '../../../state/UIState';
import { ERROR_MESSAGES, REQUEST_TYPE } from '../../constants/AppConstants';
import Divider from '../../ui/divider/Divider';
import UserProfilePhoto from '../../ui/user_profile_photo/UserProfilePhoto';
import BackButton from '../../ui/common_back_button/BackButton';
import DefaultContentView from '../../ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../ui/load_more_btn/LoadMoreBtn';

const LikedPeopleScreen = ({ location }) => {
  const referId = location.state.referId;
  const articleType = location.state.parentType;
  const commentId = location.state.commentId;
  const [employees, setEmployees] = useState([]);
  const dispatch = useDispatch();
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);

  const getLikedPeopleApi = useCallback(
    async (pageId = 1) => {
      if (pageId === 1) {
        setEmployees([]);
      }
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        setShowEmptyView(false);
        let requestParams = {
          type: articleType,
          refer_id: referId,
          page_id: pageId,
        };
        if (commentId) {
          requestParams = { ...requestParams, ...{ comment_refer_id: commentId } };
        }
        const apiResponse = await apiRequest(
          commentId ? GET_WHO_LIKED_COMMENTS : GET_WHO_LIKED,
          REQUEST_TYPE.GET,
          requestParams
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds) {
              const data = apiResponse.response.data.feeds;
              if (data.length > 0) {
                if (pageId === 1) {
                  setEmployees(data);
                } else {
                  setEmployees((prev) => [...prev, ...data]);
                }
              }
              if (data?.length === 0) {
                setLoadMoreFlag(false);
                setShowEmptyView(true);
              }
            }
          }
        } else {
          setShowEmptyView(true);
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        setShowEmptyView(true);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [articleType, commentId, dispatch, referId]
  );

  const renderScreen = useCallback(async () => {
    getLikedPeopleApi();
  }, [getLikedPeopleApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const loadMore = () => {
    getLikedPeopleApi(pageNo + 1);
    setPageNo(pageNo + 1);
  };

  const renderEmployee = (item) => {
    return (
      <div>
        <div className="common-liked-people-image-row">
          <UserProfilePhoto
            imageBaseUrl={item?.profile_picture?.base_url}
            imagePath={item?.profile_picture?.image_path}
            imageClass="common-user-profile-photo-md mb-1 mr-2"
            employeeId={item.employee_id}
          />
          <div className="ml-3">
            <div>
              <div className="d-flex align-items-center">
                <p className="cheers-emp-name mb-0">{item.first_name + ' ' + item.last_name}</p>
                {!!item.pronouns && (
                  <p className="common-liked-people-pronoun-text ml-1 mb-0">
                    {'(' + item.pronouns + ')'}
                  </p>
                )}
              </div>
              <div className="cheers-emp-position">{item.position_title}</div>
            </div>
          </div>
        </div>
        <Divider style="mt-2 mb-2" />
      </div>
    );
  };

  return (
    <div className="main-content-container container">
      <BackButton containerStyle="pt-3" />
      {employees && employees?.length > 0
        ? employees?.map((item, index) => {
            return <div key={index}>{renderEmployee(item)}</div>;
          })
        : showEmptyView &&
          employees?.length === 0 && <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />}
      {loadMoreFlag && <LoadMoreBtn onClick={loadMore} />}
    </div>
  );
};

export default LikedPeopleScreen;
