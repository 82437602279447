import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectProgressLoadingState } from '../../../state/UIState';
import { selectIjprConfigData } from '../../../state/MasterData';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const IjpApplyDialog = (props) => {
  const { isIjpApplyDialogVisible, setIsIjpApplyDialogVisible, onPressConfirmedApplyHandler } =
    props;
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const ijprConfig = useSelector(selectIjprConfigData);

  return (
    <Modal
      onHide={() => setIsIjpApplyDialogVisible((val) => !val)}
      show={isIjpApplyDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <h5 className="ijp-apply-modal-title text-center">Heads up!</h5>
        <HtmlView
          html={ijprConfig?.ijpr_apply_headsup_text}
          htmlStyle="ijp-apply-modal-subtitle text-center mb-4"
        />

        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={() => {
              setIsIjpApplyDialogVisible(false);
            }}
            buttonMainContainer="mr-4"
            buttonStyle="common-custom-button-ternary">
            No, Cancel
          </CustomButton>
          <CustomButton
            disabled={progressLoadingState.isProgressLoading}
            onClick={onPressConfirmedApplyHandler}
            buttonStyle="common-custom-button-quaternary">
            Yes, Proceed
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IjpApplyDialog;
