import { createSlice } from '@reduxjs/toolkit';

const saveVehicleBookingDataReducer = (state, action) => {
  state.selectedType = action.payload; // Or update state.bookings based on what you're trying to achieve
};

// REDUCER
const bookingDataSlice = createSlice({
  name: 'parkData',
  initialState: {
    selectedType: 'upcoming',
  },
  reducers: {
    saveBookingData: saveVehicleBookingDataReducer,
  },
});

// ACTIONS
const { saveVehicleBookingData } = bookingDataSlice.actions;

// SELECTOR
const selectBookingData = ({ parkData }) => parkData.selectedType;

const vehcileBookingDataSliceReducer = bookingDataSlice.reducer;

export { vehcileBookingDataSliceReducer, saveVehicleBookingData, selectBookingData };
