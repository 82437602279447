import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router';
import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';

const QuickBookingItem = ({ item, onClick }) => {
  const history = useHistory();
  const entryDatetime = item?.entry_datetime ? dayjs(item?.entry_datetime) : null;
  const exitDatetime = item?.exit_datetime ? dayjs(item?.exit_datetime) : null;
  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.format('dddd, DD MMMM YYYY');
  };
  const formattedDate = entryDatetime ? formatDate(entryDatetime) : 'N/A';
  const checkInTime = entryDatetime ? entryDatetime.format('hh:mm A') : 'N/A';
  const checkOutTime = exitDatetime ? exitDatetime.format('hh:mm A') : 'N/A';
  return (
    <div className="seat-quick-booking-card-container mr-2">
      {item?.facilityTitle && (
        <div className="base-12px-font-semi-bold w-50 mb-3">{item?.facilityTitle}</div>
      )}
      {item?.floorName && item?.seatData.name ? (
        <div className="booking-home-subtitle mb-2">
          {item?.floorName}, {item?.seatData.name}
        </div>
      ) : item?.seatData.name ? (
        <div className="booking-home-subtitle mb-2">{item?.seatData.name}</div>
      ) : null}

      <div
        onClick={() =>
          onClick({
            checkInTime: dayjs(checkInTime, 'h:mm A').format('HH:mm'),
            checkOutTime: dayjs(checkOutTime, 'h:mm A').format('HH:mm'),
            facilityId: item.seatData.facility_id,
            levelId: item.seatData.floor_id,
            selectedDate: formattedDate,
            entryDatetime: entryDatetime,
            exitDatetime: exitDatetime,
            space_id: item.seatData._id,
          })
        }
        className="d-flex flex-row justify-content-center align-items-center quick-booking-btn common-cursor-pointer">
        <div className="quick-booking-btn-text common-cursor-pointer">Book again</div>
      </div>
    </div>
  );
};

export default QuickBookingItem;
